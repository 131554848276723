<div class="d-flex border-top mx-auto" [ngStyle]="{'height.px':scrHeight-120}">

    <div class="d-flex flex-column align-items-stretch flex-shrink-0 bg-white border-end" style="max-width: 380px;">
        <a
            class="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom justify-content-center">
            <img src="assets/icons/chat.svg" alt="">
            <span class="ms-2 fs-5 fw-semibold d-sm-block d-none">Messagerie</span>
        </a>

        <div class="p-3" *ngIf="!loading && list.length==0">
            aucun échange jusquà maintenant
        </div>
        <div class="list-group list-group-flush border-bottom scrollarea" *ngIf="!loading && list.length!=0">
            <div class="" *ngFor="let message of list">
                <a (click)="selecteEchange(message)"
                    class="pointer list-group-item list-group-item-action d-flex gap-sm-3 py-3 border-bottom-0"
                    aria-current="true">
                    <img [src]="message.avatar" alt="twbs" width="40" height="40"
                        class="rounded-circle border flex-shrink-0 cover">
                    <div class="d-flex gap-2 w-100 justify-content-sm-between justify-content-end">
                        <div class="text-start d-sm-block d-none">
                            <h6 class="mb-0">{{message.full_name}}</h6>
                            <p class="mb-0 opacity-75">{{message.message}}</p>
                        </div>
                    </div>

                </a>
                <div class="opacity-50 text-nowrap d-sm-block d-none float-end pe-1">{{message.created_at}}</div>
            </div>

        </div>

    </div>

    <div class="col position-relative" [ngStyle]="{'max-height.px':scrHeight-50}">
        <div *ngIf="!selectedEchange && !loading" class="h-100 d-flex">
            <div class="align-self-center w-100">
                Séléctionnez un échange pour commencer à discuter
            </div>
        </div>
        <div #showBottom *ngIf="selectedEchange && !loading" class="w-100 overflow-auto bg-dager"
            [ngStyle]="{'max-height.px':scrHeight-200}">
            <div class="bg-white mb-2">
                <a class="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                    <span (click)="back()" class="float-start d-flex"><i
                            class="align-self-center fa fa-chevron-left pointer"></i></span>
                    <img [src]="selectedEchange.avatar" alt="twbs" width="40" height="40"
                        class="rounded-circle border flex-shrink-0 cover">
                    <div class="d-flex gap-2 w-100 justify-content-between">
                        <div class="text-start">
                            <h6 class="mb-0">{{selectedEchange.full_name}}</h6>
                            <p class="mb-0 opacity-75"><b><small
                                        class="opacity-50 text-nowrap">{{selectedEchange.created_at}}</small></b></p>
                        </div>
                    </div>
                </a>
            </div>
            <div class="mb-2 " *ngFor='let message of realTimeList'>
                <div class="col-12 overflow-auto my-3">
                    <div class="border-0 d-flex gap-3 list-group-item bg-transparent"
                        [ngClass]="{'float-end me-2':message.to==messageEmitter,'list-group-item-action':message.to!=messageEmitter}"
                        aria-current="true">
                        <img [ngClass]="{'order-2':message.to==messageEmitter}" alt="twbs" width="30"
                            height="30" class="rounded-circle flex-shrink-0 cover" [src]="message.avatar">
                        <div class="d-flex gap-2 w-100 justify-content-between">
                            <div class="text-start align-self-center">
                                <h6 class="mb-0">{{message.full_name}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'bg-mint mb-3 float-start text-start':message.to!=messageEmitter,'bg-light-gray float-end text-end':message.to==messageEmitter}"
                    class="mx-4 p-2 rounded text-start"> {{message.message}}
                </div>
            </div>
        </div>
        <div class="position-absolute w-100 bg-white bg-danger d-flex" [ngClass]="{'d-none':!selectedEchange}"
            style="bottom: 0;">
            <textarea placeholder="Tapez votre message ..." [(ngModel)]="message" class="col form-control mb-0" name=""
                id=""></textarea>
            <div class="p-2">
                <button (click)="send()" [disabled]="!message" class="btn btn-danger rounded-circle"><i
                        class="fas fa-paper-plane"></i></button>
            </div>
        </div>
    </div>
</div>