<ngx-spinner></ngx-spinner>
<button class="d-none" id="openPassCutureModal2" data-bs-toggle="modal" data-bs-target="#passCultureModal">pass
  culture</button>
<div class="cover-container d-flex w-100 h-100 mx-auto flex-column position-relative" id="custom-container">
  <header class="px-2 py-4" [ngClass]="{'hideNav': isIFrame(), 'bg-dark':currentMode=='dark mode'}" id="custom-header">
    <!-- menu -->
    <!--<app-dark-default-nav *ngIf="!isLoggedIn && currentMode=='light mode'"></app-dark-default-nav>
    <app-default-nav *ngIf="!isLoggedIn && currentMode=='dark mode'"></app-default-nav>-->
    <!--<app-dark-connected-nav [showProfileMenu]="showProfileMenu" (click)="showProfileMenu=false"
      [switch_button_text]="switch_button_text" [image]="image"
      *ngIf="isLoggedIn && currentMode!='dark mode' && !isIFrame()"></app-dark-connected-nav>-->
   <!-- <app-light-connected-nav [showProfileMenu]="showProfileMenu" (click)="showProfileMenu=false"
      [switch_button_text]="switch_button_text" [image]="image"
      *ngIf="isLoggedIn && currentMode=='dark mode' && !isIFrame()"></app-light-connected-nav>-->
    <!-- end menu -->
  </header>
  <div class="bg-white bd-sidebar position-absolute h-100" style="z-index: 5;position: fixed !important; right: 0px;">
    <nav [ngClass]="{'show': showProfileMenu}" class="shadow collapse menuCollapsed bd-links py-1 text-center h-100"
      id="bd-docs-nav" (click)="showProfileMenu=false">
      <div class="text-end">
        <img id="closeButton" type="button" data-bs-toggle="collapse" data-bs-target="#bd-docs-nav" class="px-2"
          src="assets/icons/croix.svg" alt="">
      </div>
      <ul class="list-unstyled mb-0">
        <div class="text-center my-4">
          <img [src]="image" alt="mdo" width="70" height="70" class="rounded-circle cover">
          <p class="mt-2">{{full_name}}</p>
        </div>
        <li *ngIf="currentType!='artiste'" class="active text-uppercase pointer">
          <a class="d-block text-start align-items-center p-3 d-flex bg-danger text-white" (click)="menu_switcher()">
            <i [attr.class]="icon-logo"></i>
            {{switch_button_text}}
          </a>
        </li>
        <li *ngFor="let item of items" class="active text-uppercase h-100">
          <hr *ngIf="!item.name">
          <a *ngIf="item.name" (click)="(item.link)?onSelectMenu(item.link):switch(item.action)"
            class="d-block text-start align-items-center p-3 d-flex" [ngClass]="item.class" data-bs-toggle="collapse"
            role="button" aria-expanded="true" aria-current="true">
            <i [attr.class]="item.icon"></i>
            {{ item.name }}
          </a>
        </li>
        <li class="active text-uppercase">
          <a (click)="logout()" class="d-flex text-start align-items-center rounded p-3" data-bs-toggle="collapse"
            href="#" role="button" aria-expanded="true" aria-current="true">
            <i class="icon-deconnexion fs-4 pe-2"></i>
            DÉCONNEXION
          </a>
        </li>
      </ul>
    </nav>
  </div>



  <main class="iframe">
    <div class="d-flex h-100 text-center">
      <section class="text-center w-100">
        <router-outlet (activate)="onActivate($event)"></router-outlet>

      </section>
    </div>
    <!--
<button id="myBtn" (click)="scrollToTop()"><span class="fa fa-arrow-up"></span></button>
    -->

  </main>



</div>
<!-- Button trigger modal -->
<button id="openCSModal" type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#comming_soon">
</button>

<!-- Modal -->
<div class="modal fade" id="comming_soon" tabindex="-1" aria-labelledby="comming_soonLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h2 class="text-uppercase mt-3">prochainement</h2>
        <p>En cours de développement</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary text-primary mx-auto" data-bs-dismiss="modal">Fermer</button>
      </div>
    </div>
  </div>
</div>
