import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private meta: Meta,
    private titleService: Title,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  updateMeta(meta: any) {
    // meta tag og
    this.meta.updateTag({ property: "og:title", content: 'Studiomap - ' + meta.title }, "property='og:title'");
    this.meta.updateTag({ property: "og:description", content: meta.description }, "property='og:description'");
    this.meta.updateTag({ property: "og:url", content: location.origin + meta.url }, "property='og:url'");
    this.meta.updateTag({ property: "og:image", content: meta.image }, "property='og:image'");

    // meta tag twitter
    this.meta.updateTag({ property: "twitter:title", content: 'Studiomap - ' + meta.title }, "property='twitter:title'");
    this.meta.updateTag({ property: "twitter:site", content: location.origin + meta.url }, "property='twitter:site'");
    this.meta.updateTag({ property: "twitter:description", content: meta.description }, "property='twitter:description'");
    this.meta.updateTag({ property: "twitter:image", content: meta.image }, "property='twitter:image'");
  }
  initialyzeMeta() {
    console.log("meta");
    
    this.meta.addTags([
      { name: "og:title", content: 'Studiomap - Home'  },
      { name: "og:description", content: "Gérer et réserver des studios en france" },
      { name: "og:url", content: location.origin },
      { name: "og:image", content: "https://img.audiofanzine.com/image.php?lang=fr&identifier=id&size=normal&module=studioClassified&classified_id=9533" },

      { name: "twitter:title", content: 'Studiomap - Home'},
      { name: "twitter:site", content: location.origin },
      { name: "twitter:description", content: "Gérer et réserver des studios en france" },
      { name: "twitter:image", content: "https://img.audiofanzine.com/image.php?lang=fr&identifier=id&size=normal&module=studioClassified&classified_id=9533" },
    ]);
  }

  setTitle(title: string) {
    this.titleService.setTitle('Studiomap - ' + title);
  }

  loadScript(name: string): void {
    // ssr window or document is not define issue
    if (isPlatformBrowser(this.platformId)) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = name;
      s.async = false;
      document.getElementsByTagName('head')[0].appendChild(s);
    }
  }

  getByPageName(filters: string): Observable<any>{
    return this.http.get(environment.api + "tags"+filters);
  }
}
