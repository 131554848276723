import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { FormService } from 'src/app/services/form/form.service';
import { LinksService } from 'src/app/services/links/links.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {
  Form: FormGroup; socialMediaForm: FormGroup;
  loading = true;
  submitted = false;
  status = { text: '', color: '' };
  showLink = false;
  VerifiedAccount = false;
  canEditPhone = true;
  // avatar
  file: File;
  image: any = environment.defaultAvatar;
  accountLink = "";
  accountId: any;
  user: any;
  social = [
    { name: "Facebook", link: "https://www.Facebook.com" }
    , { name: "Twitter", link: "https://www.Twitter.com" }
    , { name: "Instagram", link: "https://www.Instagram.com" }
    , { name: "Tiktok", link: "https://www.Tiktok.com" }
    , { name: "Youtube", link: "https://www.Youtube.com" }
  ];
  linksToDelete = [];
  //google place (paris france by default)
  options = {
    types: [],
    componentRestrictions: { country: 'FR' }
  };
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  constructor(
    private stripeService: StripeService,
    private emitterService: EmitterService,
    private tokenStorageService: TokenStorageService,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private formService: FormService,
    private linksService: LinksService
  ) {

    this.Form = this.fb.group({
      full_name: ['', Validators.required],
      description: [''],
      name: [''],
      phone: [''],
      email: [''],
      iban: [''],
      lat: [''],
      lng: [''],
      adresse: [''],
      type_service: [''],
      all_city: [false],
    })
    this.socialMediaForm = this.fb.group({
      array: this.fb.array([])
    })
  }
  //validator submit
  get f() { return this.Form.controls; }

  initializeForm(profile: any) {
    this.Form.controls.email.setValue(this.user.email);
    profile.phone = profile.phone.slice(-9);
    this.Form.patchValue(profile);
    this.initialyzeLink(this.socialMediaForm, profile.links);
  }
  checkVerification(userId: string, status: any, noAccountId?: boolean) {
    this.stripeService.checkIsVerify(userId).subscribe(
      res => {
        this.loading = false
        if (res.etat == 'not-found') {
          status.text = "Veuillez remplir le formulaire afin de pouvoir recevoir vos virements";
          status.color = 'text-danger';
        } else {
          this.VerifiedAccount = true;
          this.accountId = res.accountId;
          if (res.etat == "verified") {
            status.text = "votre compte a été vérifié";
            status.color = 'text-success';

          } else {
            this.showLink = true;
            status.text = "votre compte doit êtres vérifié";
            status.color = 'text-warning';
            this.stripeService.createAccountLink(res.accountId).subscribe(link => {
              if (link) {
                this.accountLink = link.url;
                if (noAccountId) {
                  if (typeof window !== 'undefined')
                    window.location.href = link.url;
                }
              }
            })
          }
        }
      },
      err => { console.log(err); },
    )
  }

  async ngOnInit(): Promise<void> {
    this.emitterService.mode.emit("light mode")

    this.user = this.tokenStorageService.getUser();
    this.profileService.getByEmail({ phone: this.user.profile.phone, email: this.user.email, check: true }).subscribe(available => {
      if (available.foundLoginPhone && this.user.profile.phone != '') {
        this.canEditPhone = false;
      }
    })
    if (this.user.type == "freelance") {
      this.Form.controls.lat.setValidators(Validators.required);
      this.Form.updateValueAndValidity();
    }
    this.image = (this.user.profile.avatar) ? this.user.profile.avatar.url : this.image;
    if (this.image.indexOf('http') == -1) this.image = environment.api.slice(0, -1) + this.image;
    this.initializeForm(this.user.profile);
    if (this.user.type == 'gerant') {
      this.checkVerification(this.user.id, this.status);
    } else {
      this.loading = false
    }
  }
  uploadFile(event) {
    var reader = new FileReader();
    this.file = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.image = reader.result;
    }
  }

  async onSubmit() {
    this.submitted = true;
    // _foundVerify && widget.currentUser.type == "gerant" && _ibanEditingController.text.length > 0 && _accountToken != ""
    // !_foundVerify && widget.currentUser.type == "gerant" && _ibanEditingController.text.length > 0
    if (this.Form.invalid) {
      Swal.fire('Erreur', "- Veuillez vérifier vos informations", 'error');
      return;
    }
    if (this.socialMediaForm.invalid) {
      Swal.fire('Erreur', 'Veuillez renseigner des liens réseaux sociaux valides', 'error');
      return;
    }
    if (this.user.type != 'freelance') {
      this.formService.emptyControl(this.Form, 'description');
    }
    this.spinner.show();
    let profile = this.Form.value;
    let data = {
      full_name: profile.full_name,
      description: profile.description,
      name: profile.name,
      phone: profile.phone,
      lat: profile.lat,
      lng: profile.lng,
      adresse: profile.adresse,
      all_city: profile.all_city,
    }
    let result: any;
    if (this.user.type == 'gerant' && this.Form.value.iban != "") {
      if (!this.VerifiedAccount) {
        await this.stripeService.createAccountConnect(profile.full_name, profile.email, profile.iban).then((res: any) => {
          this.stripeService.update(this.user.stripe.id, res).subscribe(update => {
            this.checkVerification(this.user.id, this.status, true);
          }, err => {
            Swal.fire('Erreur', "une erreur s'est produite", "error");
            this.spinner.hide()
            return
          })
        }).catch(err => {
          result = err;
          Swal.fire('Erreur', err, "error");
        }).finally(() => {
          this.spinner.hide()
        })
      } else {
        await this.stripeService.updateAccountConnect(this.accountId, profile.email, profile.full_name, profile.iban, this.tokenStorageService.getUser().id).then((res: any) => {
          this.checkVerification(this.user.id, this.status);
        }).catch(err => {
          result = err;
          Swal.fire('Erreur', err, "error");
          return
        }).finally(() => {
          this.spinner.hide()
        })
      }
      if (result) {
        return
      }
    }
    this.profileService.update_profile(this.user.profile.id, data, this.file, this.tokenStorageService.getToken()).subscribe((res: any) => {
      let encapsulatedObject = {};
      encapsulatedObject = this.tokenStorageService.getUser();
      encapsulatedObject['profile'] = res;
      if (this.accountId) {
        encapsulatedObject['stripe']['account_id'] = this.accountId;
      }
      // create links
      this.linksService.create(this.socialMediaForm.value).subscribe(type => {
        encapsulatedObject['profile']['links'] = type;
        // delete types
        this.linksService.delete({ ids: this.linksToDelete }).subscribe(deleteType => { }, err => {
          console.log("delete link error");
          this.spinner.hide();
        })
        this.emitterService.getLoggedUser.emit(encapsulatedObject);
        this.tokenStorageService.saveUser(encapsulatedObject);

        if (this.isIFrame())
          parent.postMessage(JSON.stringify({ userData: encapsulatedObject }), environment.webflowURL);

      }, err => {
        console.log("error type");
        this.spinner.hide();
      })
      Swal.fire('Félicitation', "Profile modifié avec succès", "success");
      this.Form.controls.iban.setValue("");
      this.spinner.hide();
    }, err => {
      Swal.fire('Erreur', 'Une erreur est survenue', "error");
      this.spinner.hide();
    })
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // social media
  createLinks(name: string, link: string) {
    return this.fb.group(
      {
        name: name,
        checked: true,
        link: [link, [Validators.required, this.formService.link_validator]],
        profile: this.user.profile.id
      })
  }

  addLink(form: FormGroup, name: string, link: string) {
    const control = <FormArray>form.get('array');
    control.push(this.createLinks(name, link));
  }

  selectLink(form: FormGroup, event: any) {
    if (event.target.checked) {
      this.addLink(form, event.target.value, null);
    }
    else {
      this.deleteArray(form, this.arrayIndex(form, event.target.value));
    }
  }
  deleteArray(form: FormGroup, i: number) {
    const control = <FormArray>form.get('array');
    control.removeAt(i);
  }

  arrayIndex(form: FormGroup, name: string) {
    let array: Array<any> = form.value['array'];
    return array.findIndex(x => x.name == name);
  }

  initialyzeLink(form: FormGroup, links: any) {
    if (links) {
      links.forEach(items => {
        this.linksToDelete.push(items.id);
        this.addLink(form, items.name, items.link);
      })
    }
  }

  public handleAddressChange(address: Address) {
    var addressComponents = address;
    this.getAdress(addressComponents);
  }

  getAdress(addressComponents) {
    this.Form.patchValue({
      lat: addressComponents.geometry.location.lat().toString(),
      lng: addressComponents.geometry.location.lng().toString(),
      adresse: addressComponents.formatted_address
    });
  }

  // supprimer mon compte
  deleteAccount() {
    Swal.fire({
      title: "Étes-vous sûre de vouloir supprimer votre compte ?",
      cancelButtonText: 'Annuler',
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: "Supprimer",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.spinner.show();
        this.profileService.deleteMyAccount().subscribe(user => {
          Swal.fire("Supprimé" + '!', 'Votre compte a été supprimer avec succès', 'success');
        }, err => {
          if (this.user.role.type == "super_admin") {
            Swal.fire("Érreur" + '!', 'Impossible de supprimer ce compte sur le site,Veuillez faire cette action depuis le backoffice', 'error');
          } else {
            Swal.fire("Érreur" + '!', 'Une érreur est survenue', 'error');
          }
        }).add(() => {
          this.spinner.hide();
          this.tokenStorageService.signOut();
        })
      }
    }).finally(() => {
      this.spinner.hide();
    })
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }
}
