import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ParrainageService {
  endPoint = 'parrainages/';
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  create(to: string, from: string): Observable<any> {
    return this.http.post(environment.api + this.endPoint, {
      from: from,
      to: to,
      etat: true
    },
    );
  }

  getList(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "?from=" + this.tokenStorageService.getUser().id + "&etat=true", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    })
  }

  update(list: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint + "update", JSON.stringify({ "array": list }), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    })
  }
}
