import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  create_profile(user: string, email: string, full_name: string, phone: any, adresse: string, lng: string, lat: string, type_service: string, all_city: string): Observable<any> {
    phone = "0" + String(phone * 1);
    return this.http.post(environment.api + 'profiles', {
      user,
      email,
      full_name,
      phone,
      adresse,
      lat,
      lng,
      type_service,
      all_city
    }, httpOptions
    );
  }

  getByEmail(data: any): Observable<any> {
    if (data.phone) {
      data.phone = "0" + String(data.phone * 1);
    }
    return this.http.post(environment.api + 'profiles/getProfileByEmail', data, httpOptions)
  }

  update_profile(profileId: string, data: any, avatar: File, token: any): Observable<any> {
    const formData = new FormData();
    formData.append("files.avatar", avatar);
    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + 'profiles/' + profileId, formData,
      {
        headers: { Authorization: 'Bearer ' + token }
      });
  }


  get_profile_by_id(profileId: string): Observable<any> {
    return this.http.get(environment.api + "profiles/" + profileId, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  deleteMyAccount(): Observable<any> {
    return this.http.post(environment.api + "profiles/deleteMyAccount", {}, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  find(filter: any, socialToken?: string): Observable<any> {
    let token = (socialToken) ? socialToken : this.tokenStorageService.getToken()
    return this.http.get(environment.api + "profiles/?" + filter, {
      headers: { Authorization: 'Bearer ' + token }
    });
  }

  get_profile_by_user_id(userId: string): Observable<any> {
    return this.http.get(environment.api + "profiles/?user=" + userId, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }
}
