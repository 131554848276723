import { Component, OnInit } from '@angular/core';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { MetaService } from 'src/app/services/meta/meta.service';
import { SeoService } from 'src/app/services/seo/seo.service';

@Component({
  selector: 'app-politique-confidentialite',
  templateUrl: './politique-confidentialite.component.html',
  styleUrls: ['./politique-confidentialite.component.css']
})
export class PolitiqueConfidentialiteComponent implements OnInit {

  constructor(private emitterService: EmitterService,private metaService:MetaService,private seoService:SeoService) { }

  ngOnInit(): void {
    this.emitterService.mode.emit("dark mode");

    this.metaService.getByPageName("?page=politique-confidentialite&item=politique-confidentialite").subscribe(res => {

      if(res.length > 0) {
        this.seoService.generateTags({
          title: 'Studiomap : '+res[0].title,
          description: res[0].description,
          image: res[0].image.url,
          slug: ''
        });
      }
      
    });
  }

}
