import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ReservationsService } from 'src/app/services/reservations/reservations.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  Form: FormGroup;
  submitted = false;
  code = "";
  path = "";

  constructor(private reservationService: ReservationsService, private tokenStorage: TokenStorageService, private profileService: ProfileService, private route: ActivatedRoute, private emitterService: EmitterService, public router: Router, private fb: FormBuilder, private authService: AuthService) {
    this.Form = this.fb.group({
      code: [''],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    }, { validators: this.checkPasswords })
  }

  ngOnInit(): void {
    this.emitterService.mode.emit("light mode");
    this.route.queryParams.subscribe(res => {
      this.code = res.code;
    })
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.Form.invalid) {
      Swal.fire('Error', 'Veuillez vérifier vos informations', 'error')
      return
    }

    this.authService.resetPassword(this.code, this.Form.value.password, this.Form.value.confirmPassword).subscribe(data => {
      Swal.fire('Félicitation', 'Votre mot de passe a été changé avec succès', 'success');
      let encapsulatedUser: any = {}
      encapsulatedUser = data.user;
      if (!data.user.stripe) {
        encapsulatedUser.stripe = { customer_id: "" };
      }
      if (data.user.type == "freelance") {
        encapsulatedUser.type = "artiste";
      }
      if (data.user.profile != null) {
        this.tokenStorage.saveToken(data.jwt);
        this.profileService.get_profile_by_id(data.user.profile.id).subscribe(profile => {
          encapsulatedUser.profile = profile;
          this.emitterService.getLoggedUser.emit(encapsulatedUser);
          this.tokenStorage.saveUser(encapsulatedUser);
          this.reservationService.salary_bilan(data.user.id).then((team: any) => {
            (team.length > 0) ? this.emitterService.menu_has_salary_bilan.emit(true) : this.emitterService.menu_has_salary_bilan.emit(false);
          })
          this.reservationService.prestations_assigned_to_gerant(data.user.id).then((team: any) => {
            (team.length > 0) ? this.emitterService.menu_has_calendar_prestation.emit(true) : this.emitterService.menu_has_calendar_prestation.emit(false);
          })
          this.reditrection(data);
        })
      }
    }, err => {
      if (err.error.message[0].messages[0].id == "Auth.form.error.code.provide") {
        Swal.fire('Erreur', 'Lien expiré veuillez tester à nouveau', 'error')
      }
      console.log(err);
    })
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { notSame: true }
  }

  reditrection(data: any) {
    localStorage.setItem('type', data.user.type);
    if (localStorage.getItem("reservation")) {
      let reservation = JSON.parse(localStorage.getItem("reservation"));
      if (data.user.type == 'gerant' || data.user.type == 'freelance') {
        localStorage.setItem('current_type', "artiste");
      }
      this.router.navigate(["/artiste/fiche-studio/" + reservation.studio]);
    } else {
      if (data.user.type == 'gerant' || data.user.type == 'freelance') {
        localStorage.setItem('current_type', data.user.type);
      }
      switch (data.user.type) {
        case "artiste":
          this.path = "/" + data.user.type + "/reserver-studio";
          break;
        case "gerant":
          this.path = "/" + data.user.type + "/tableau-de-bord";
          break;
        case "freelance":
          this.path = "/" + data.user.type + "r/tableau-de-bord";
          break;
      }
      this.router.navigate([this.path]);
    }
  }

  //validator submit
  get f() { return this.Form.controls; }

}
