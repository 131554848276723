import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PassCulture } from 'src/app/classes/passculture.model';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { PassCultureService } from 'src/app/services/pass-culture/pass-culture.service';
import { ReservationsService } from 'src/app/services/reservations/reservations.service';
import { StudioService } from 'src/app/services/studio/studio.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'pass-culture-modal',
  templateUrl: './pass-culture-modal.component.html',
  styleUrls: ['./pass-culture-modal.component.css']
})
export class PassCultureModalComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  constructor(private tokenService: TokenStorageService, private reservationService: ReservationsService, private router: Router, private studioService: StudioService, private spinner: NgxSpinnerService, private fb: FormBuilder, private passcultrureService: PassCultureService) {
    this.form = this.fb.group({
      contremarque: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]]
    })
  }

  get controls() {
    return this.form.controls
  }

  ngOnInit(): void {
  }

  changeTextToUppercase(field) {
    const obj = {};
    obj[field] = this.form.controls[field].value.toUpperCase();
    this.form.patchValue(obj);
  }

  onSubmit() {

    this.passcultrureService.getDetailsReservationFromPassCulture(this.form.value.contremarque).subscribe(datasFromPassCulture => {
      if (datasFromPassCulture.bookingId) {
        this.passcultrureService.getDetailsOfferFromPassCulture(datasFromPassCulture.offerId).subscribe(offerDetails => {
          if (offerDetails != null) {
            let studioId = (offerDetails.externalTicketOfficeUrl != "" && offerDetails.externalTicketOfficeUrl != null) ? ((offerDetails.externalTicketOfficeUrl.split("/").length > 0) ? offerDetails.externalTicketOfficeUrl.split("/")[offerDetails.externalTicketOfficeUrl.split("/").length - 1] : null) : null;
            if (studioId != null) {
              this.studioService.get_studio_by_id(studioId).subscribe(searchStudio => {
                if (searchStudio != null) {
                  if (searchStudio.is_draft || searchStudio.is_deleted) {
                    Swal.fire({
                      title: "Attention",
                      text: "impossible de faire une reservation avec ce code. \n le studio a été bloqué",
                      icon: "info",
                      heightAuto: false
                    });
                  } else {
                    if (searchStudio.user == "") {
                      Swal.fire({
                        title: "Attention",
                        text: "Ce studio est non partenaire",
                        icon: "info",
                        heightAuto: false
                      });
                    } else {

                      let body = {
                        "studioName": searchStudio.name,
                        "studioAddress": searchStudio.adresse,
                        "gerantPhone": searchStudio.user.profile.phone,
                        "bookingName": datasFromPassCulture.userName,
                        "bookingPhone": datasFromPassCulture.phoneNumber,
                        "bookingId": this.form.value.contremarque,
                        "nbr_hours": this.getNbrHoursFromOfferName(datasFromPassCulture.offerName)
                      };

                      this.passcultrureService.validate_reservation(this.form.value.contremarque, body).subscribe((result_validate_code: any) => {

                        /*
                        this.spinner.hide();
                        document.getElementById("closepassCultureModal").click();
                        if (result_validate_code != null) {
                          Swal.fire('Validation contremarque', "La contremarque a été validée avec avec succès", "info");
                        } else {
                          Swal.fire('Érreur 704', "une erreur s'est produite", "error");
                        }
                        */

                      }, err => {

                      }).add(() => {
                        this.spinner.hide();
                        document.getElementById("closepassCultureModal").click();
                        Swal.fire('Validation contremarque', "La contremarque a été validée avec avec succès", "info");
                      });


                    }
                  }
                } else {
                  Swal.fire({
                    title: "Attention",
                    text: "impossible de confirmer ce code : studio introuvable",
                    icon: "info",
                    heightAuto: false
                  });
                  this.spinner.hide();
                }
              }, (errorStudio) => {
                Swal.fire({
                  title: "Attention",
                  text: "impossible de confirmer ce code : studio introuvable. \n\n Merci de contacter le support",
                  icon: "info",
                  heightAuto: false
                });
                this.spinner.hide();
              });
            } else {
              Swal.fire({
                title: "Attention",
                text: "impossible de confirmer ce code : studio introuvable. \n\n Merci de contacter le support",
                icon: "info",
                heightAuto: false
              });
              this.spinner.hide();
            }

          } else {
            Swal.fire({
              title: "Attention",
              text: "impossible de faire une reservation avec ce code",
              icon: "info",
              heightAuto: false
            });
            this.spinner.hide();
          }
        }, err => {
          this.spinner.hide();
          Swal.fire({
            title: "Attention",
            text: "impossible de faire une reservation avec ce code",
            icon: "info",
            heightAuto: false
          });
        });
      } else {
        const keys = Object.keys(datasFromPassCulture);
        let message = (keys.length > 0) ? datasFromPassCulture[keys[0]][0] : "Cette contremarque n'a pas été trouvée";
        Swal.fire({
          title: "Attention",
          text: message,
          icon: "info",
          heightAuto: false
        });
        this.spinner.hide();
      }
    }, err => {
      Swal.fire({
        title: "Attention",
        text: (err["error"] && err["error"][Object.keys(err["error"])[0]]) ? err["error"][Object.keys(err["error"])[0]][0] : "Cette contremarque n'a pas été trouvée",
        icon: "info",
        heightAuto: false
      });
      this.spinner.hide();
    });

  }

  getNbrHoursFromOfferName(offerName: string) {
    let nbrHours: number = 2;
    var array = offerName.split("h");
    for (let i = 0; i < array.length - 1; i++) {
      let str: string = array[i].substring(array[i].length - 1, array[i].length);
      if (!isNaN(Number(str))) {
        nbrHours = Number(str);
      }
    }
    return nbrHours;
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }
}
