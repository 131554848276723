<div class="container mt-5 p-5 text-start">
    <p>Les présentes Conditions d’utilisation du site Web STUDIOMAP (les « Conditions générales de STUDIOMAP »ou les
        « Conditions générales ») régissent votre utilisation du site STUDIOMAP.fr (« STUDIOMAP.fr » ou le « site Web
        STUDIOMAP »), exploité par STUDIOMAP SAS et ses sociétés affiliées (« STUDIOMAP » ou « nous »).STUDIOMAP.fr
        inclut une plate-forme sur laquelle les personnes qui se sont inscrites pour obtenir un compte d’utilisateur
        STUDIOMAP.fr peuvent commander des Services ou proposer leurs services entant que photographes (la « plate-forme
        STUDIOMAP »). En utilisant le site Web STUDIOMAP et/ou la plate-forme STUDIOMAP, vous acceptez les présentes
        Conditions générales. </p>
    <p>Les particuliers et les entreprises («Vous») peuvent se rendre sur le site Web STUDIOMAP et la plate-forme
        STUDIOMAP en libre accès. Si vous avez l’intention d’utiliser le site Web STUDIOMAP pour le compte de votre
        employeur, d’une entreprise ou d’une autre personne morale, toutes les références à Vous sont également des
        références à cette entreprise, et votre employeur, entreprise ou personne morale est juridiquement lié par
        les présentes Conditions. Merci de lire attentivement les présentes Conditions. </p>
    <h6>CRÉATION DE COMPTE</h6>
    <p>Les personnes physiques et morales (les « Clients ») qui souhaitent commander des photographies, des
        vidéographies, des services associés et des médias (respectivement les «services » et les « produits
        livrables ») depuis le site STUDIOMAP, ainsi que les photographes souhaitant proposer des services de
        photographie ou de vidéographie (les « photographes ») via la plate-forme STUDIOMAP peuvent créer un compte
        sur STUDIOMAP.fr (un « compte d’utilisateur »). Vous ne pouvez créer qu’un seul compte d’utilisateur sur
        STUDIOMAP.fr. Votre compte d’utilisateur STUDIOMAP.fr peut vous permettre d’accéder à d’autres sites Web de
        STUDIOMAP, auquel cas d’autres conditions peuvent s’appliquer. </p>
    <p>Veuillez noter que vous devez avoir au moins 18 ans pour créer un compte d’utilisateur. </p>
    <p>En créant un compte d’utilisateur, vous déclarez et garantissez que toutes les informations que vous fournissez
        dans le cadre de votre inscription et de votre utilisation du site Web et de la plate-forme STUDIOMAP sont et
        demeureront exactes et complètes, et que vous gérerez et actualiserez régulièrement ces informations. </p>
    <p>Vous n’êtes pas autorisé à partager les informations de votre compte d’utilisateur avec qui que ce soit. Vous
        êtes seul responsable de la confidentialité et de la sécurité de votre compte d’utilisateur. </p>
    <h6>PLATE-FORME STUDIOMAP</h6>
    <p>Pour accéder à la plate-forme STUDIOMAP, vous devez vous connecter à votre compte d’utilisateur. Toutes les
        commandes de services et de produits livrables réalisées via la plate-forme STUDIOMAP sont régies par les
        présentes Conditions, ainsi que par tout autre accord conclu entre STUDIOMAP et vous. </p>
    <h6>Commande de services sur la plateforme STUDIOMAP</h6>
    <p>La plate-forme STUDIOMAP permet aux clients de commander des services et des produits livrables que STUDIOMAP met
        à disposition. Lorsque vous passez commande, nous vous demanderons d’accepter des conditions supplémentaires
        qui régiront votre utilisation des services du photographe impliqué. Lorsque les produits livrables sont
        prêts, vous pouvez y accéder via la plate-forme STUDIOMAP, sauf accord contraire convenu entre les Parties.
    </p>


    <h6>Devenir photographe sur la plateforme STUDIOMAP </h6>
    <p>Si vous souhaitez devenir photographe sur la plate-forme STUDIOMAP, vous devez conclure un contrat séparé avec
        STUDIOMAP (« Contrat de photographe ») qui vous autorisera à proposer vos services de photographie aux clients
        STUDIOMAP, en fonction de votre emplacement, votre disponibilité, votre expérience, vos compétences et votre
        expertise. Si vous souhaitez obtenir plus d’informations pour devenir photographe sur la plate-forme STUDIOMAP,
        veuillez nous contacter à l’adresse suivante : contact@studiomap.fr.</p>
    <p>CONTENU STUDIOMAP </p>
    <p>STUDIOMAP peut mettre à votre disposition certains renseignements tels que les coordonnées du client, des
        directives et/ou d’autres documents. Nous (et, dans certains cas, nos concédants de licence) détenons tous les
        droits sur ces renseignements. Sauf autorisation expresse formulée ailleurs sur le site, vous n’êtes pas
        autorisé à copier, publier et/ou afficher ces renseignements. </p>
    <h6>Contenu de tiers </h6>
    <p>Vous pouvez trouver du contenu tiers sur le site Web STUDIOMAP et/ou la plate-forme STUDIOMAP, des informations
        sur des clients, des directives et/ou autre documentation. Ce contenu appartient à des tiers et nous ne sommes
        pas responsables de sa teneur, de sa cohérence et/ou de sa disponibilité. En outre, vous n’êtes pas autorisé
        à utiliser ce contenu tiers, à moins que vous ne receviez l’autorisation de son propriétaire. </p>
    <h6>RESTRICTIONS </h6>
    <p>En utilisant le site Web STUDIOMAP et/ou la plate-forme STUDIOMAP, vous acceptez de vous conformer à l’ensemble
        des lois, règles et réglementations fédérales, régionales, locales ou provinciales, qui s’appliquent à
        votre utilisation du site Web STUDIOMAP, de la plate-forme STUDIOMAP et à tout contenu que vous transmettez sur
        le site Web et/ou la plate-forme STUDIOMAP et/ou via leur intermédiaire, y compris, sans toutefois s’y limiter,
        l’ensemble des lois sur le droit d’auteur et les lois et/ou réglementations en matière de protection des
        données. </p>
    <p>En ce qui concerne Vos fichiers et Votre Compte utilisateur, Vous ne pouvez : </p>
    <p>● créer un compte d’utilisateur si nous avons déjà désactivé ou supprimé un compte d’utilisateur qui vous
        appartenait ; </p>
    <p>● transmettre ou partager du contenu contenant des images pédopornographiques ou qui incite à la haine ; </p>
    <p>● transmettre tout contenu obscène, grossier, indécent, discriminatoire, menaçant, abusif, nuisible, vulgaire,
        diffamatoire, injurier ou autrement illégal ; </p>
    <p>● transmettre des documents défendant ou faisant l’apologie du racisme, de la violence ou de la haine ; </p>
    <p>● usurper l’identité d’une personne ou d’une société, ou faussement prétendre être affiliée à une </p>
    <p>personne ou à une société ;</p>
    <p>En ce qui concerne votre utilisation de STUDIOMAP.fr et/ou de la plate-forme STUDIOMAP, vous ne pouvez pas : </p>
    <p>● supprimer ou modifier tout droit d’auteur, marque de commerce, confidentialité ou autres mentions légales,
        avertissements légaux ou marques propriétaires ; </p>
    <p>● accéder (ou tenter d’accéder) à des zones qui ne sont pas réservées au public du site Web STUDIOMAP et/ou
        de la plate-forme STUDIOMAP ; </p>
    <p>● interférer avec des restrictions d’accès ou d’utilisation, ou empêcher (ou tenter d’empêcher) un autre
        utilisateur d’accéder ou d’utiliser le site Web STUDIOMAP et/ou la plate-forme STUDIOMAP, ou perturber le site
        Web STUDIOMAP et/ou la plate-forme STUDIOMAP ; </p>
    <p>● utiliser un robot, une araignée ou un autre moyen automatisé pour accéder ou scanner le site Web et/ou la
        plate-forme STUDIOMAP, créer une « trame » ou un « miroir » d’une partie du site Web, de la plate-forme
        STUDIOMAP et/ou des Services, ou créer une activité qui concurrence les services STUDIOMAP ; </p>
    <p>● utiliser des méthodes d’exploration, de collecte ou d’extraction de données, ou recueillir de toute autre
        manière des informations sur le site Web STUDIOMAP et/ou la plate-forme STUDIOMAP, et/ou ses visiteurs et/ou
        utilisateurs ; </p>
    <p>● recueillir et/ou enregistrer des données à caractère personnel concernant toute personne ou entité ; </p>
    <p>● envoyer des virus, des vers, des programmes malveillants, des ransomwares, des courriers indésirables, des
        spams, des chaînes de lettres, des e-mails de hameçonnage, des messages non </p>
    <p>sollicités, des emails promotionnels ou des publicités de tout type et pour quelque raison que ce soit ; </p>
    <p>● essayer de sonder, scanner, compromettre ou tester la vulnérabilité du site Web STUDIOMAP et/ou de la
        plate-forme STUDIOMAP, du système ou du réseau ou d’enfreindre tout système de sécurité ou </p>
    <p>d’authentification ; </p>
    <p>● de procéder à de l’ingénierie inverse ou décompiler toute (partie) du site Web STUDIOMAP et/ou de la </p>
    <p>plate-forme STUDIOMAP ; </p>
    <p>● de décompiler, désassembler ou procéder à de l’ingénierie inverse, ou de déterminer tout code source,
    </p>
    <p>algorithme, méthode ou technique du site Web et/ou de la plate-forme STUDIOMAP ; </p>
    <p>● revendre, concéder en sous-licence, louer ou commercialiser de toute autre manière tout service, offre, </p>
    <p>produit et/ou fonctionnalité de STUDIOMAP ; </p>
    <p>● utiliser le site Web et/ou la plate-forme STUDIOMAP à des fins illégales ou non autorisées. </p>
    <h6>PLAINTE CONCERNANT LES DROITS D'AUTEUR </h6>
    <p>STUDIOMAP respecte toutes les lois applicables en matière de droit d’auteur et prend très au sérieux toute
        violation du droit d’auteur. STUDIOMAP traitera les plaintes relatives aux droits d’auteur qui lui sont soumises
        à l’adresse contact@studiomap.fr conformément à la législation sur les droits d’auteur applicable. Lorsque
        vous déposez une plainte, veuillez fournir les informations suivantes : </p>
    <p>● vos coordonnées, notamment votre nom complet, votre adresse, votre numéro de téléphone et votre adresse
        e-mail ; </p>
    <p>● une identification de l’œuvre protégée concernée et de son emplacement sur le site Web et/ou la plate-forme
        STUDIOMAP (un lien vers la photo, par exemple) ; </p>
    <p>● une déclaration attestant que vous pensez de bonne foi que l’œuvre protégée par le droit d’auteur que vous
        avez identifiée est utilisée sans autorisation (que ce soit par son propriétaire, son agent ou du fait de la
        loi) ; et </p>
    <p>● toute autre information nécessaire au traitement de votre plainte et/ou toute autre information requise par la
        loi applicable en vertu de laquelle vous déposez votre plainte. </p>
    <p>Nous répondrons aux plaintes dans l’ordre où nous les avons reçues. L’envoi de plusieurs plaintes
        n’accélérera pas leur traitement. Veuillez noter que les informations concernant votre plainte, notamment
        votre nom, votre email, votre adresse et autres informations, seront transmises à l’utilisateur impliqué dans
        la plainte. Si vous préférez que vos renseignements ne soient pas divulgués, vous pouvez désigner un agent
        pour déposer la plainte en votre nom. </p>
    <h6>CONFIDENTIALITÉ </h6>
    <p>Vous utilisez le site Web STUDIOMAP et/ou la plate-forme STUDIOMAP en vous conformant à notre politique de
        confidentialité, disponible sur l’adresse www.studiomap.fr (« Politique de confidentialité »), qui est
        incorporée par renvoi aux présentes dans son intégralité. </p>
    <h6>PROMOTIONS ET CONCOURS </h6>
    <p>STUDIOMAP peut périodiquement organiser des loteries, des concours, des tirages au sort ou des promotions
        similaires (les « Promotions ») via le site Web STUDIOMAP et/ou la plate-forme STUDIOMAP. Ces promotions peuvent
        être régies par des règles distinctes des présentes Conditions. En cas de conflit entre le règlement des
        Promotions et les présentes Conditions, ces dernières prévaudront, mais uniquement pour ce qui a trait aux
        Promotions. </p>
    <p>DÉSACTIVATION, RÉSILIATION </p>
    <p>Vous pouvez désactiver votre compte d’utilisateur sur le(s) site(s) à tout moment en accédant aux paramètres
        de votre compte. </p>
    <p>Si vous êtes photographe et que vous avez créé un compte d’utilisateur dans le but de proposer des services de
        photographie et/ou de vidéographie via la plate-forme STUDIOMAP, la désactivation de votre compte
        d’utilisateur ne met pas fin à votre Contrat de photographe. Si vous désactivez votre compte, STUDIOMAP ne
        pourra être tenu responsable des informations, des contenus ou des médias figurant sur votre compte qui
        continuent d’être stockés ou qui sont perdus par la suite. </p>
    <p>Si vous êtes client et que vous avez passé une commande sur la plate-forme STUDIOMAP conformément aux
        conditions générales, que vous les avez acceptées de toute autre manière ou si avez conclu un accord de
        services avec STUDIOMAP, la désactivation de votre compte d’utilisateur ne mettra pas fin aux Conditions
        générales, ou tout autre accord conclu avec STUDIOMAP. Si vous désactivez votre compte, STUDIOMAP ne pourra
        être tenu responsable des informations, des contenus ou des médias figurant sur votre compte qui continuent
        d’être stockés ou qui sont perdus par la suite. </p>
    <p>Nous pouvons suspendre l’accès à votre compte d’utilisateur et à tous les droits qui vous sont accordés en
        vertu des présentes Conditions si vous avez omis à plusieurs reprises d’effectuer le(s) paiement(s)
        applicable(s) à une commande à son/leur échéance ou si un paiement dû pour une commande est en souffrance.
        Si nous suspendons votre compte d’utilisateur en raison d’un défaut de paiement, vous ne pourrez prétendre à
        aucun type de remboursement. </p>
    <p>Nous pouvons résilier votre compte d’utilisateur et tous les droits qui vous sont accordés en vertu des
        présentes Conditions si, à notre entière discrétion, nous estimons qu’une telle résiliation sert les
        intérêts de STUDIOMAP. Dans ce cas, nous pouvons, sans engager notre responsabilité, supprimer votre compte
        d’utilisateur ainsi que toutes les informations et le contenu qui lui sont associés. Si nous résilions votre
        compte d’utilisateur parce que vous ne respectez pas les Conditions générales de STUDIOMAP, vous ne pourrez
        prétendre à aucun type de remboursement. </p>
    <h6>PRESTATIONS DE TIERS </h6>
    <p>Le site Web STUDIOMAP et la plate-forme STUDIOMAP comprennent des services et des fonctionnalités fournis par
        des tiers. Dans certains cas, ces tiers peuvent ne pas être identifiés. Vous reconnaissez et acceptez que (A)
        tous les services tiers proposés ou utilisés en connexion avec le site Web et/ou la plateforme STUDIOMAP sont
        soumis aux conditions d’utilisation et/ou politiques de ces tiers, (B) STUDIOMAP n’est pas responsable de ces
        services et/ou politiques tiers, et (C) STUDIOMAP peut partager vos données avec ces tiers pour pouvoir
        utiliser leurs services, ce que nous ferons uniquement en vertu de notre politique de confidentialité. </p>
    <p>Bien que l’accès à STUDIOMAP.fr, la plate-forme STUDIOMAP et la création d’un compte STUDIOMAP.fr soient
        gratuits, sachez que les fournisseurs tiers peuvent facturer des frais pour le traitement des paiements. </p>
    <h6>INDISPONIBILITÉ </h6>
    <p>Les offres, produits et fonctionnalités de STUDIOMAP.fr et de la plate-forme STUDIOMAP sont constamment
        développés et améliorés. En conséquence, et à l’entière discrétion de STUDIOMAP,certaines
        fonctionnalités (qu’elles soient ou non identifiées comme bêta) peuvent être modifiées,supprimées
        temporairement et/ou suspendues. Nous n’engageons pas notre responsabilité pour les offres, produits et/ou
        fonctionnalités présentés sur le site Web STUDIOMAP et/ou la plate-forme STUDIOMAP qui deviennent
        temporairement ou définitivement indisponibles. </p>
    <h6>LIMITATION DE RESPONSABILITÉS </h6>
    <p>LE SITE WEB STUDIOMAP ET LA PLATE-FORME STUDIOMAP SONT FOURNIS « TELS QUELS ».DANS TOUTE LA MESURE PERMISE PAR LA
        LOI, STUDIOMAP NE DONNE AUCUNE GARANTIE, EXPRESSE, IMPLICITE, LÉGALE OU AUTRE (Y COMPRIS, SANS TOUTEFOIS S’Y
        LIMITER, LES GARANTIES DE QUALITÉ MARCHANDE,D’ADÉQUATION À UN USAGE PARTICULIER ET DE NON-VIOLATION).
        STUDIOMAP NE GARANTIT PAS QUE LE FONCTIONNEMENT DU SITE WEB STUDIOMAP ET / OU DE LA PLATE-FORME DE STUDIOMAP
        SERA ININTERROMPU, SÉCURISÉ OU NE COMPORTERA PAS D’ERREUR, NI QUE LES ERREURS OU LES DÉFAILLANCES DE SYSTÈME
        SERONT CORRIGÉES. STUDIOMAP N’EST PAS RESPONSABLE DE LA PERTE OU DES DOMMAGES SUBIS PAR VOTRE CONTENU, NI DE
        L’IMPACT QUE LES PROBLÈMES QUE VOUS RENCONTREZ SUR LE SITE WEB STUDIOMAP ET / OU SUR LA PLATE-FORME STUDIOMAP
        PEUVENT AVOIR SUR VOTRE RELATION AVEC VOS CLIENTS. VOUS UTILISEZ LE SITE WEB STUDIOMAP ET / OU LA PLATE-FORME
        STUDIOMAP À VOS RISQUES ET PÉRILS, ET VOUS ÊTES TENU DE RESPECTER TOUTES LES LOIS, RÈGLES ET
        RÉGLEMENTATIONS LOCALES, Y COMPRIS LES RÈGLES LOCALES RELATIVES AU COMPORTEMENT À ADOPTER SUR INTERNET ET AU
        CONTENU AUTORISÉ. DANS TOUTE LA MESURE PERMISE PAR LES LOIS APPLICABLES ET NONOBSTANT TOUT MANQUEMENT À
        L’OBJET ESSENTIEL DE TOUT RECOURS LIMITÉ, (1) EN AUCUN CAS STUDIOMAP NE SERA RESPONSABLE DES
        DOMMAGES-INTÉRÊTS SPÉCIAUX, INDIRECTS, </p>

    <p>CONSÉCUTIFS OU TOUT AUTRE DOMMAGE-INTÉRÊT SIMILAIRE DE QUELQUE NATURE QUE CE SOIT (QUELQUE SOIT LA CAUSE OU LE
        PRINCIPE DE RESPONSABILITÉ, QU’IL DÉCOULE D’UN CONTRAT, D’UN DÉLIT (Y COMPRIS, SANS TOUTEFOIS S’Y LIMITER,
        TOUT ACTE DE NÉGLIGENCE) OU AUTRE), Y COMPRIS, SANS S’Y LIMITER, TOUTE PERTE DE PROFIT, PERTE DE REVENUS, PERTE
        D’UTILISATION, PERTE DE DONNÉES, PERTE D’EXPLOITATION,TOUTE ACQUISITION DE BIENS OU SERVICES DE SUBSTITUTION OU
        AUTRE DÉPENSE, OU DE TOUTE AUTRE PERTE COMMERCIALE OU ÉCONOMIQUE SIMILAIRE, MÊME SI STUDIOMAP EN A ÉTÉ
        INFORMÉE, ET STUDIOMAP NE POURRA ÊTRE TENUE RESPONSABLE DES DOMMAGES, QUELS QU’ILS SOIENT, RÉSULTANT D’UN CAS
        DE FORCE MAJEURE OU D’UN ACTE DE TIERS OU S’ILS NE SONT PAS DE SON FAIT ; ET (2) LA RESPONSABILITÉ COLLECTIVE
        TOTALE CUMULÉE DE STUDIOMAP POUR TOUS LES COÛTS, PERTES OU DOMMAGES RÉSULTANT DE TOUTES RÉCLAMATIONS,
        ACTIONS OU POURSUITES, QUELLE QU’EN SOIT LA CAUSE,DÉCOULANT DU SITE WEB STUDIOMAP OU EN RELATION AVEC CELUI-CI
        NE DÉPASSERA PAS 500 $ OU LE MONTANT QUE VOUS AVEZ VERSÉ À STUDIOMAP POUR UTILISER LES SERVICES PROPOSÉS
        DANS LES DOUZE (12) MOIS PRÉCÉDANT LA PLAINTE. </p>
    <h6>DROITS RÉSERVÉS </h6>
    <p>Nous nous réservons tous les droits qui ne vous sont pas expressément accordés aux-présentes. Pour éviter
        toute ambigüité, vous êtes autorisés à accéder à la plate-forme STUDIOMAP via laquelle vous pouvez
        commander des services et accéder aux produits livrables, ou proposer vos services en tant que photographe, et
        nous ne vous accordons aucune licence pour utiliser nos logiciels, marques commerciales, noms commerciaux ou
        logos. </p>
    <h6>DIVERS</h6>
    <h6>Loi applicable</h6>
    <p>Les conditions de STUDIOMAP sont régies par le droit français. Vous acceptez la compétence non exclusive du
    </p>
    <p>tribunal de commerce de Paris à Paris, en France, pour toute action ou procédure découlant ou liée aux
        Conditions STUDIOMAP. Tout litige découlant des Conditions STUDIOMAP (ou en lien avec celles-ci) et/ou en
        relation avec le Site Web STUDIOMAP et/ou la Plateforme STUDIOMAP (y compris les politiques mentionnées dans
        les présentes) est régi et interprété conformément au droit français, sans qu’il soit donné effet aux
        dispositions en matière de conflits de lois. Les conditions STUDIOMAP excluent spécifiquement l’application de
        la Convention des Nations Unies sur les contrats de vente internationale de marchandises. </p>
    <h6>Relation de contractant indépendant </h6>
    <p>Les relations entre les parties en vertu des présentes conditions STUDIOMAP sont strictement celles de
        contractants indépendants, et aucune disposition aux présentes ne constitue ou ne s’interprète en aucune
        manière comme une preuve de l’intention de créer une association, un partenariat, une coentreprise ou toute
        autre relation. </p>
    <h6>Communications de STUDIOMAP </h6>
    <p>STUDIOMAP peut vous contacter à propos de l’administration de votre compte d’utilisateur et/ou de modifications
        importantes apportées aux présentes Conditions via l’adresse e- mail fournie lors de votre inscription. </p>
    <h6>Modifications </h6>
    <p>STUDIOMAP peut modifier ou mettre à jour les Conditions de STUDIOMAP à tout moment, à son entière
        discrétion, et ces conditions modifiées de STUDIOMAP entreront en vigueur une fois publiées sur STUDIOMAP.fr.
        Si vous continuez à utiliser le site Web STUDIOMAP et/ou la plate- forme STUDIOMAP une fois ces modifications
        apportées, vous acceptez implicitement ces modifications. Si les modifications que nous apportons sont
        importantes, nous vous en </p>
    <p>informerons à l’aide de moyens raisonnables (par exemple, en publiant un avis relatif à ces modifications sur
        STUDIOMAP.fr ou en vous adressant un avis sur votre adresse e-mail). Si vous n’acceptez pas les conditions
        modifiées de STUDIOMAP, vous pourrez interrompre votre utilisation de STUDIOMAP.fr et de la plate-forme
        STUDIOMAP. </p>
    <h6>Renonciation </h6>
    <p>Sauf disposition expresse des présentes, une partie ne peut renoncer à ses droits en vertu des Conditions
        STUDIOMAP qu’en signant un document écrit entre les deux parties. Tout manquement ou retard à faire respecter
        une disposition des Conditions générales de STUDIOMAP ne constituera pas une renonciation à celles-ci ni à
        aucune autre disposition des présentes. </p>
    <h6>Titres des sections </h6>
    <p>Les titres des paragraphes des Conditions STUDIOMAP sont uniquement fournis à des fins de commodité et n’auront
        aucun effet significatif. </p>
    <h6>Applicabilité </h6>
    <p>Les droits que vous accordez à STUDIOMAP en vertu des Conditions de STUDIOMAP resteront en vigueur même si vous
        cessez d’utiliser le(s) site(s) Web de STUDIOMAP. </p>
    <h6>Applicabilité </h6>
    <p>Les droits que vous accordez à STUDIOMAP en vertu des Conditions de STUDIOMAP resteront en vigueur même si vous
        cessez d’utiliser le(s) site(s) Web de STUDIOMAP. </p>
    <h6>Cession </h6>
    <p>Vous ne pouvez pas céder ou déléguer les Conditions STUDIOMAP ou vos droits ou obligations en vertu des
        présentes. Toute cession non autorisée sera nulle et non avenue. Vous reconnaissez et acceptez que STUDIOMAP
        puisse céder ou sous-traiter ses droits ou obligations en vertu des Conditions générales de STUDIOMAP. </p>
    <h6>Intégralité de l'accord </h6>
    <p>Les Conditions STUDIOMAP contiennent l’intégralité du contrat passé entre Vous et STUDIOMAP en ce qui concerne
        l’objet des présentes et remplacent toutes les communications et propositions précédentes ou actuelles,
        qu’elles soient sous format électronique, orales ou écrites, entre vous et nous en ce qui concerne
        STUDIOMAP.fr et/ou la plate-forme STUDIOMAP. En cas de conflit ou d’incohérence entre les conditions des
        Conditions de STUDIOMAP et les conditions de tiers relatives aux services intégrés au site Web STUDIOMAP et/ou
        à la Plateforme STUDIOMAP, les Conditions de STUDIOMAP s’appliqueront à l’égard de Votre utilisation du site
        Web STUDIOMAP et/ou de la plate-forme STUDIOMAP. En cas de conflit entre les Conditions générales de STUDIOMAP
        et la politique de confidentialité, la politique de confidentialité s’appliquera, uniquement dans la mesure de
        ce conflit. </p>

</div>