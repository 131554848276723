import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { MenuSwitchService } from 'src/app/services/menu-swicth/menu-switch.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-dark-connected-nav',
  templateUrl: './dark-connected-nav.component.html',
  styleUrls: ['./dark-connected-nav.component.css']
})
export class GerantNavComponent implements OnInit {
  @Input() image: any;
  @Input() switch_button_text: any;
  @Input() showProfileMenu: boolean;
  user: any;
  currentType = localStorage.getItem('type');
  selectedType = localStorage.getItem('current_type');
  constructor(private menuService: MenuSwitchService, private tokenStorageService: TokenStorageService) { }

  menu_switcher() {
    this.menuService.switch();
  }

  comming_soon() {
    var myModal = document.getElementById('openCSModal')
    myModal.click();
  }

  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    if (this.isIFrame()) {
      parent.postMessage(JSON.stringify({ userData : this.user }), environment.webflowURL);
    }

  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }
}


