<form [formGroup]="Form" (ngSubmit)="onSubmit()" class="p-3 mb-5">
    <div class="card mx-auto container p-5 my-5 bg-transparent" style="max-width: 400px;">
        <img src="assets/icons/envelope-red.svg" class="text-danger" height="25" alt="">
        <h5 class="fw-bold mb-4">Contactez-nous !</h5>
        <input type="text" class="form-control border" [ngClass]="{'border-danger':f.full_name.errors && submitted}"
            formControlName="full_name" placeholder="Nom complet">
        <input type="text" class="form-control border" [ngClass]="{'border-danger':f.email.errors && submitted}"
            formControlName="email" placeholder="email">
        <input type="text" class="form-control border" [ngClass]="{'border-danger':f.phone.errors && submitted}"
            formControlName="phone" placeholder="Téléphone">
        <input type="text" class="form-control border" [ngClass]="{'border-danger':f.object.errors && submitted}"
            formControlName="object" placeholder="Object">
        <textarea formControlName="message" rows="5" [ngClass]="{'border-danger':f.message.errors && submitted}"
            placeholder="Message" class="form-control border"></textarea>
        <button class="btn btn-danger text-uppercase" type="submit">envoyer</button>
    </div>
</form>