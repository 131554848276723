import { Component, EventEmitter, OnInit, Output, ElementRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.css']
})
export class ConfirmAccountComponent implements OnInit {
  @ViewChild('myElement', { static: false }) myElement: ElementRef;
  @Input() user!: any;
  preparedLink: any;
  error: string = "";
  // emit account status to parent (booking conatiner) has account or not to manage redirections 
  @Output() emitAccountStatus = new EventEmitter<any>();
  loading:boolean = false;

  constructor(private stripeService: StripeService, private authservice: AuthService, private profileService: ProfileService, private route: ActivatedRoute, private router: Router, private authService: AuthService) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.preparedLink = { ...params };
    })
  }

  hasAccount(value: any) {
    this.preparedLink['step'] = 'booking';
    this.router.navigate(['/connexion'], { queryParams: this.preparedLink });    
  }

  hasntAccount(value: any) {
    this.error = "";
    this.loading = true;
    

    this.profileService.getByEmail({ email: this.preparedLink.email, phone: this.preparedLink.phone, check: true }).subscribe(available => {
      if (available.foundUser) {
        this.loading = false;
        this.error = "Vous avez déjà un compte avec cette email ou numéro téléphone!";
        return
      }
      if (available.foundPhone) {
        this.loading = false;
        this.error = "Ce numéro de téléphone est déjà lié à un compte";
        return
      }

      if(this.preparedLink.type == "classic"){
        this.router.navigate([`/artiste/fiche-studio/${this.preparedLink.studio}`], { queryParams: this.preparedLink });
      }else{
        this.authservice.register_with_phone(this.preparedLink.phone, this.preparedLink.email, '', 'phone').subscribe(res => {
          this.profileService.create_profile(res.user.id, this.preparedLink.email, this.preparedLink.complete_name, this.preparedLink.phone, "", "", "", "", "false").subscribe(profile => {
            this.stripeService.create_customer(res.user.id, this.preparedLink.email, this.preparedLink.complete_name).subscribe(token => {
              this.authService.send_phone_confirmation(this.preparedLink.phone).subscribe(res => {
                this.emitAccountStatus.emit(value);
              }, err => {
                this.loading = false;
                this.error = "numéro de téléphone invalid";
              })
            })
          })
        })
      }
      
    }, err => {
      this.error = "une érreur est survenu";
      this.loading = false;
    })
  }

  back() {
    this.emitAccountStatus.emit('booking');
  }


  ngAfterViewInit(): void {
    this.setIFrameHeight();
  }

  private waitForViewChild(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.myElement) {
        resolve();
      } else {
        setTimeout(() => this.waitForViewChild().then(resolve), 100);
      }
    });
  }

  setIFrameHeight() {
    if (this.isIFrame()) {
      this.waitForViewChild().then(() => {
        const elementHeight = this.myElement.nativeElement.offsetHeight + 290;
        console.log(elementHeight);
        parent.postMessage(JSON.stringify({ iframeHeight: elementHeight, userData: this.user, bookingStep: 'checkAccount' }), environment.webflowURL);
      });
    }
  }

  objectToParams(obj: Record<string, any>): string {
    const params = new URLSearchParams();

    // Iterate over each key in the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        // If the value is an array, add multiple params with the same key
        if (Array.isArray(value)) {
          value.forEach(item => {
            params.append(key, item);
          });
        } else {
          params.append(key, value.toString());
        }
      }
    }

    return params.toString();
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

}
