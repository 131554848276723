import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  link_validator: Validators = Validators.pattern('http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+');

  requireControl(form: FormGroup, controle: string) {
    form.controls[controle].setValidators(Validators.required);
    form.controls[controle].updateValueAndValidity();
  }

  emailControl(form: FormGroup, controle: string) {
    form.controls[controle].setValidators([Validators.email,Validators.required]);
    form.controls[controle].updateValueAndValidity();
  }

  requireControlPercent(form: FormGroup, controle: string) {
    form.controls[controle].setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
    form.controls[controle].updateValueAndValidity();
  }

  facultatifControl(form: FormGroup, controle: string) {
    form.controls[controle].clearValidators();
    form.controls[controle].updateValueAndValidity();
  }

  emptyControl(form: FormGroup, controle: string) {
    form.controls[controle].setValue('');
  }

  setControlValue(form: FormGroup, controle: string, value: any) {
    form.controls[controle].setValue(value);
  }

  isChecked(array: any, value: string) {
    let exist = array.includes(value);
    return exist;
  }
}
