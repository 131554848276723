import { Reservation } from "./reservation.model";
import { Type } from "./type.model";
import { User } from "./user.model";

export class Studio {
    id: string;
    tags: any;
    services: any;
    is_deleted: false;
    isSponsored: true;
    commission: 0;
    commission_client: 0;
    is_draft: false;
    price_variable: false;
    minhours_day: 2;
    minhours_semiday: 0;
    medias: any;
    web_site: string;
    description: string;
    siren: string;
    email: string;
    phone: string;
    route: string;
    postal_code: string;
    lng: string;
    lat: string;
    country: string;
    city: string;
    adresse: string;
    name: string;
    location: any;
    user: User;
    ical: string;
    webflow_id: string;
    reservations: Reservation;
    ouvertures: any;
    salles: any;
    types: Array<Type> = [];
    links: any;
    avis: any;
    promotions: any;

    constructor(
        data: Partial<Studio>
    ) {
        Object.assign(this, data);
        let types: Array<Type> = [];
        if (data.types) {
            data.types?.forEach(typeItems => {
                types.push(new Type(typeItems));
            });
            this.types = types;
        }
        this.user = (data.user) ? new User(data.user) : null;
    }

    getTypeByName(name: string) {
        let item: Type = this.types.find(i => i.name === name);
        return item
    }

}
