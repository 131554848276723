import { Component, OnInit } from '@angular/core';
import { EmitterService } from 'src/app/services/emitter/emitter';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.css']
})
export class ConfirmationPageComponent implements OnInit {

  constructor(private emitterService: EmitterService) { }

  ngOnInit(): void {
    this.emitterService.mode.emit("light mode")
  }

}
