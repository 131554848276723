import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmitterService {
  @Output() mode: EventEmitter<any> = new EventEmitter();
  @Output() typeMembre: EventEmitter<any> = new EventEmitter();
  @Output() getLoggedUser: EventEmitter<any> = new EventEmitter();
  @Output() encapsulated_meta_infos: EventEmitter<any> = new EventEmitter();
  @Output() meta_title: EventEmitter<any> = new EventEmitter();
  background:any;
  @Output() menu_has_calendar_prestation: EventEmitter<any> = new EventEmitter();
  @Output() menu_has_salary_bilan: EventEmitter<any> = new EventEmitter();
  constructor() { }
}
