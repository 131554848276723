import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dark-default-nav',
  templateUrl: './dark-default-nav.component.html',
  styleUrls: ['./dark-default-nav.component.css']
})
export class DarkDefaultNavComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

  }

  gotToBlog() {
    if(typeof window !== 'undefined')
      window.location.href = environment.url + "blog/";
  }

  path() {
    return (this.router.url.indexOf('result-map') != -1 || this.router.url.indexOf('fiche-studio') != -1);
  }

}
