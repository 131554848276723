<div #myElement>
  <div *ngIf="reservationEnAttente && !loading" class="text-danger fs-12 pt-2">
    <h3 class="m-0">{{error}}</h3>
    <button type="button" class="w-100 btn rounded btn-lg btn-danger mt-4" (click)="goToReservation()">Détails
      réservation</button>
  </div>
  <div *ngIf="!reservationEnAttente">
    <i (click)="back()" class="fs-5 fa fa-chevron-left pointer float-start me-2"></i>
    <h3>Code de validation par sms</h3>
    <div class="form-group px-1">
      <input mask="(00) 00000000||000000000" [(ngModel)]="varificationCode" placeholder="Code reçu par sms" type="text"
        class="form-control m-0">
    </div>
    <button type="button" class="btn btn-danger w-100 my-2 text-uppercase" (click)="valider()" *ngIf="!loading"><i
        class="fa fa-check me-2 pointer"></i> Valider</button>
    <span class="pointer text-primary" (click)="sendPhoneConfirmation()" *ngIf="!loading">je n'ai pas reçu de
      validation</span><br>
    <span class="text-success mt-1" *ngIf="send_code"><i class="fa fa-check me-2 pointer"></i> le code a été envoyé avec
      succès . </span>
    <button class="w-100 btn rounded btn-lg btn-secondary btn-outline-dark" *ngIf="loading"><i
        class="fa fa-spinner fa-spin"></i>
      Chargement...</button>
    <div *ngIf="error!=''" class="text-danger fs-12 pt-2">
      {{error}}
    </div>
  </div>
</div>