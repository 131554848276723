import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmAccountComponent } from './confirm-account.component';


@NgModule({
  declarations: [ConfirmAccountComponent],
  exports: [ConfirmAccountComponent],
  imports: [
    CommonModule
  ]
})
export class ConfirmAccountModule { }
