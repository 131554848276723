import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShimmerComponent } from './shimmer.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';



@NgModule({
  declarations: [ShimmerComponent],
  exports:[ShimmerComponent],
  imports: [
    CommonModule,
    NgxShimmerLoadingModule
  ]
})
export class ShimmerModule { }
