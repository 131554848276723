<div class="container mt-5 p-5">
    <h2 class="mb-5">Mentions légales STUDIOMAP</h2>



    <div class="text-start mb-5">
        <p><b>•</b> Tout UTILISATEUR, EXPLOITANT DE STUDIO, PRESTATAIRE FREELANCE est réputé avoir pris connaissance et
            lu attentivement les présentes modalités d’utilisation de la plateforme STUDIOMAP avant de se connecter et
            d’utiliser les services proposés conformément aux règles d’ordre public sur le territoire Français.</p>
        <p><b>•</b> La plateforme STUDIOMAP se conforme strictement à l’article n°6 de la loi n°2004-575 du 21 juin 2004
            pour la confiance dans l’économie numérique.</p>
    </div>

    <h4 class="mb-5 text-decoration-underline">POURVOYEUR DE LA PLATEFORME STUDIOMAP</h4>

    <div class="text-start mb-5">
        <p>STUDIOMAP, S.A.S au capital de 44.000€ </p>
        <p>Siège social : 2 rue Antoine Etex, Créteil 94000</p>
        <p>Immatriculée au RCS 894479443 TVA N°FR 77894479443</p>
    </div>



    <h4 class="mb-5 text-decoration-underline"> Responsables Éditoriaux </h4>

    <div class="text-start mb-5">

        <p>Le président de STUDIOMAP,</p>
        <p>Jeremy ATTIA Élisant domicile au 2nd siège social : 2 Avenue du Général Balfourier, Paris 75116</p>
        <p>Le Directeur juridique de STUDIOMAP,</p>
        <p>Timothée SON élisant domicile au 49, rue de la goutte d’or 75018 Paris.</p>
    </div>

    <h4 class="mb-5 text-decoration-underline">
        Responsables Editoriaux
    </h4>


    <div class="text-start mb-5">
        <p>OVH SAS au capital de 10 069 020 €</p>
        <p>RCS Roubaix – Tourcoing 424 761 419 00045</p>
        <p>Siège social : 2 rue Kellermann - 59100 Roubaix - France.</p>
        <p>OVH SAS est une filiale de la société́ OVH Groupe SAS, société immatriculée au RCS de Lille sous le
            numéro 537</p>
        <p>407 926 sise 2, rue Kellermann, 59100 Roubaix.</p>
    </div>


    <h4 class="mb-5 text-decoration-underline">
        Propriété intelectuelle
    </h4>

    <div class="text-start mb-5">
        <p>
            Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle,
            de
            différents éléments, distinctif ou non présent sur la plateforme STUDIOMAP est réputée strictement
            interdite sans
            l'accord exprès par écrit de STUDIOMAP. Cette représentation ou reproduction, par quelque procédé que
            ce soit,
            constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété
            intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la
            responsabilité
            civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter
            une action
            en justice à votre encontre.
        </p>
        <p>
            Tout le contenu de la Plateforme STUDIOMAP incluant, de façon non limitative, les graphismes, images,
            textes,
            vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive
            de
            STUDIOMAP à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou
            auteurs.
        </p>
    </div>




    <h4 class="mb-5 text-decoration-underline">
        Responsabilités
    </h4>

    <div class="text-start mb-5">
        <p>
            Les informations publiées sur ce site peuvent être modifiées ou corrigées à tout moment, sans préavis.
            L'éditeur
            décline toute responsabilité notamment pour toute imprécision, inexactitude ou omission portant sur des
            informations
            disponibles sur le site.
        </p>
    </div>


    <h4 class="mb-5 text-decoration-underline">
        Informatique est libertés
    </h4>

    <div class="text-start mb-5">
        <p>
            En application de la loi n°78-17 du 6 janvier 1978 modifiée, relative à l'informatique, aux fichiers et aux
            libertés, le site web a fait l'objet d'une déclaration auprès de la Commission Nationale de l'Informatique
            et des
            libertés (cnil.fr).
            L'utilisateur est notamment informé, conformément à l'article 32 de la loi Informatique et libertés du 6
            janvier
            1978 modifiée, que STUDIOMAP, en tant que responsable du traitement, met en œuvre un traitement de données à
            caractère personnel ayant pour principales finalités la gestion de l'inscription de l'utilisateur au site
            communautaire, l'envoi de newsletters.
            Certaines des données collectées pourront être communiquées à ses partenaires à des fins de prospection ou
            des fins
            commerciales.
            Les champs identifiés par un astérisque sont obligatoires. En l'absence de réponse ou si les informations
            fournies
            sont erronées, STUDIOMAP ne pourra pas traiter votre demande.
        </p>
        <p>
            L'utilisateur est informé qu'il dispose d'un droit d'accès, d'interrogation, et de rectification qui lui
            permet, le
            cas échéant, de faire rectifier, compléter, mettre à jour, verrouiller ou effacer les données personnelles
            le
            concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont la collecte, l'utilisation, la
            communication ou la conservation est interdite.
            L'utilisateur dispose également d'un droit d'opposition au traitement de ses données pour des motifs
            légitimes ainsi
            qu'un droit d'opposition à ce que ces données soient utilisées à des fins de prospection commerciale.
            L'ensemble de ces droits s'exerce auprès de STUDIOMAP, par courrier postal accompagné d'une copie d'un titre
            d'identité comportant une signature à l'adresse suivante :
            Studiomap.app@gmail.com
            STUDIOMAP- 2 rue Antoine ETEX 94000 Créteil
        </p>
    </div>

    <h4 class="mb-5 text-decoration-underline">
        Litiges
    </h4>

    <div class="text-start mb-5">
        <p>
            Sans préjudice de l’application des Conditions Générales d’Utilisation et de Services, les litiges
            survenant à
            l’occasion de l’utilisation de la Plateforme STUDIOMAP et impliquant STUDIOMAP sont régis par les lois
            françaises
            et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci
            seront
            de la compétence exclusive des juridictions françaises compétentes.
        </p>
    </div>
</div>