<div class="col-12 text-start" *ngIf="studio">
    <div class="bg-white container-card mb-4 rounded-3 shadow-0">
        <div *ngIf="type">
            <span class="text-gray fs-12">Prix de la session</span><br>
            <span class="text-danger pb-2 m-0" [ngClass]="{'text-dark text-decoration-line-through h6':type.tarif_promo,'text-danger h2 fs-24':!type.tarif_promo}">{{(type)?type.tarif:null}}€ / heure</span>
            <h2 class="text-danger pb-2 fs-24 m-0" *ngIf="type && type.tarif_promo">{{type.tarif_promo}} € / heure</h2>
            <hr>
        </div>
        <div>
            <app-booking-form *ngIf="targetedStep=='booking'" [studio]="studio" [type]="type"
                (emitReservation)="getEmittedReservation($event)" [user]="user"></app-booking-form>
            <app-confirm-account *ngIf="targetedStep=='checkAccount'"
                (emitAccountStatus)="getEmittedAccountStatus($event)" [user]="user"></app-confirm-account>
            <app-create-account-sms *ngIf="targetedStep=='validSms'"
                (emitCodeSmsResponse)="getEmittedCodeSmsResponse($event)" [user]="user" [type]="type"></app-create-account-sms>
        </div>
    </div>
</div>