import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parrainer-ami',
  templateUrl: './parrainer-ami.component.html',
  styleUrls: ['./parrainer-ami.component.css']
})
export class ParrainerAmiComponent implements OnInit {
  userId: string;
  url: string;
  description: string = 'Grâce à une interface simple et puissante, artistes, gérants de studio, producteurs, photographes, et vidéastes peuvent se retrouver facilement et commencer à créer ensemble.\n\nCliquez sur le lien suivant pour créer votre compte : ';
  constructor(
    private emitterService: EmitterService, public title: Title, private location: Location, private token: TokenStorageService) {

  }

  ngOnInit() {
    this.userId = this.token.getUser().id;
    this.url = environment.url + "inscription/?code=" + this.token.getUser().id;
    this.emitterService.mode.emit("light mode");
    // meta & title
    this.title.setTitle("Studiomap : parrainer un ami");
  }


  back() {
    this.location.back();
  }

}
