import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectedGuard implements CanActivate {
  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router,
  ) { }
  
  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<any> {
    if (!this.tokenStorage.getToken() || !this.tokenStorage.getUser()) {
      return true
    }
    switch (this.tokenStorage.getUser().type) {
      default:
        this.router.navigate(['/artiste/reservations']);
        break;
        case 'gerant':
          if(localStorage.getItem('current_type')=='gerant'){
            this.router.navigate(['/gerant/tableau-de-bord']);
          }else{
            this.router.navigate(['/artiste/reservations']);
        }
        break;
    
    }
  }
  
}
