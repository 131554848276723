import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  endPoint = 'payments/';
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  create_payment(payment_id: string, charge_id: string, reservation: string, facture: string, type: string, tokenJWT?: string): Observable<any> {
    return this.http.post(environment.api + this.endPoint, {
      payment_id,
      charge_id,
      reservation,
      facture,
      type
    },
      {
        headers: { Authorization: 'Bearer ' + ((tokenJWT) ? tokenJWT : this.tokenStorageService.getToken()), "content-type": "application/json" }
      }
    );
  }

  update(payment_id: string, data: any): Observable<any> {
    return this.http.put(environment.api + this.endPoint + payment_id, data,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }

  createPaypalPayment(studio: string, total: any, is_iframe: boolean, reservationId: string): Observable<any> {
    console.log("=> call reservation from services : " + reservationId);

    return this.http.post(environment.api + this.endPoint + "createPaypalPayment", JSON.stringify({ "reservationId": reservationId, "is_iframe": is_iframe, "device": "web", "title": "studio " + studio, "description": "Réservation studio " + studio, "pay": total }),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  createPaypalPaymentOffline(obj: any): Observable<any> {

    let is_connected = (this.tokenStorageService.getToken() != null) ? true : false;

    let header = (is_connected) ? {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    } : {};

    return this.http.post(environment.api + this.endPoint + "create-paypal-payment-offline", obj,
      header
    );
  }

  executePaypalPaymentOffline(obj: any): Observable<any> {

    let is_connected = (this.tokenStorageService.getToken() != null) ? true : false;

    let header = (is_connected) ? {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    } : {};

    return this.http.post(environment.api + this.endPoint + "execute-paypal-payment-offline", obj,
      header
    );
  }

  executePaypalPayment(payment_id: string, payer_id: string, total: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint + "executePaypalPayment", JSON.stringify({ "paymentId": payment_id, "payerID": payer_id, "pay": total }),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  getOrderIdByPaypalPayment(data: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint + "getPaypalPayment", JSON.stringify(data),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  capturePaypalPayment(data: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint + "capturePaypalPayment", JSON.stringify(data),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  refundPaypalPayment(data: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint + "refundPaypalPayment", JSON.stringify(data),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }


}
