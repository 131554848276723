import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const TOKEN_KEY = 'auth-token';
const Abonnent_To_pay = 'abonnementToPay';
const USER_KEY = 'auth-user';
const current_type = 'current_type';
const choosen_type = 'choosen-type';
const TYPE = 'type';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor(private router: Router, private angularFireAuth: AngularFireAuth) { }

  signOut(): void {
    localStorage.removeItem(Abonnent_To_pay);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(current_type);
    localStorage.removeItem(TYPE);
    localStorage.removeItem(choosen_type);
    this.angularFireAuth.signOut();
    if (parent && window && parent != window) {
      parent.postMessage(JSON.stringify({ userData: {} }), environment.webflowURL);
      parent.location.href = environment.webflowURL + "/app";
    }
    this.router.navigate(['/connexion']);
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
}
