export class Type {
    prestations: Array<string>;
    name: string;
    tarif: number;
    studio: string;
    id: string

    constructor(data: Partial<Type>) {
        Object.assign(this, data);
    }
}