import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';
import { Studio } from 'src/app/classes/studio.model';
import { map } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class StudioService {
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create_studio(form: any, medias: Array<File>): Observable<any> {
    form.phone = "0" + String(form.phone * 1);
    let data = form
    const formData = new FormData();
    medias.forEach(element => {
      formData.append("files.medias", element);
    });

    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + 'studios', formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  update_studio(studioId: string, studioValues: any, medias: Array<File>): Observable<any> {
    studioValues.phone = "0" + String(studioValues.phone * 1);
    let data = studioValues;
    const formData = new FormData();
    medias.forEach(element => {
      formData.append("files.medias", element);
    });

    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + 'studios/' + studioId, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  is_deleted(studioId: string, etat: boolean): Observable<any> {
    let data = {
      is_deleted: etat,
    }
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + 'studios/' + studioId, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  get_studios_by_user_id(): Observable<any> {
    return this.http.get(environment.api + "studios?user=" + this.tokenStorageService.getUser().id + "&_sort=createdAt:DESC");
  }

  get_studios_by_user_id_promise() {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + "studios?user=" + this.tokenStorageService.getUser().id + "&_sort=createdAt:DESC").toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
    return promise
  }


  get_studio_by_id(studioId: string): Observable<any> {
    return this.http.get(environment.api + "studios/" + studioId);
  }

  get_studio_by_filter(type: string, lat: string, lng: string, min_distance: number, max_distance: number, min_tarif: number, max_tarif: number, prestation: string, name: string, startDate: any, endDate: any): Observable<any> {
    if (min_distance) {
      min_distance *= 1000;
    }
    if (max_distance) {
      max_distance *= 1000;
    }
    let url = environment.api + `studios/?min_distance=${min_distance.toString()}&max_distance=${max_distance.toString()}&min_tarif=${min_tarif.toString()}&max_tarif=${max_tarif.toString()}&prestations=${prestation.replace("&", "%26")}&is_draft=false`;
    (type) ? url += `&type=${type.toUpperCase()}` : '';
    (lat && lng) ? url += `&lat=${lat}&lng=${lng}` : '';
    (endDate) ? url += `&start_date=${startDate}&end_date=${endDate}` : url;
    if (name != undefined && name) {
      url = environment.api + `studios/?_q=${encodeURIComponent(name)}&is_draft=false`;
      (type) ? url += `&types.name=${type.toUpperCase()}` : '';
    }

    return this.http.get(url);
  }

  get_all_studios(): Observable<any> {
    return this.http.get(environment.api + "studios/");
  }

  delete_studio_by_id(studioId: string): Observable<any> {
    return this.http.delete(environment.api + "studios/" + studioId,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }

  update_Ical(studioId: string, url: string): Observable<any> {
    return this.http.put(environment.api + 'studios/' + studioId, { ical: url }, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  get_custom(querys: string): Observable<any> {
    return this.http.get(environment.api + "studios/custom" + querys);
  }

  // improve restful api by mapping to predefined models

  get_studio_by_id_model(studioId: string): Observable<Studio> {
    return this.http.get<Studio>(environment.api + "studios/" + studioId).pipe(map(studio => new Studio(studio)));
  }

}
