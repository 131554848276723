<nav class="navbar navbar-light navbar-expand-custom">
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <img src="./assets/icons/logo-black-red.svg" width="30" alt="logo">
      <span class="ms-3 text-uppercase" style="letter-spacing: 10px;font-weight: 200;font-size: 13px;">studiomap</span>
    </a>
    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fas fa-bars fs-3"></i>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
      <ul class="d-flex justify-content-between navbar-nav">
        <li class="nav-item dropdown position-static d-none d-xxl-block">
          <a class="nav-link text-capitalyze dropdown-toggle" routerLink="/artiste/reserver-studio"
            routerLinkActive="active">Réserver un studio</a>
          <ul class="dropdown-menu justify-content-center w-100" aria-labelledby="navbarDropdown">
            <div class="topnav"></div>
            <li><a class="dropdown-item" routerLink="/artiste/reserver-studio" [queryParams]="{type: 'ENREGISTREMENT'}"
                routerLinkActive="active-sub">Studio d’enregistrement</a></li>
            <li><a class="dropdown-item" routerLink="/artiste/reserver-studio" [queryParams]="{type: 'PHOTOGRAPHIE'}"
                routerLinkActive="active-sub">Studio photo</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown position-static d-block d-xxl-none">
          <a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            class="nav-link text-capitalyze dropdown-toggle" routerLink="/artiste/reserver-studio"
            [queryParams]="{type: 'ENREGISTREMENT'}" routerLinkActive="active-sub">Studio d’enregistrement</a>
        </li>
        <li class="nav-item dropdown position-static d-block d-xxl-none">
          <a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            class="nav-link text-capitalyze dropdown-toggle" routerLink="/artiste/reserver-studio"
            [queryParams]="{type: 'PHOTOGRAPHIE'}" routerLinkActive="active-sub">Studio photo</a>
        </li>
        <li class="nav-item dropdown position-static">
          <a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            class="nav-link text-capitalyze dropdown-toggle" routerLink="/artiste/plus/pass-culture"
            routerLinkActive="active-sub">PASS CULTURE</a>
        </li>
        <li class="nav-item dropdown position-static">
          <a class="nav-link text-capitalyze dropdown-toggle" href="https://blog.studiomap.fr/"
            routerLinkActive="active" target="_blank">ARTICLES</a>
        </li>
        <li class="d-none d-xxl-block">&nbsp;</li>
        <li class="d-none d-xxl-block">&nbsp;</li>
        <li class="d-none d-xxl-block">&nbsp;</li>
        <li>
          <!-- <a *ngIf="!path()" class="btn btn-outline-dark rounded-pill text-uppercase btn-sm rounded" data-bs-toggle="modal" data-bs-target="#devenir-partenaire">devenir partenaire</a> -->
          <button routerLink="/inscription"
            class="btn btn-outline-dark rounded-pill text-uppercase btn-sm rounded mt-nav">rejoignez-nous</button>
        </li>
        <li>
          <!-- <a *ngIf="!path()" class="btn btn-danger rounded-pill text-uppercase btn-sm rounded mt-nav" href="https://calendly.com/studiomap/20min" target="_blank">Prendre contact</a> -->
          <a class="btn btn-danger rounded-pill text-uppercase btn-sm rounded mt-nav"
            routerLink="/connexion">Connexion</a>
        </li>
        <li>
          <button routerlink="/connexion"
            class="border-0 btn btn-sm mt-nav ng-star-inserted pass-culture-gradient rounded-pill text-white"
            tabindex="0" data-bs-toggle="modal" data-bs-target="#passCultureModal"><img
              src="assets/menu-icons/pass-culture.png" alt="" style="
            height: 25px;
        "></button>
        </li>
      </ul>
    </div>
  </div>
</nav>