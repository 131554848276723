    <div class="card mx-auto container p-5 my-5 bg-transparent" style="max-width: 400px;">
        <h5 class="fw-bold mb-4">INSCRIPTION</h5>

        <button class="btn border btn-white py-3" type="button">Numéro téléphone</button>
        <button class="btn border btn-white mt-3 py-3" routerLink="/choose-user-type">email et mot de passe</button>
        <button class="btn border btn-white mt-3 pe-4" type="button" (click)="signInWithFB()">
            <img class="me-2" height="30" width="30" src="assets/icons/facebook-btn.svg" alt="facebook"> Facebook
        </button>
        <button class="btn border btn-white mt-3 pe-5" (click)="signInWithGoogle()" alt="google login" type="button">
            <img class="me-2" height="25" width="25" src="assets/icons/google-btn.svg" alt="google"> Google
        </button>

    </div>