import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { colors } from 'src/app/classes/colors';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';
import { DateFormatterService } from '../date-formatter/date-formatter.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {
  endPoint = "reservations/";
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService, private dateService: DateFormatterService) { }

  create(data: any) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + this.endPoint, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  create_payment_for_reservation(data: any) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + "payments", formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  check_reservation_for_studio(id: string, studio: string, token: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + this.endPoint + "check", {
        "reservation" : id,
        "studio" : studio,
        "token" : token
      }).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      })
    })
    return promise;
  }

  check_reservation_for_payment(id: string, payment_token: string) {

    let is_connected = (this.tokenStorageService.getToken() != null) ? true : false;

    let header = (is_connected) ? {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    } : {};
    

    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + this.endPoint + "payment-check", {
        "reservation" : id,
        "payment_token" : payment_token
      },header).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      })
    })
    return promise;
  }

  update_after_execute_paypal(obj: any) {

    let is_connected = (this.tokenStorageService.getToken() != null) ? true : false;

    let header = (is_connected) ? {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    } : {};
    

    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + this.endPoint + "update-after-paypal-execute", obj,header).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      })
    })
    return promise;
  }

  confirm_reservation_for_studio(id: string, studio: string, token: string,confirmation : boolean) {
    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + this.endPoint + "confirmation", {
        "reservation" : id,
        "studio" : studio,
        "token" : token,
        "confirmation" : confirmation
      }).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      })
    })
    return promise;
  }

  get_reservation_without_login(reservation_id: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + reservation_id, {}).subscribe((res: any) => {
        if (res.user) {
          resolve(res);
        } else {
          resolve(null);
        }
      }, err => {
        reject(err);
      })
    })
    return promise;
  }

  get_reservation(reservation_id: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + "?id=" + reservation_id,
        {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
        }).subscribe((res: any) => {
          if (res.length != 0 && res[0].user && this.tokenStorageService.getUser().id == res[0].user.id) {
            resolve(res[0]);
          } else {
            resolve(null);
          }
        }, err => {
          reject(err)
        })
    })
    return promise
  }

  get_reservation_gerant(reservation_id: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + "?id=" + reservation_id,
        {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
        }).subscribe((res: any) => {
          console.log(this.tokenStorageService.getUser().id);
          if (res.length != 0 && res[0].user && this.tokenStorageService.getUser().id == res[0].studio.user) {
            resolve(res[0]);
          } else {
            resolve(null);
          }
        }, err => {
          reject(err)
        })
    })
    return promise
  }

  get_reservations_by_user_id(filters?: string): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "?user=" + this.tokenStorageService.getUser().id + "&_sort=createdAt:DESC" + ((filters) ? "&" + filters : ""),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }
  get_upcomming_reservations(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "?studio.user=" + this.tokenStorageService.getUser().id + "&_sort=createdAt:DESC&etat=false&is_deleted=false&payment.type_nin=Pré-Réservation&payment.type_nin=waiting&payment.type_nin=cancel",
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }

  salary_bilan(userId: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + "?studio.user=" + userId + "&etat=true&_sort=createdAt:DESC&membre.id_ne=" + userId,
        {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
        }).subscribe(res => {
          resolve(res);
        }, err => {
          reject(err)
        })
    })
    return promise
  }

  prestations_assigned_to_gerant(userId: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + "?membre=" + userId + "&etat=true&_sort=createdAt:DESC",
        {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
        }).subscribe(res => {
          resolve(res);
        }, err => {
          reject(err)
        })
    })
    return promise
  }

  get_reservation_by_range_date(currentDay: any, lastDay: any): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "?studio.user=" + this.tokenStorageService.getUser().id + "&_sort=createdAt:ASC&etat=true&start_date_gt=" + currentDay,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }

  /**
   * 
   * @param studio object
   * @param start_date 2023-06-18 00:00
   * @param end_date_date 2023-06-18 03:00
   * @returns 
   */
  create_reservation(reservation: any, user: string, tokenJWT?: string): Observable<any> {
    // format date to send : 2023-06-18 00:00
    var start_date = reservation.start_date;
    var end_date = reservation.end_date;
    var user = user;
    var studio = reservation.studio.id;
    var total = reservation.total;
    var pay = reservation.pay;
    var prestations = reservation.prestations;
    var type_selected = reservation.type;
    var payment_details = reservation.payment_details;
    var name = reservation.name;
    return this.http.post(environment.api + 'reservations', {
      start_date,
      end_date,
      user,
      studio,
      total,
      pay,
      prestations,
      type_selected,
      payment_details,
      name
    },
      {
        headers: { Authorization: 'Bearer ' + ((tokenJWT) ? tokenJWT : this.tokenStorageService.getToken()) }
      }
    );
  }

  contactStudio(array: any): Observable<any> {
    return this.http.post(environment.api + 'contact-studios/add', array, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  update_reservation(id: string, data: any): Observable<any> {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + this.endPoint + id, formData,
      // etat,
      // is_deleted,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }

  getEventsIcal(url: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + this.endPoint + "ical", { url: url },
        {
          headers: { 'content-type': "application/json" }
        }
      ).toPromise().then((res: any) => {
        if (res.type == "success") {
          resolve(res.data);
        } else {
          reject("lien iCal invalid")
        }
      }, err => {
        reject("une érreur est survenue")
      })
    })
    return promise
  }
}
