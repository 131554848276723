import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParrainerAmiComponent } from './parrainer-ami.component';
import { ShareButtonModule } from '../modals/share-buttons/share-buttons.module';




@NgModule({
  declarations: [ParrainerAmiComponent],
  exports: [ParrainerAmiComponent],
  imports: [
    CommonModule,
    ShareButtonModule
  ]
})
export class ParrainerAmiModule { }
