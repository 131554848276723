<div class="text-start container mt-5 p-5">
    <h2>Charte STUDIOMAP</h2>
    <hr>
    <h5>Sommaire : </h5>
    <h6 class="mt-4">1) Charte du bon studio</h6>
    <div class="ms-3">
        <p>A. Informations précises</p>
        <p>B. Réponses rapides</p>
        <p>C. Disponibilité à jour</p>
        <p>D. Coopération avec Studiomap</p>
        <p>E. Équipement</p>
        <p>F. Service et assistance</p>
        <p>G. Mettre en évidence la Charte de son studio</p>
    </div>

    <h6 class="mt-5">2) Charte du bon prestataire free-lance</h6>
    <div class="ms-3">

        <p>A. Réponse rapide</p>
        <p>B. Informations précises</p>
        <p>C. Fiabilité vis-à-vis de l’artiste client</p>
    </div>

    <h6 class="mt-5">3) Charte du bon utilisateur « Artiste »</h6>
    <div class="ms-3">

        <p>A. Avant la réservation</p>
        <p>B. Avant la séance</p>
        <p>C. Pendant la séance</p>
        <p>D. Après la séance</p>
        <p>E. Respecter la charte Studiomap et la charte de chaque studio partenaire</p>
        <p class="mt-5">Ce texte a pour objet de faire appliquer une certaine déontologie dans le cadre de l’utilisation
            de la plateforme STUDIOMAP tant côté studio, Prestataire que côté Artiste. </p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger text-center">1. Charte du bon studio</h6>
        <p>Le but visé est ici d’instaurer un rapport de confiance profitant entre tous. Qu’il s’agisse des Artistes ou
            des L’EXPLOITANT DE STUDIO. Il faut s’avoir que STUDIOMAP s’engage à offrir un service de qualité aux
            artistes et cette qualité passe par la fiabilité de nos partenaires qui s’engage par tous moyens à offrir la
            meilleure expérience possible aux artistes, car c’est avant tout devant eux que nous sommes responsables.
            L’adage « Le client est roi » prend donc ici tout son sens ! </p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">A. Informations précises </h6>

        <p>L’EXPLOITANT DE STUDIO s’engage donc à veiller à ce que toute son annonce dans ses moindres détails (photos,
            description, prix) reflète précisément la réalité de ce que les ARTISTES vont trouver. L’équipe STUDIOMAP
            demande donc ici aux EXPLOITANTS DE STUDIOS de porter une attention particulière sur la qualité des photos
            car elles sont votre vitrine. Il s’agira donc de mettre en valeur votre studio, vous pourrez ainsi générer
            plus de réservations. Ainsi, une annonce comporte :</p>
        <p>- Présentation brève du studio ;</p>
        <div class="ms-4">

            <p>• Présentation brève du gérant, responsable (mentionnez si vous êtes titulaire d’un diplôme d’ingénieur
                son,
                êtes-vous un musicien possédant un studio, êtes-vous un autodidacte ?, depuis quand pratiquez-vous cette
                activité ?, indiquez quelques mots sur votre passion, des infos inédites qui séduiront les ARTISTES) ;
            </p>
            <p>• Les styles musicaux maîtrisés ;
            </p>
            <p>• Le service (ce à quoi vont s’attendre les ARTISTES, exemple : pour 1h, enregistrement simple (ES) +
                mixage
                ; pour 2h, ES + mixage ou mastering; etc.) Vous aurez la possibilité de moduler vos offres comme vous le
                désirez.
            </p>
            <p>• Le support de stockage sur lequel vous allez rendre la musique. Informez L’ARTISTE s’il doit ramener
                une
                clé USB, par exemple ;
            </p>
            <p>• Vos équipements (détails, marque, modèle etc.) Pour savoir ce qu’un ARTISTE peut attendre d’un studio,
                merci de vérifier la liste du matériel souhaité.

            </p>
        </div>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">B. Réponses rapides </h6>
        <p>Il est également attendu que L’EXPLOITANT DE STUDIO réponde aux demandes d’information et de réservation dans
            les 24heures. Il est par ailleurs établi que les studios qui répondent en moins de 12 heures enregistrent en
            moyenne d’avantage de réservation chaque mois. </p>
        <p>Si votre studio n'est pas disponible, L’EXPLOITANT DE STUDIO doit veiller à refuser rapidement la demande de
            réservation pour faciliter le travail de l’ ARTISTE et de l’équipe STUDIOMAP. Enfin, si le Studio subit une
            absence prolongée de son équipe, il est préférable de laisser un message d’absence afin de prévenir
            automatiquement les utilisateurs. </p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">C. Disponibilités à Jour </h6>
        <p>Soyez sûr de pouvoir accueillir les ARTISTES ayant réservé. Lorsque le calendrier et les informations de
            votre studio sont bien à jour, vous augmentez vos chances de recevoir des demandes de réservation que vous
            pouvez honorer.</p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">D. Coopération avec STUDIOMAP </h6>
        <p>Point crucial : Il sera aussi apprécié que L’EXPLOITANT DE STUDIO de studio se rende disponible pour
            communiquer avec l’équipe studiomap en cas d’incident, questions et améliorations de l’usage de la
            plateforme. Il est par ailleurs conseillé à L’EXPLOITANT DE STUDIO de relier son planning de réservations à
            la plateforme STUDIOMAP afin de rendre plus fluide et automatique le processus de réservation côté ARTISTE.
        </p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">E. Équipements </h6>
        <p>Important ! L’équipement est la première condition pour assurer une expérience musicale optimale. Veillez à
            ce que votre matériel soit de qualité. Nous avons élaboré une liste de matériel standard que tout studio
            d’enregistrement doit posséder. L’ ARTISTE s’attend à un niveau d’équipement pour effectuer sa prestation.
            Merci de vous assurer de posséder l’ensemble de ces matériels avant de publier une annonce. </p>
        <p>Veuillez cependant faire attention à bien surveiller les ARTISTES pendant une séance dans vos locaux car
            STUDIOMAP s’exonère de toute responsabilité en cas de vol et/ou de casse du matériel.</p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">F. Service et assistance </h6>
        <p>Pour assurer une expérience musicale optimale à l’ARTISTE, il faut veiller à l’accompagner au mieux dans son
            processus de création. Nous désirons tous être conseillés, aiguillés pour améliorer nos capacités. Plus les
            conseils sont précis, meilleur sera le rendu, plus l’ ARTISTE sera satisfait.</p>
        <p>A l’issu de chaque séance d’enregistrement, l’ARTISTE attend que sa musique soit enregistrée, mixée voire
            selon les performances du studio, masterisée.</p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">G. Mettre en évidence la charte de son STUDIO </h6>
        <p>Point non négligeable également présent dans les conditions générales d’utilisation ! En effet, L’ ARTISTE
            s’engage lors de ses séances à respecter tant la charte STUDIOMAP tant la charte de chaque studio dans
            lequel il se rendrait. C’est pourquoi, il est impératif que chaque EXPLOITANT de STUDIO mette en évidence sa
            propre charte afin que l’ ARTISTE puisse en prendre connaissance avant chaque séance. Ainsi, s’il est
            établit que l’ ARTISTE n’a pas respecté les règles du studio dans lequel il a effectué une séance, mais que
            le studio ne lui avait pas donné la possibilité au préalable de prendre connaissance d’une charte exposant
            les règles internes (ex : ne pas fumer, ne pas venir à plus de 3 personnes, prendre soin du matériel etc..),
            STUDIOMAP s’exonère alors de toutes responsabilité. </p>
    </div>
    <h6 class="text-danger text-center mt-5">II) Charte du bon prestataire free-lance</h6>
    <div class="mt-5">
        <h6 class="text-danger">A. Réponse rapide </h6>
        <p>Comme pour les studios, il est crucial que le prestataire freelance réponde aux demandes d’information et de
            réservation dans les 24heures. Il est par ailleurs établi que les studios qui répondent en moins de 12
            heures enregistrent en moyenne d’avantage de réservation chaque mois. </p>
        <p>Si vous n’êtes pas disponible, veillez à refuser rapidement la demande de réservation pour faciliter le
            travail de l’ ARTISTE et de l’équipe STUDIOMAP. Ne jamais accepter une réservation sans en honorer la
            prestation au client, ce qui nous engagerait auprès du client et qui vous engagerait auprès de nous. Enfin,
            si vous subissez une absence prolongée, il est préférable de laisser un message d’absence afin de prévenir
            automatiquement les utilisateurs. </p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">B. Information précises</h6>
        <p>Le prestataire s’engage ici à donner des informations précises non seulement sur sa disponibilité, mais aussi
            sur sa compétence, son matériel et ses possibilités de prestations afin que l’ ARTISTE obtienne le service
            auquel il pouvait s’attendre en réservant sur la plateforme STUDIOMAP. </p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">C. Fiabilité vis-à-vis de L’ARTISTE client</h6>
        <p>Encore une fois, comme pour les EXPLOITANTS DE STUDIOS. La plateforme STUDIOMAP se rend responsable vis-à-vis
            des ARTISTES d’un point de vue qualité sur le service rendu. Il est donc inconcevable, qu’un prestataire
            freelance ne soit pas fiable et annule plusieurs fois d’affilé un rendez-vous à la dernière minute, ou pire,
            ne se rende pas au lieu de rendez-vous sans même prévenir le client. Le seuil de tolérance sera ainsi
            dépassé et L’équipe STUDIOMAP sera dans l’obligation de bannir le prestataire de sa plateforme pour
            préserver son image de marque et ne pas voir sa clientèle s’échapper vers d’autres concurrence. Mais il est
            clair que le but est de ne pas en arriver là. C’est pourquoi le prestataire se doit d’être fiable et de
            confiance.</p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">D. Collaboration avec STUDIOMAP</h6>
        <p>Le prestataire se doit également de collaborer avec l’équipe STUDIOMAP dont il est partenaire, respectez les
            règles des CGU et de la charte et éventuellement de communiquer son planning de manière précise. Créer des
            fiches incidents si il rencontre des problèmes avec les clients ou la plateforme. </p>
    </div>
    <h6 class="text-danger text-center mt-5">III) Charte du bon « ARTISTE» </h6>
    <div class="mt-5">
        <h6 class="text-danger">A. Avant la réservation</h6>
        <p><b>Remplissez</b> correctement les informations</p>
        <p><b>Pensez </b>à renseigner toutes les informations requises, cela rassurera les studios et augmentera vos
            chances de validation de réservation.</p>
        <p><b>Lisez </b>attentivement les fiches studios.</p>
        <p>Pour être certain de trouver le studio qui vous correspond, pensez à lire attentivement les fiches studios
            avant de réserver. Pour être certain d’obtenir un produit répondant parfaitement à vos attentes, privilégiez
            les studios qui ont acquis une bonne réputation sur STUDIOMAP. Et au cas où n’oubliez pas de consulter avant
            la réservation les conditions d’annulation.</p>
        <p><b>Précisez </b>attentivement les fiches studios.</p>
        <p>Pour être certain de trouver le studio qui vous correspond, pensez à lire attentivement les fiches studios
            avant de réserver. Pour être certain d’obtenir un produit répondant parfaitement à vos attentes, privilégiez
            les studios qui ont acquis une bonne réputation sur STUDIOMAP. Et au cas où n’oubliez pas de consulter avant
            la réservation les conditions d’annulation.</p>
        <p><b>Restez </b>sur STUDIOMAP durant tout le processus.
        <p>
        <p>Les transactions et échanges s’effectuant à l’extérieur de la plateforme STUDIOMAP exposent à un risque accru
            de fraude et de failles de sécurité. Pour ces raisons les paiements en dehors de la plateforme sont
            interdits dans nos conditions générales. Il est crucial de comprendre que notre service est pourvu d’un
            département intégralement dédié à la fraude dont la mission est d’observer le taux d’annulation, réservation
            et paiement et qu’au premier doute, le compte de l’utilisateur frauduleux peut être banni à tout moment.
        </p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">B. Avant la séance </h6>
        <p>Vérifiez que le studio peut accueillir vos accompagnateurs. Il est toujours plus agréable de réaliser une
            telle expérience accompagnée et nous le savons bien. Cependant pensez à demander auparavant au studio
            quelles sont les limites de place</p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">C. Pendant la séance </h6>
        <p><b>Soyez ponctuel </b></p>
        <p>Pour commencer du bon pied la session rien de mieux que la ponctualité. Même si un imprévu est vite arrivé
            essayez de les éviter, et si vraiment il vous sera impossible d’être présent à l’horaire décidé pensez à en
            informer le studio afin de conserver une bonne ambiance ! Par ailleurs et comme convenu dans dos conditions
            générales d’utilisation, il est impossible d’annuler ou modifier une réservation moins de 24h avant la
            prestation et donc impossible d’obtenir remboursement dans ce cas de figure. Donc pensez bien à ne pas
            réserver à la « va vite » si vous n’êtes pas surs d’être présents. </p>
        <p><b>Faites attention au matériel</b></p>
        <p>Les studios mettent à votre disposition du matériel, nous vous demandons ainsi de le respecter. Au moindre
            doute sur son utilisation n’hésitez pas à demander conseil au gérant du studio qui sera surement ravi de
            vous aider.</p>
        <p>Nous précisons que STUDIOMAP s’exonère de toute responsabilité en cas de vol et de casse du matériel.</p>
        <p><b>Soyez à l’écoute et courtois</b></p>
        <p>Les gérants des studios sont des passionnés ne cherchant qu’à vous aider ! Alors n’hésitez pas à écouter
            leurs conseils. ils ne sont là que dans un objectif : vous accompagner lors de la création de ce projet dont
            vous rêvez tant. Alors restez cordial cela permettra qui plus est une bonne ambiance. </p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">D. Après la séance </h6>
        <p><b>Donnez vos retours et partagez votre expérience.</b></p>
        <p>Bien entendu, vous n’êtes pas tenus de donner des retours et de partager à autrui votre bonne expérience.
            Mais il faut aussi comprendre que les milieux ARTISTES fonctionnent énormément aux bouches à oreilles.
            Ainsi, il sera apprécié tant par les EXPLOITANTS DE STUDIOS, tant par les PRESTATAIRES FREELANCES que par
            l’équipe studio map que vous partagiez votre expérience sur la plateforme surtout si vous avez apprécié un
            studio ou un ingénieur son en particulier.</p>
    </div>
    <div class="mt-5">
        <h6 class="text-danger">E. Respecter la Charte Studiomap et la charte de chaque studio partenaire.</h6>
        <p>Pour finir, l’ARTISTE s’engage en utilisant la plateforme STUDIOMAP à respecter la charte de celle-ci mais
            également et SURTOUT à respecter la charte de chaque studio de manière individuelle. En effet, avant chaque
            séance, vous aurez la possibilité de prendre connaissance des règles de vie dans les studios où vous vous
            rendez. Il est donc dans le devoir de chaque ARTISTE de les lires et de les respecter. </p>
    </div>

</div>