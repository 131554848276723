import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareButtonsComponent } from './share-buttons.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { faFacebookMessenger, faFacebookSquare, faLine, faLinkedin, faMailchimp, faMix, faPinterestSquare, faRedditSquare, faTelegram, faTumblrSquare, faTwitterSquare, faViber, faVk, faWhatsappSquare, faXingSquare } from '@fortawesome/free-brands-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCheck, faEllipsisH, faEnvelope, faLink, faMinus, faPrint, faSms } from '@fortawesome/free-solid-svg-icons';


const icons = [
  // ... other icons
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faPinterestSquare,
  faRedditSquare,
  faTumblrSquare,
  faViber,
  faMix,
  faVk,
  faTelegram,
  faFacebookMessenger,
  faWhatsappSquare,
  faXingSquare,
  faLine,
  faEnvelope,
  faSms,
  faEllipsisH,
  faPrint,
  faMinus,
  faLink,
  faCheck
];


const shareProp = {
  facebook: {
    icon: ['fab', 'facebook-square']
  },
  twitter: {
    icon: ['fab', 'twitter-square']
  },
  linkedin: {
    icon: ['fab', 'linkedin']
  },
  pinterest: {
    icon: ['fab', 'pinterest-square']
  },
  reddit: {
    icon: ['fab', 'reddit-square']
  },
  tumblr: {
    icon: ['fab', 'tumblr-square']
  },
  mix: {
    icon: ['fab', 'mix']
  },
  viber: {
    icon: ['fab', 'viber']
  },
  vk: {
    icon: ['fab', 'vk']
  },
  telegram: {
    icon: ['fab', 'telegram']
  },
  messenger: {
    icon: ['fab', 'facebook-messenger']
  },
  whatsapp: {
    icon: ['fab', 'whatsapp-square']
  },
  xing: {
    icon: ['fab', 'xing-square']
  },
  line: {
    icon: ['fab', 'line']
  },
  sms: {
    icon: ['fas', 'sms']
  },
  envelope: {
    icon: ['fas', 'envelope']
  },
  ellipsis: {
    icon: ['fas', 'ellipsis-h']
  },
  print: {
    icon: ['fas', 'print']
  },
  minus: {
    icon: ['fas', 'minus']
  },
  link: {
    icon: ['fas', 'link']
  },
  check: {
    icon: ['fas', 'check']
  }
};
@NgModule({
  declarations: [ShareButtonsComponent],
  exports: [ShareButtonsComponent],
  imports: [
    CommonModule,
    ShareButtonsModule.withConfig({ prop: shareProp }),
  ]
})
export class ShareButtonModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(...icons);
  }
}
