import { Component, OnInit } from '@angular/core';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { MetaService } from 'src/app/services/meta/meta.service';
import { SeoService } from 'src/app/services/seo/seo.service';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.css']
})
export class MentionsLegalesComponent implements OnInit {

  constructor(private emitterService:EmitterService,private metaService:MetaService,private seoService:SeoService) { }

  ngOnInit(): void {
    this.emitterService.mode.emit("dark mode");
    this.metaService.getByPageName("?page=mentions-legales&item=mentions-legales").subscribe(res => {

      if(res.length > 0) {
        this.seoService.generateTags({
          title: 'Studiomap : '+res[0].title,
          description: res[0].description,
          image: res[0].image.url,
          slug: ''
        });
      }
      
    });
  }

}
