export class Contact {
  constructor(
    full_name: string, 
    email: string, 
    phone: string, 
    object: string, 
    message: string,
    user: any,
    as_read: boolean
) {
    this.full_name = full_name
    this.email = email
    this.phone = phone
    this.object = object
    this.message = message
    this.user = user
    this.as_read = as_read
  }
  private full_name: string;
  private email: string;
  private phone: string;
  private object: string;
  private message: string;
  private user: any;
  private as_read: boolean;

    public getFull_name(): string {
        return this.full_name;
    }

    public setFull_name(full_name: string): void {
        this.full_name = full_name;
    }

    public getEmail(): string {
        return this.email;
    }

    public setEmail(email: string): void {
        this.email = email;
    }

    public getPhone(): string {
        return this.phone;
    }

    public setPhone(phone: string): void {
        this.phone = phone;
    }

    public getObject(): string {
        return this.object;
    }

    public setObject(object: string): void {
        this.object = object;
    }

    public getMessage(): string {
        return this.message;
    }

    public setMessage(message: string): void {
        this.message = message;
    }

}
