import { AfterViewChecked, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Message } from 'src/app/classes/message.model';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { MessagerieService } from 'src/app/services/messagerie/messagerie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-messagerie',
  templateUrl: './messagerie.component.html',
  styleUrls: ['./messagerie.component.css']
})

export class MessagerieComponent implements OnInit, AfterViewChecked {
  selectedEchange: any;
  messageEmitter: string;
  list: Array<Message> = [];
  realTimeList: Message[];
  currentUser: any;
  loading = false;
  scrHeight: any;
  scrWidth: any;
  message: any;
  messageFromStorage = false;
  constructor(
    private emitterService: EmitterService, public router: Router,
    private tokenStorage: TokenStorageService,
    private messageApi: MessagerieService,
    private spinner: NgxSpinnerService,
    private title: Title
  ) {

  }
  @ViewChild('showBottom') private myScrollContainer: ElementRef;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if(typeof window !== 'undefined') {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
    }
  }


  ngAfterViewChecked() {
    this.scrollToBottom();

  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }


  // left list
  getMessageList() {
    this.list = [];
    this.loading = true;
    this.spinner.show();
    this.messageApi.get_messages_list(this.tokenStorage.getUser().id).subscribe((res: any) => {
      res.filter(x => x.to).map(item => {
        let from = (this.currentUser.id == item.from.id) ? item.to : item.from;
        let to = (this.currentUser.id != item.from.id) ? item.to : item.from;
        this.list.push(new Message(item.id, from.profile.full_name, from.id, to.id, from.profile.avatar, item.last_message, item.as_read, moment(item.updatedAt).fromNow(), item.token));
      })
    }).add(() => {
      this.loading = false;
      this.spinner.hide();
    })
  }

  ngOnInit(): void {
    // meta & title
    this.title.setTitle("Studiomap : Messagerie");

    if (localStorage.getItem('message')) {
      let echange = JSON.parse(localStorage.getItem('message'));
      this.messageFromStorage = true;
      this.loading = false;
      this.selecteEchange(echange);
      this.messageEmitter = JSON.parse(localStorage.getItem('message'))["to"]
    }
    this.currentUser = this.tokenStorage.getUser();

    this.getMessageList();
    this.getScreenSize();
    this.emitterService.mode.emit("light mode");
  }

  // real time (echange)
  selecteEchange(message: Message) {
    this.realTimeList = [];
    this.selectedEchange = message;
    this.messageApi.get_real_time(this.selectedEchange.token).subscribe(result => {
      this.realTimeList = result.map(item => {
        let data = item.payload.doc.data();
        this.messageEmitter = (this.currentUser.id == data['from']) ? data['to'] : data['from'];
        let full_name = (this.currentUser.id == data['from']) ? this.currentUser.profile.full_name : this.selectedEchange.full_name
        let avatar = (this.currentUser.id == data['from']) ? (this.currentUser.profile.avatar) ? this.currentUser.profile.avatar.url : environment.defaultAvatar : this.selectedEchange.avatar
        return new Message(null, full_name, data['from'], data['to'], avatar, data['message'], false, "", data['token']);
      });
      this.scrollToBottom();
    }).add(() => {

    })
  }

  send() {
    let message = {
      "from": this.currentUser.id,
      "to": this.messageEmitter,
      "message": this.message,
      "token": this.currentUser.id + "-" + this.messageEmitter,
      "order": moment.now()
    }
    this.messageApi.send(message).then(result => {
      this.messageApi.get_by_token(this.currentUser.id, this.messageEmitter).subscribe((res: any) => {
        if (res.length > 0) {
          this.messageApi.update({ "from": this.currentUser.id, "to": this.messageEmitter, "last_message": this.message, "token": this.currentUser.id + "-" + this.messageEmitter, "as_read": false }, this.selectedEchange.id).subscribe(res => this.getMessageList()).add(() => { this.message = null })
        } else {
          this.messageApi.create({ "from": this.currentUser.id, "to": this.messageEmitter, "last_message": this.message, "token": this.currentUser.id + "-" + this.messageEmitter, "as_read": false }).subscribe(res => this.getMessageList()).add(() => { this.message = null })
        }
      })
      localStorage.removeItem('message');
    }).catch(err => {
      console.log(err);
    })
  }

  back() {
    this.selectedEchange = null;
    this.message = null;
    localStorage.removeItem('message');
  }
}