import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.api;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(API_URL + 'all', { responseType: 'text' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(API_URL + 'user', { responseType: 'text' });
  }

  getModeratorBoard(): Observable<any> {
    return this.http.get(API_URL + 'mod', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(API_URL + 'admin', { responseType: 'text' });
  }

  update(user: string, type: string, token: string): Observable<any> {
    return this.http.put(environment.api + 'users/' + user,
      JSON.stringify({ type: type })
      , { headers: { Authorization: 'Bearer ' + token,"content-type": "application/json" } });
  }

  getMe(jwt: String) : Observable<any>{
    return this.http.get(API_URL + 'users/me', {headers: { Authorization: 'Bearer ' + jwt,"content-type": "application/json" } },);
  }

  getById(userId:String,jwt: String) : Observable<any>{
    return this.http.get(API_URL + 'users/'+userId, {headers: { Authorization: 'Bearer ' + jwt,"content-type": "application/json" } },);
  }
}
