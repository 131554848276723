import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(identifier: string, password: string): Observable<any> {
    return this.http.post(environment.api + "auth/local/", {
      identifier,
      password
    }, httpOptions);
  }

  send_phone_confirmation(phone): Observable<any> {
    phone = "0" + String(phone * 1);
    return this.http.post(environment.api + "auth/send-phone-confirmation", {
      phone
    }, httpOptions);
  }

  valide_account_by_sms(phoneNumber, verificationCode: string): Observable<any> {
    return this.http.get(environment.api + "auth/phone/callback?phoneNumber=0" + String(phoneNumber*1) + "&verificationCode=" + verificationCode, httpOptions);
  }

  register(username: string, email: string, password: string, type: string, provider?: string): Observable<any> {
    let obj = {
      username,
      email,
      password,
      type
    };

    if (provider) {
      obj["provider"] = provider;
    }
    return this.http.post(environment.api + 'auth/local/register', obj, httpOptions);
  }

  register_with_phone(username, email: string, password: string, provider: string): Observable<any> {
    username = "0" + String(username * 1);
    return this.http.post(environment.api + 'auth/local/register', {
      username,
      email,
      password,
      type: 'artiste',
      provider
    }, httpOptions);
  }

  resetPassword(code: string, password: string, passwordConfirmation: string): Observable<any> {
    return this.http.post(environment.api + 'auth/reset-password', {
      code,
      password,
      passwordConfirmation
    }, httpOptions);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(environment.api + 'auth/forgot-password', {
      email
    }, httpOptions);
  }

  resendConfirmation(email: string): Observable<any> {
    return this.http.post(environment.api + 'auth/send-email-confirmation', {
      email
    }, httpOptions);
  }

  signInWithSocial(provider: string, token_access: string) {
    return this.http.get(environment.api + "auth/" + provider + "/callback/?access_token=" + token_access)
  }

  /**
   * 
   * @param provider 
   * @param accessToken 
   * @param email 
   * @param displayName 
   * @param isValid 
   */


}
