import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.css']
})
export class ShimmerComponent implements OnInit {

  @Input() iterator:any;
  @Input() height:any;
  @Input() width:any="100%";
  @Input() radius:any;
  @Input() class:any;
  @Input() shape:any;
  constructor() { }

  ngOnInit(): void {
  }

  number(length){
    return new Array(length);
  }
}
