import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EmitterService } from 'src/app/services/emitter/emitter';

@Component({
  selector: 'app-choose-type-user',
  templateUrl: './choose-type-user.component.html',
  styleUrls: ['./choose-type-user.component.css']
})
export class ChooseTypeUserComponent implements OnInit {

  socialUser: any;
  constructor(private emitterService: EmitterService, private title: Title, private router: Router) {
  }



  ngOnInit(): void {
    this.emitterService.mode.emit("light mode");
    // meta & title
    this.title.setTitle("Studiomap : User type");
  }

  selectType(value: string) {
    localStorage.setItem('choosen-type', value);
    if (localStorage.getItem('socialUser')) {
      this.socialUser = JSON.parse(localStorage.getItem('socialUser'));
      localStorage.removeItem('socialUser');
      localStorage.setItem('providedUser', JSON.stringify(this.socialUser));
      this.router.navigate(['/social-inscription']);
    } else {
      this.router.navigate(['/inscription']);
    }
  }

}
