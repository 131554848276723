<div style="
height: calc(100vh - 200px) !important;
display: flex;
justify-content: center;
align-items: center;
width: 50%;
margin: 0 auto;
">
    <p class="my-4">{{ message }}</p>
    <button class="btn btn-primary" *ngIf="showButtonListReservation" (click)="goToListReservations()">Mes
        réservations</button>
</div>