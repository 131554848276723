import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from 'src/app/classes/contact.model';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  endPoint = "contacts";
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create(contact:Contact): Observable<any> {
    return this.http.post(environment.api + this.endPoint ,contact, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  send(contact:Contact): Observable<any> {
    return this.http.post(environment.api + this.endPoint ,contact);
  }
}
