<div class="mb-5 pb-4">
    <div class="card mx-auto container my-5 text-start p-0" style="max-width: 400px;background-color: #726F85;">
        <div class="p-3 rounded text-center text-white">
            <i (click)="back()" class="fa fa-chevron-left mb-5 pointer float-start me-2"></i>
            <h5 class="text-start fw-bold mb-4 text-uppercase text-light text-center">Parrainer un ami</h5>
            <img height="100" src="assets/icons/surprise.svg" alt="">
            <h5 class="text-white text-uppercase my-4">
                Des prestations à <br> prix réduits !
            </h5>
            <p class="p-3">Invitez au moins 3 amis et obtenez
                <b>10%</b> de réduction sur votre prochaine prestation
            </p>
        </div>
        <div class="bg-white rounded p-3 text-center pb-5">
            <p>Votre code de parrainage</p>
            <button class="my-3 text-uppercase btn btn-light-gray w-100">{{userId}}</button>
            <button class="text-uppercase btn btn-danger w-100" data-bs-toggle="modal"
                data-bs-target="#shareButtons">Partager le code</button>
        </div>
    </div>
</div>
<app-share-buttons [url]="url" [description]="description"></app-share-buttons>