import { environment } from "src/environments/environment";

export class Message {
  private id: string;
  private full_name: string;
  private from: string;
  private to: any;
  private avatar: string;
  private message: string;
  private token: string;
  private as_red: boolean;
  private created_at: string;
  constructor(
    id: string,
    full_name: string,
    from: string,
    to: any,
    avatar: any,
    message: string,
    as_red: boolean,
    created_at: string,
    token?: string,
  ) {
    this.id = id
    this.full_name = full_name
    this.from = from
    this.to = (to.id) ? to.id : to
    this.avatar = (typeof (avatar) == "string") ? avatar : (avatar && avatar.url) ? avatar.url : environment.defaultAvatar;
    this.message = message
    this.as_red = as_red
    this.created_at = created_at
    this.token = (token) ? token : this.from + "-" + this.to;
  }

  get_token() {
    return this.token;
  }
}