
<nav class="navbar navbar-expand-custom navbar-light">
  <div class="container-xxl">
    <a class="navbar-brand" routerLink="/">
      <img src="./assets/icons/logo-white-red.svg" width="30" alt="logo">
      <span class="ms-3 text-uppercase text-white"
        style="letter-spacing: 10px;font-weight: 200;font-size: 13px;">studiomap</span>
    </a>
    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fas fa-bars text-white fs-3"></i>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
      <ul class="d-flex justify-content-between navbar-nav">

        <li class="nav-item dropdown position-static d-none d-xxl-block">
          <a data-bs-toggle="collapse" class="nav-link text-white text-capitalyze dropdown-toggle"
            routerLink="/artiste/reserver-studio" routerLinkActive="active">Réserver un studio</a>
          <ul class="dropdown-menu justify-content-center w-100 bg-dark" aria-labelledby="navbarDropdown">
            <div class="topnav"></div>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLink="/artiste/reserver-studio" [queryParams]="{type: 'ENREGISTREMENT'}"
                routerLinkActive="active-sub">Studio d’enregistrement</a></li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLink="/artiste/reserver-studio" [queryParams]="{type: 'PHOTOGRAPHIE'}"
                routerLinkActive="active-sub">Studio photo</a></li>
          </ul>
        </li>

        <li class="nav-item dropdown position-static d-block d-xxl-none">
          <a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            class="nav-link text-white text-capitalyze dropdown-toggle" routerLink="/artiste/reserver-studio"
            [queryParams]="{type: 'ENREGISTREMENT'}" routerLinkActive="active-sub">Studio d’enregistrement</a>
        </li>
        <li class="nav-item dropdown position-static d-block d-xxl-none">
          <a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            class="nav-link text-white text-capitalyze dropdown-toggle" routerLink="/artiste/reserver-studio"
            [queryParams]="{type: 'PHOTOGRAPHIE'}" routerLinkActive="active-sub">Studio photo</a>
        </li>
        <li class="nav-item dropdown position-static">
          <a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            class="nav-link text-white text-capitalyze dropdown-toggle" routerLink="/artiste/plus/pass-culture"
            routerLinkActive="active-sub">PASS CULTURE</a>
        </li>
        <li class="nav-item dropdown position-static">
          <a class="nav-link text-white text-capitalyze dropdown-toggle" href="https://blog.studiomap.fr/"
            routerLinkActive="active" target="_blank">ARTICLES</a>
        </li>
        <!-- <li class="nav-item dropdown position-static">
          <a class="nav-link text-white text-capitalyze dropdown-toggle" routerLink="/artiste/offres"
            routerLinkActive="active">Offres</a>
          <ul class="dropdown-menu justify-content-center w-100 bg-dark" aria-labelledby="navbarDropdown">
            <div class="topnav"></div>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/offres/photographe">Photo</a></li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/offres/videaste">Vidéo</a></li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/offres/designer">Motion Design 2D</a></li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/offres/designer">Motion Design 3D</a></li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/offres/modele">Modele</a></li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub">Pack</a></li> -->
        <!-- </ul>
        </li>
        <li class="nav-item dropdown position-static">
          <a class="nav-link text-white text-capitalyze dropdown-toggle" routerLink="/artiste/freelances/"
          routerLinkActive="active">Partenaires</a>
          <ul class="dropdown-menu justify-content-center w-100 bg-dark" aria-labelledby="navbarDropdown">
            <div class="topnav"></div>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/freelances/photographie">Photographie</a>
            </li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/freelances/video">Vidéo</a></li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/freelances/motion-design">Motion Design</a>
            </li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
                routerLinkActive="active-sub" routerLink="/artiste/freelances/modele">Modele</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown position-static me-xxl-5">
          <a class="nav-link text-white text-capitalyze dropdown-toggle" routerLink="/artiste/plus"
          routerLinkActive="active">Plus</a>
          <ul class="dropdown-menu justify-content-center w-100 bg-dark" aria-labelledby="navbarDropdown">
            <div class="topnav"></div>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
              routerLinkActive="active-sub" routerLink="/artiste/plus/pass-culture">Pass Culture</a></li>
            <li><a class="dropdown-item text-white" style="cursor: pointer;" href="https://blog.studiomap.fr/" target="_blank">Articles</a></li>
            <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item text-white"
              routerLinkActive="active-sub" routerLink="/artiste/plus/vision">Vision</a></li>
          </ul>
        </li> -->
        <li class="d-none d-xxl-block">&nbsp;</li>
        <li class="d-none d-xxl-block">&nbsp;</li>
        <li class="d-none d-xxl-block">&nbsp;</li>
        <li>
          <!-- <a class="btn btn-outline-light btn-sm text-uppercase fw-bold rounded"
            routerLink="/inscription-type">rejoignez-nous</a> -->
          <button routerLink="/inscription"
            class="btn btn-sm rounded-pill text-uppercase border-white text-white">rejoignez-nous</button>
        </li>
        <li>
          <!-- <a class="btn btn-danger btn-sm text-uppercase fw-bold rounded mt-nav" routerLink="/connexion">Connexion</a> -->
          <button class="btn btn-sm rounded-pill btn-danger text-uppercase text-white mt-nav"
            routerLink="/connexion">Connexion</button>
        </li>
        <li>
          <button routerlink="/connexion"
            class="border-0 btn btn-sm mt-nav ng-star-inserted pass-culture-gradient rounded-pill text-white"
            tabindex="0" data-bs-toggle="modal" data-bs-target="#passCultureModal"><img
              src="assets/menu-icons/pass-culture.png" alt="" style="
            height: 25px;
        "></button>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #fundModal let-c="close" let-d="dismiss">
  <div class="fundModal modal-content">
      <div class="modal-body">
          <button id="closepassCultureModal" type="button" (click)="c('Save click')"
              class="btn-close float-end"></button>
          <div class="fund">
              <a href="https://invest.tudigo.co/Studiomap" target="_blank" (click)="d('Save click')"
                  class="btn fundButton text-decoration-none">DEVENIR ACTIONNAIRE</a>
          </div>
      </div>
  </div>
</ng-template>
