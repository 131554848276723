import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingFormComponent } from './booking-form.component';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatterService } from 'src/app/services/custom-date-parser-formatter/custom-date-parser-formatter.service';
import { NgbDateAdatpterService } from 'src/app/services/ngb-date-adatpter/ngb-date-adatpter.service';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

@NgModule({
  declarations: [BookingFormComponent],
  exports: [BookingFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgxSliderModule,
    GooglePlaceModule,
    NgxMaskModule
  ],
  providers: [
    {provide: NgbDateAdapter, useClass: NgbDateAdatpterService},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService}
  ]
})
export class BookingFormModule { }
