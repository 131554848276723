<div class="col-md-9 mx-auto text-start container py-5">
    <h5>Mon profil</h5>
    <div class="col-lg-8 mx-auto">
        <h6 class="text-center">Photo</h6>
        <div class="text-center col-12" *ngIf="loading">
            <app-shimmer class="mb-5" width="152px" height="152px" shape="circle"></app-shimmer>
            <app-shimmer [iterator]=6 class="mb-4" height="40px" shape="rect"></app-shimmer>
        </div>
        <div *ngIf="!loading">
            <form [formGroup]='Form'>
                <div class="row">
                    <div class="col-12 text-center pb-4">
                        <input hidden (change)="uploadFile($event)" accept="image/gif, image/jpeg, image/png"
                            type="file" #inputFile>
                        <div style="height: 152px;width: 152px;" class="mx-auto p-0 rounded-circle overflow-auto">
                            <div style="height: 152px;width: 152px;"
                                class="mx-auto p-0 rounded-circle border position-relative">
                                <img class="rounded-circle cover" height="150" width="150" [src]="image"
                                    alt="user image">
                                <button type="button" style="left: 0;
                            bottom: 0;
                            font-size: small;
                            background: #00000038;" (click)='inputFile.click()'
                                    class="w-100 btn text-white position-absolute">
                                    changer
                                    <i style="font-size: small;" class="fas fa-pen"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center pb-5" *ngIf="user.type=='gerant'">
                        <div [ngClass]="status.color">{{status.text}}</div>
                        <a [href]="accountLink" target="_blank" class="text-primary pointer" *ngIf="showLink">Cliquer
                            ici</a>
                    </div>
                    <div class="col-md-6">
                        <label for="country" class="form-label">Nom complet</label>
                        <div class="position-relative pe-5">
                            <i class="fas fa-pen pen"></i>
                            <input [ngClass]="{'border-danger':f.full_name.errors && submitted}" type="text"
                                class="form-control no-border" placeholder="Nom complet" formControlName="full_name">
                        </div>
                    </div>

                    <div *ngIf="user.type=='freelance'" class="mt-3">
                        <div class="col-12">
                            <label for="email" class="form-label">Adresse</label>
                            <input [ngClass]="{'border-danger':f.lat.errors && submitted}" type="text"
                                class="form-control" placeholder="Adresse" #placesRef ngx-google-places-autocomplete
                                [options]='options' (onAddressChange)="handleAddressChange($event)"
                                placeholder="Entrer adresse" formControlName="adresse">
                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" formControlName="all_city">
                                <label for="">Disponible partout en France</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-3" *ngIf="user.type=='freelance'">
                        <label for="country" class="form-label">Biographie</label>
                        <textarea formControlName="description" class="form-control" placeholder="Biographie..."
                            style="height: 250px"></textarea>
                    </div>
                    <div class="col-md-12 ps-0 mt-3" *ngIf="user.type=='gerant'">
                        <label for="country" class="form-label ps-3">Coordonnées de professionnelles</label>
                        <div class="position-relative pe-5">
                            <i class="fas fa-pen pen"></i>
                            <input [ngClass]="{'border-danger':f.iban.errors && submitted}" type="text"
                                class="form-control no-border" placeholder="IBAN" formControlName="iban">
                        </div>
                    </div>
                    <div class="col-md-12 ps-0 mt-3">
                        <label for="country" class="form-label ps-3">Coordonnées de contact</label>
                        <div class="position-relative pe-5">
                                <i class="fas fa-pen pen"></i>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-phone">+33</span>
                                </div>
                                <input [readOnly]="!canEditPhone" [ngClass]="{'border-danger':f.phone.errors && submitted}" type="text"
                                    class="form-control no-border" placeholder="Téléphone" formControlName="phone"
                                    mask="(00) 00000000||000000000">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 ps-0 mt-3">
                        <div class="position-relative px-5"><img class="icon-left" src="assets/icons/envelope.svg"
                                alt="icon"><i class="fas fa-pen pen"></i><input type="text"
                                class="form-control no-border" [attr.disabled]="true" placeholder="email"
                                formControlName="email"></div>
                    </div>
                </div>
            </form>
            <form [formGroup]="socialMediaForm">
                <div class="col-md-12 ps-0 mt-3">
                    <h5>Réseaux sociaux</h5>
                    <div class="form-check mb-4" *ngFor="let type of social;let i=index;">
                        <input type="checkbox" class="form-check-input"
                            [checked]="arrayIndex(socialMediaForm,type.name)!=-1"
                            (change)="selectLink(socialMediaForm,$event)" [value]="type.name">
                        <label class="form-check-label link" for="same-address">{{type.name}}</label>
                        <div class="ms-3" *ngIf="arrayIndex(socialMediaForm,type.name)!=-1">
                            <div formArrayName="array">
                                <div [formGroupName]="arrayIndex(socialMediaForm,type.name)">
                                    <div class="col-auto">
                                        <input type="text" class="form-control m-0" formControlName="link"
                                            [placeholder]="type.link">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
            <div class="row my-4">
                <div class="col-lg-6 mb-2">
                    <button (click)="onSubmit()" type="submit" class="btn btn-danger w-100">SAUVEGARDER</button>
                </div>
                <div class="col-lg-6 mb-2">
                    <button (click)="deleteAccount()" type="submit"
                        class="border btn btn-transparent-white text-uppercase w-100 text-uppercase">Supprimer mon
                        compte</button>
                </div>
            </div>
        </div>
    </div>

</div>