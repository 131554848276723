<div class="text-start container mt-5 p-5">
        <h3>Conditions Générales d'Utilisation des services de STUDIOMAP</h3>
        <p>mises à jour le 4 mars 2021</p>
        <p>STUDIOMAP SAS (ci-après, «STUDIOMAP»), société par actions simplifiée au capital de 3.619 €, inscrite au
                Registre du Commerce et des Sociétés de Bobigny sous le n° 881 720 437 et dont le siège social est situé
                au 2 rue antoine etex, 94000 Créteil, propose des solutions pour la réalisation de visuels sur tout
                support.</p>
        <h5 class="mt-5">Article 1. Définitions</h5>
        <p>Chacune des expressions mentionnées ci-dessous aura dans le présent contrat la signification qui lui est
                donnée, qu’elle soit utilisée au singulier ou au pluriel, à savoir :</p>
        <p>• « Brief » ou « Commande » : désignent l’ensemble des besoins transmis à STUDIOMAP par les Clients, acceptés
                par STUDIOMAP et dont le but des Services est d’y répondre par son intermédiation entre les Clients et
                des Prestataires. Sont notamment déterminés dans les Briefs les dates d’exécution souhaitées, le nombre
                et les caractéristiques des Visuels, l’utilisation et l’envoi de produits spécifiques, le recours à
                certains types de Prestataires ainsi que le choix des lieux.</p>
        <p>• « Conditions Générales » ou « Conditions » : désignent les présentes conditions générales d’utilisation des
                services STUDIOMAP ;</p>
        <p>• « Site » ou « Site Internet » ou « Application » : désignent le site internet édité par STUDIOMAP,
                accessible à l’URL www.studiomap.fr ou notre plateforme studiomap.fr ;</p>
        <p>• « Services » : désignent les services d’intermédiation proposés par STUDIOMAP via le site Internet ou
                l’Application et désignés à l’article 4 des présentes ;</p>
        <p>• « Client » : désigne les personnes physiques ou morales qui souscrivent aux Services procèderaient à dans
                le cadre d’une commande de Visuels proposés par STUDIOMAP ;</p>
        <p>• « Prestataire » : désigne les personnes physiques ou morales intervenant en qualité de prestataire de
                service pour la réalisation de Visuels, contractant directement avec les Clients à l’issue des Services
                proposés par STUDIOMAP et à la demande de STUDIOMAP ;</p>
        <p>• « Utilisateur » : désigne toute personne utilisant les Services proposés par STUDIOMAP, Client ou
                Prestataire ;</p>
        <p>• « Visuels » : désigne tout support marketing proposé par STUDIOMAP, photos et/ou vidéos, que STUDIOMAP
                propose de faire réaliser via ses Services.</p>
        <h5 class="mt-5">Article 2. Objet</h5>
        <p>Les présentes Conditions Générales ont pour objet de définir les conditions dans lesquelles le Client peut
                utiliser l’Application pour définir ses besoins de Visuels, déterminer les lieux et Prestataires adaptés
                à ses besoins, organiser et réaliser les prestations, et réceptionner les Visuels réalisés.</p>
        <p>Les présentes Conditions Générales ont également pour objet de définir les conditions dans lesquelles le
                Prestataire peut organiser son profil, créer et publier des books, livrer les Visuels et prendre contact
                avec les Clients.</p>
        <h5 class="mt-5">Article 3. Portée des présentes</h5>
        <p>L’utilisation des services STUDIOMAP par les Prestataires et les Clients implique leur acceptation immédiate
                et inconditionnelle des présentes conditions.</p>
        <p>Les parties conviennent que leurs relations seront régies exclusivement par les présentes Conditions
                Générales ainsi que, pour les Clients, par les conditions générales de vente et tout devis, le cas
                échéant, à l'exclusion de toutes autres conditions disponibles sur tout autre support, qui n’ont qu’une
                valeur informative et non contractuelle et, pour les Prestataires, partout contrat de prestation conclu
                avec STUDIOMAP.</p>
        <p>Les relations entre les Clients et les Prestataires déterminés par les Services de STUDIOMAP seront quant à
                eux exclusivement régies par les contrats de prestations qui les lieront, STUDIOMAP n’y étant pas partie
                et n’étant qu’un intermédiaire.</p>
        <h5 class="mt-5">Article 4. Missions et responsabilité de STUDIOMAP</h5>
        <p>STUDIOMAP est une société d’intermédiation dans le domaine de la création de Visuels photos et vidéos à des
                fins publicitaires. Dans le cadre de cette activité, STUDIOMAP coopère avec les Prestataires afin de
                permettre la réalisation de Visuels conformément aux attentes des Clients.</p>
        <p>Ses missions auprès du Client sont de:</p>
        <p>• Définir avec le Client ses besoins en Visuels, en fonction de contraintes techniques, budgétaires et
                calendaires;</p>
        <p>• Déterminer et proposer au Client un ensemble de Prestataires et de services adéquats pour réaliser
                l’opération souhaitée;</p>
        <p>• Négocier avec le Prestataires les conditions de réalisation des Visuels;</p>
        <p>• Vérifier les disponibilités du Prestataire;</p>
        <p>• Permettre au Client de conclure un contrat de prestation de service avec les Prestataires déterminés,
                notamment via la solution certifiée Docusign ;</p>
        <p>• Organiser la réalisation des Visuels avec le Prestataire;</p>
        <p>• Maintenir l’intégrité des fichiers téléversés dans l’Application, sauf en cas de suppression volontaire de
                la part d’un Utilisateur;</p>
        <p>• Permettre au Client de télécharger les Visuels qu’il a choisi sur son espace en ligne;</p>
        <p>• Conserver au sein de l’Application les Visuels commandés, compressés et retravaillés, pendant une période
                de 12 mois ;</p>
        <p>• Permettre au Client de communiquer avec le Prestataire choisi depuis l’Application;</p>
        <p>• Permettre au Client d’accéder à l’ensemble des devis et factures établis depuis son inscription sur le
                site.</p>
        <p>Ses missions auprès du Prestataire sont de:</p>
        <p>• Transmettre en temps utile au Prestataire l’ensemble des informations nécessaires à la bonne exécution de
                ses prestations;</p>
        <p>• Permettre au Prestataire de conclure un contrat de prestation de service avec le Client, notamment via la
                solution certifiée Docusign;</p>
        <p>• Collaborer à la réalisation des Visuels;</p>
        <p>• Maintenir l’intégrité des fichiers téléversés dans l’Application, sauf en cas de suppression volontaire de
                la part de l’Utilisateur;</p>
        <p>• Assurer la confidentialité des books téléversés sur l’Application (STUDIOMAP se réserve le droit d’exposer
                sur son site internet certains clichés réalisés dans le cadre des Services à des fins commerciales ou
                promotionnelles).</p>
        <p>Il n’existe aucun lien de subordination entre STUDIOMAP et les Prestataires, qui sont des partenaires
                indépendants.</p>
        <p>Le Prestataire disposant d’une entière indépendance dans l’exécution de sa prestation, celle-ci s’effectuant
                dans le cadre du contrat de prestation de service conclu avec le Client.</p>
        <p>STUDIOMAP se réserve le droit de refuser tout document qu’elle jugerait comme suspect, violant quelconque
                droit des parties ou de tiers et/ou possiblement falsifié au regard d’un faisceau d’indices.</p>
        <h5 class="mt-5">Article 5. Accès aux Services</h5>
        <h6>5.1. Inscription du Client sur l’Application</h6>
        <p>Tout nouveau Client souhaitant requérir les Services de STUDIOMAP remplit pour ce faire le formulaire de
                contact disponible sur le Site Internet de STUDIOMAP. Un premier rendez-vous téléphonique ou physique
                est ensuite organisé. Ce rendez-vous a pour but de définir les besoins en Visuels du Client.</p>
        <p>À la suite du premier rendez-vous et d’un accord concernant l’opération à réaliser, STUDIOMAP aura la charge
                de créer un compte client au sein de l’Application.</p>
        <p>Dans le cadre de cette création de compte, le Client fournira à STUDIOMAP les informations suivantes:</p>
        <p>• Nom/Prénom du Client;</p>
        <p>• Dénomination sociale (si applicable);</p>
        <p>• Numéro RCS (si applicable);</p>
        <p>• Numéro de TVA intracommunautaire (si applicable);</p>
        <p>• Numéro de SIRET (si applicable);</p>
        <p>• Adresse du domicile ou siège social (selon le cas);</p>
        <p>• Année de naissance;</p>
        <p>• Numéro de téléphone;</p>
        <p>• Adresse email.</p>
        <p>Une fois le compte client créé sur l’Application, le Client recevra un email de STUDIOMAP l’invitant à se
                rendre sur une page web dans un délai imparti, afin que celui-ci active son compte, réinitialise son mot
                de passe et accepte les Conditions Générales d’Utilisation, les Conditions Générales de Vente ainsi que
                la Politique de Protection des Données. L’utilisation de l’Application implique l’acceptation immédiate
                et inconditionnelle des dits contrats.</p>
        <p>Un email de confirmation rappelant l’identifiant et le mot de passe du Client lui sera consécutivement
                transmis.</p>
        <p>Le Client aura un accès illimité à son compte dès la finalisation de ce processus.</p>
        <p>Pour accéder à son espace, le Client devra saisir son identifiant et son mot de passe.</p>
        <p>Le Client pourra à tout moment modifier ses informations personnelles au sein de l’Application.</p>
        <p>Le Client pourra y suivre l’état de traitement de ses commandes et l’évolution de son budget, communiquer
                avec les différents Prestataires qu’il aura sélectionné, télécharger les Visuels livrés, ainsi que
                commander des retouches. idem cgv</p>
        <p>Le Client disposera du même compte client pour toutes ses commandes.</p>
        <p>L’espace Client sur l’Application est strictement personnel et confidentiel. Le Client est responsable de la
                confidentialité de son identifiant et de son mot de passe. Ainsi, l’utilisation du Service est réputée
                être effectuée par le Client, sous son entière responsabilité.</p>
        <p>Sur demande du Client, STUDIOMAP sera en mesure de créer plusieurs accès au compte Client. Chaque accès
                disposera d’un identifiant et d’un mot de passe propre et jouira de l’ensemble des prérogatives sur le
                compte Client.</p>
        <p>STUDIOMAP se réserve le droit de supprimer ou bloquer l’accès à tout compte faisant l’objet d’une activité
                suspicieuse, ne respectant pas les Conditions Générales d’Utilisation ou les Conditions Générales de
                Ventes, ou plus généralement, portant atteinte à quelconque des droits des parties ou de tiers.</p>
        <h6>5.2. Inscription du Prestataire sur l’Application</h6>
        <p>Tout professionnel souhaitant prendre contact avec STUDIOMAP ou adhérer à ses Services remplit un formulaire
                dédié à son activité disponible sur le Site Internet de STUDIOMAP.</p>
        <p>Dans le cadre de ce premier contact à l’initiative du professionnel, il fournira à STUDIOMAP via le
                formulaire les informations relatives à ses coordonnées, domaines d’expertises, expériences
                professionnelles, matériels, et toute autre information pertinente liée à son activité.</p>
        <p>À la suite d’un processus de sélection déterminé par STUDIOMAP, un compte de Prestataire lui sera créé sur
                l’Application.</p>
        <p>STUDIOMAP se réserve le droit de sélectionner les professionnels qui entreront en relation commerciale avec
                elle.</p>
        <p>Une fois le compte Prestataire créé sur l’Application, le Prestataire recevra un email de STUDIOMAP
                l’invitant à se rendre sur une page web dans un délai imparti, afin que celui-ci active son compte,
                réinitialise son mot de passe et accepte les Conditions Générales d’Utilisation, les Conditions
                Générales de Vente ainsi que la Politique de Protection des Données. L’utilisation de l’Application
                implique l’acceptation immédiate et inconditionnelle des dits contrats.</p>
        <p>Un email de confirmation rappelant l’identifiant et le mot de passe du Prestataire lui sera consécutivement
                transmis.</p>
        <p>Le Prestataire aura un accès illimité à son compte dès la finalisation de ce processus.</p>
        <p>Pour accéder à son espace, le Prestataire devra saisir son identifiant et son mot de passe.</p>
        <p>Le Prestataire pourra à tout moment modifier ses informations personnelles au sein de l’Application.</p>
        <p>Le Prestataire pourra, sur son espace, mettre à jour l’état de traitement des commandes, communiquer avec les
                différents Clients, téléverser les Visuels ainsi qu’accéder à l’historique des opérations effectuées et
                à l’ensemble des devis et factures adressées à STUDIOMAP.</p>
        <p>À ce titre, la livraison des Visuels objets du contrat de prestation de service conclu entre le Prestataire
                et le Client sera obligatoirement effectuée via l’Application.</p>
        <p>Le Prestataire disposera du même compte pour toutes les prestations qu’il effectuera.</p>
        <p>L’espace prestataire sur l’Application est strictement personnel et confidentiel. Le Prestataire est
                responsable de la confidentialité de son identifiant et de son mot de passe. Ainsi, l’utilisation du
                Service est réputée être effectuée par le Prestataire, sous son entière responsabilité.</p>
        <p>STUDIOMAP se réserve le droit de supprimer ou bloquer l’accès à tout compte faisant l’objet d’une activité
                suspicieuse, ne respectant pas les Conditions Générales d’Utilisation ou les Conditions Générales de
                Ventes, ou plus généralement, portant atteinte à quelconque des droits des parties ou de tiers.</p>
        <h6>5.3. Fonctionnement des Services</h6>
        <p>STUDIOMAP s'engage à mettre en œuvre tous les moyens nécessaires pour assurer le bon fonctionnement de ses
                Services.</p>
        <p>Toutefois, STUDIOMAP se réserve le droit d’interrompre l’accès à ses Services à tout moment, pour l’un des
                motifs suivants:</p>
        <p>• en cas d’utilisation abusive ou frauduleuse des Services par le Client ou le Prestataire</p>
        <p>• en cas de force majeure tels que notamment ceux retenus comme tels par les Cours de Justice et de
                jurisprudences françaises;</p>
        <p>• dans l’hypothèse d’un quelconque dysfonctionnement des Services;</p>
        <p>• en vue d’une opération de maintenance.</p>
        <p>L’interruption de l’accès aux Services relève de la décision souveraine de STUDIOMAP, sans condition aucune
                de motivation de cette décision préalable aux Utilisateurs.</p>
        <p>En outre, STUDIOMAP ne peut être tenue responsable de l’impossibilité pour les Utilisateurs d’accéder à
                l’Application, lorsque cette impossibilité a pour cause des difficultés liées au réseau Internet ou
                toute autre cause étrangère à STUDIOMAP.</p>
        <h5 class="mt-5">Article 6. Obligations et garanties de STUDIOMAP</h5>
        <p>STUDIOMAP garantit que l’Application dispose d'un système de sécurité, garantissant une protection lors du
                téléchargement et de l’archivage des Visuels.</p>
        <p>STUDIOMAP mettra en œuvre toutes les diligences raisonnables pour maintenir la disponibilité de ses Services
                7 jours sur 7.</p>
        <p>STUDIOMAP ne saurait être tenue pour responsable dans les situations suivantes : perturbation dans
                l’utilisation du réseau Internet et présence de virus informatiques, et toute forme d’inexécution du
                Service due à des faits dits de force majeure tels que notamment ceux retenus comme tels par les Cours
                de Justice et de jurisprudence françaises.</p>
        <h5 class="mt-5">Article 7. Collecte et traitement des données à caractère personnel</h5>
        <p>STUDIOMAP respecte l'ensemble des dispositions légales applicables en matière de protection de la vie privée.
                Les données d'inscription, et plus généralement toutes les informations nominatives recueillies à
                l'occasion de l'utilisation du Service, sont traitées conformément aux dispositions du règlement
                européen du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des
                données à caractère personnel et à la libre circulation de ces données (RGPD), et de la loi n°78-17 du 6
                janvier 1978 modifiée, relative à l'informatique, aux fichiers et aux libertés.</p>
        <p>L’Application est hébergée par les services de la société OVH :</p>

        <p>OVH SAS au capital de 10 069 020 €
                RCS Roubaix – Tourcoing 424 761 419 00045
                Siège social : 2 rue Kellermann - 59100 Roubaix - France.
                OVH SAS est une filiale de la société OVH Groupe SAS, société immatriculée au RCS de Lille sous le
                numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix. </p>

        <p>En sa seule qualité de prestataire technique, STUDIOMAP collecte et conserve les données à caractère
                personnel que les Utilisateurs ont volontairement fournies via l’Application dans le cadre de la
                réalisation et la livraison de Visuels.</p>
        <h6 class="mt-5">7.1 Sécurité des données collectées</h6>
        <p>L'accès aux serveurs et au système STUDIOMAP sur lesquels les données sont collectées, traitées et archivées
                est strictement limité. Des précautions techniques et organisationnelles appropriées ont été prises afin
                d'interdire l'accès à toute personne non autorisée. Au sein de STUDIOMAP, seules les personnes dont les
                fonctions le justifient peuvent accéder aux données personnelles de l’Utilisateur.</p>
        <p>Pour des raisons de sécurité, après suppression des données personnelles de l’utilisateur, STUDIOMAP
                conservera pendant une période de trois (3) ans lesdites données, ainsi que l'historique des événements
                et opérations commandées.</p>
        <h6 class="mt-5">7.2 Finalités du traitement</h6>
        <p>L’Utilisateur est informé et accepte que STUDIOMAP, en sa qualité de responsable du traitement, collecte et
                procède à des traitements sur ses données personnelles à des fins de:</p>
        <p>• respect de toute disposition légale ou réglementaire applicable;</p>
        <p>• traitement/gestion et archivage ;</p>
        <p>• traitement des demandes de l’Utilisateur;</p>
        <p>• réalisation de tests et statistiques;</p>
        <p>• contrôle de la qualité du Service;</p>
        <p>Le Participant est informé que ses données personnelles peuvent être enregistrées dans un ou plusieurs
                fichiers dans le respect de la législation applicable et accepte que les données collectées soient
                enregistrées et traitées conformément aux finalités précédemment énoncées.</p>
        <h6 class="mt-5">7.3 Communication des données collectées</h6>
        <p>L’utilisateur accepte que les données personnelles strictement nécessaires à la réalisation d'une au moins
                des finalités mentionnées ci-dessus ou à ce qui est requis en vertu de la réglementation applicable,
                soient communiquées par STUDIOMAP à un Client ou à un Prestataire, selon le cas.</p>
        <p>STUDIOMAP ne communiquera en aucun cas les données personnelles de l’Utilisateur à un tiers autre que Clients
                ou Prestataires, sauf accord express et préalable de l’Utilisateur.</p>
        <p>Pour assurer le bon fonctionnement et la sécurisation de l’Application, STUDIOMAP peut être amené à
                journaliser les événements sur l’Application.</p>
        <p>STUDIOMAP ne peut être tenu responsable de l’authenticité des informations collectées et transmises dans le
                cadre de son activité et des finalités mentionnées ci-dessus.</p>
        <h6 class="mt-5">7.4 Droits d'opposition, d'accès et de rectification</h6>
        <p>L’Utilisateur a un droit d'accès aux données personnelles le concernant et est informé qu'il peut consulter à
                tout moment les informations qu'il a communiquées à STUDIOMAP lors de l’étape de configuration de son
                mot de passe d’accès à l’Application.</p>
        <p>L’Utilisateur a le droit d'obtenir la rectification des données inexactes qui le concernent, il est informé
                par STUDIOMAP qu'il peut modifier ses informations personnelles lors de l’étape de configuration de son
                mot de passe d’accès à l’Application.</p>
        <p>Les droits d'opposition, d'accès et de rectification peuvent être exercés gratuitement sur demande adressée à
                STUDIOMAP par courrier électronique à l'adresse email suivante : contact@studiomap.fr ou par courrier
                postal à l'adresse suivante : 2 rue antoine etex, 94000 Créteil.</p>
        <h6 class="mt-5">7.5 Cookies</h6>
        <p>Des cookies (fichiers envoyés par le serveur de STUDIOMAP et qui s'enregistrent sur le disque dur de
                l'ordinateur de tout Utilisateur) sont utilisés dans le cadre des Services proposés par STUDIOMAP. Ces
                cookies servent avant tout à améliorer le fonctionnement des Services notamment en termes de rapidité.
                Ces cookies permettent de conserver les données de l’Utilisateur jusqu’à la suppression de son compte.
                Ces cookies temporaires expirent automatiquement au bout de 72 heures d’inactivité sur l’Application ou
                lorsque l’Utilisateur quitte l’Application ou ferme son navigateur internet.</p>
        <p>L’Utilisateur est informé qu'il peut refuser les cookies du système STUDIOMAP dans les paramètres de son
                navigateur.</p>
        <p>Des cookies sont stockés (via Google Analytics et HEAP) sur l’appareil des Utilisateurs afin d’utiliser leurs
                données de recherche, de localisation et de navigation et pour comprendre leurs pratiques de navigation.
                Ces données ne sont pas collectées nominativement, mais uniquement à des fins statistiques pour
                améliorer l’expérience Utilisateur sur STUDIOMAP.</p>
        <h5 class="mt-5">Article 8. Durée du Contrat et suppression de l’inscription</h5>
        <p>Le présent Contrat entre en vigueur au moment où le Client ou le Prestataire créé son espace sur le Site et
                le restera jusqu’au terme de son inscription, quelle qu’en soit la raison.</p>
        <p>L’Utilisateur peut demander la suppression des fichiers, Visuels, communications et/ou son compte et résilier
                le présent Contrat à tout moment en adressant une demande à STUDIOMAP, soit depuis les paramètres de son
                compte, soit par courrier électronique à l'adresse email suivante : contact@studiomap.fr </p>
        <h5 class="mt-5">Article 9. Nullité</h5>
        <p>La nullité ou l'inapplicabilité de l'une quelconque des stipulations des présentes n'emportera pas nullité
                des autres stipulations qui conserveront toute leur force et leur portée.</p>
        <p>Les parties conviennent alors de remplacer la clause déclarée nulle et non valide par une clause qui s’en
                rapproche le plus quant au contenu de la clause initialement arrêtée.</p>
        <h5 class="mt-5">Article 10. Renonciation temporaire à un droit</h5>
        <p>Le fait pour l'une des parties de ne pas exercer, en une ou plusieurs occasions, les droits, options,
                réclamations ou actions que lui réserve la présente convention, ne pourra être interprété comme un
                abandon ou un refus de se prévaloir dudit droit, d'exercer ladite option, de formuler ladite réclamation
                ou d'exercer ladite action.</p>
        <h5 class="mt-5">Article 11. Règlement des litiges</h5>
        <p>Le présent contrat est soumis au droit français.</p>
        <p>En cas de litige, l’Utilisateur s’adressera par priorité à STUDIOMAP par courrier électronique (<a href="mailto:contact@studiomap.fr">contact@studiomap.fr</a>) pour tenter d’obtenir une solution amiable. A défaut de solution amiable, les
                tribunaux français seront seuls compétents. Toute procédure ou tout litige à naître du présent contrat
                sera du ressort du tribunal du lieu de domicile du défendeur ou, au choix du défendeur, de celui du lieu
                de réalisation de la prestation.</p>
        <h5 class="mt-5">Article 12. Notifications destinées à STUDIOMAP</h5>
        <p>Toute notification envoyée à STUDIOMAP dans le cadre du présent Contrat doit être adressée par courrier à :
                STUDIOMAP, 2 rue antoine etex, 94000 Créteil.</p>
        <p>Nous sommes également disponibles par courriel via contact@studiomap.fr.</p>


</div>