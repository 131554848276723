import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../auth/token-storage.service';
import { UserService } from '../auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class CheckTokenGuard implements CanLoad, CanActivate {
  constructor(
    private tokenStorage: TokenStorageService,
    private angularFireAuth: AngularFireAuth,
    private userService: UserService
  ) { }
  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<any> {
    if (!!this.tokenStorage.getToken() && !!this.tokenStorage.getUser) {
      this.angularFireAuth.authState.subscribe(res => {
        if (!res) {
          this.angularFireAuth.signInWithEmailAndPassword("app.studiomap@gmail.com", "%R9h66:cZq/RcB:_").then(res => {
          })
        }
      })
      this.userService.getMe(this.tokenStorage.getToken()).subscribe(res => {
        if (res.blocked) {
          this.tokenStorage.signOut();
          return
        }
      }, err => {
        this.tokenStorage.signOut();
        return
      })
    }
    return true
  }
  canLoad() {
    if (!!this.tokenStorage.getToken() && !!this.tokenStorage.getUser) {
      this.angularFireAuth.authState.subscribe(res => {
        if (!res) {
          this.angularFireAuth.signInWithEmailAndPassword("app.studiomap@gmail.com", "%R9h66:cZq/RcB:_").then(res => {
          })
        }
      })
      this.userService.getMe(this.tokenStorage.getToken()).subscribe(res => {
        if (res.blocked) {
          this.tokenStorage.signOut();
          return
        }
      }, err => {
        this.tokenStorage.signOut();
        return
      })
    }
    return true
  }
}
