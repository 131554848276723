import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingContainerComponent } from './booking-container.component';
import { BookingFormModule } from './booking-form/booking-form.module';
import { ConfirmAccountModule } from './confirm-account/confirm-account.module';
import { CreateAccountSmsComponent } from './create-account-sms/create-account-sms.component';
import { CreateAccountSmsModule } from './create-account-sms/create-account-sms.module';



@NgModule({
  declarations: [BookingContainerComponent],
  exports: [BookingContainerComponent],
  imports: [
    CommonModule,
    BookingFormModule,
    ConfirmAccountModule,
    CreateAccountSmsModule
  ]
})
export class BookingContainerModule { }
