import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MessagerieService {
  endPoint = "messageries/";
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService
    , private firestore: AngularFirestore
  ) { }

  create(form: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint, JSON.stringify(form),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }


  update(form: any,messageId:string): Observable<any> {
    return this.http.put(environment.api + this.endPoint+messageId, JSON.stringify(form),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }


  get_messages_list(userId: any) {
    return this.http.get(environment.api + this.endPoint + "?_where[_or][0][from]=" + userId + "&_where[_or][1][to]=" + userId + "&_sort=updatedAt:DESC",
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }
  get_by_token(from: any, to: any) {
    return this.http.get(environment.api + this.endPoint + "?_where[_or][0][token]=" + from + "-" + to + "&_where[_or][1][token]=" + to + "-" + from,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }

  reverseToken(token: string) {
    let value = token.split("-");
    return value[1] + "-" + value[0]
  }

  // firebase

  get_real_time(token: string) {
    return this.firestore.collection(this.endPoint.toString(), ref => ref.orderBy("order", "asc").where('token', 'in', [token, this.reverseToken(token)])).snapshotChanges();
  }

  send(message: any) {
    return this.firestore.collection(this.endPoint.toString()).add(message);
  }
}
