import { Component, EventEmitter, OnInit, Output, ElementRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Reservation } from 'src/app/classes/reservation.model';
import { Studio } from 'src/app/classes/studio.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ReservationsService } from 'src/app/services/reservations/reservations.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { StudioService } from 'src/app/services/studio/studio.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-account-sms',
  templateUrl: './create-account-sms.component.html',
  styleUrls: ['./create-account-sms.component.css']
})
export class CreateAccountSmsComponent implements OnInit {
  @ViewChild('myElement', { static: false }) myElement: ElementRef;
  @Input() user!: any;
  reservation: Reservation;
  studio: Studio;
  type: any;
  params: any;
  error: any;
  loading: boolean = false;
  varificationCode: string = "";
  unpaidReservation: string = "";
  send_code: boolean = false;
  reservationEnAttente: boolean = false;

  // emit account status to parent (booking conatiner) has account or not to manage redirections 
  @Output() emitCodeSmsResponse = new EventEmitter<any>();

  constructor(private authService: AuthService, private emitterService: EmitterService, private stripeService: StripeService, private authservice: AuthService, private profileService: ProfileService, private studioService: StudioService, private route: ActivatedRoute, private reservationService: ReservationsService, private paymentService: PaymentService, private router: Router, private tokenService: TokenStorageService) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log("------------ params datas ----------------");

      console.log(params);
      this.params = params;
      this.studioService.get_studio_by_id_model(params.studio).subscribe(studio => {
        this.studio = studio;
        let startdate = moment(moment(params.day, 'DD-MM-YYYY')).format('YYYY-MM-DD HH:mm');
        let endDate = moment(moment(params.day, 'DD-MM-YYYY')).add(params.hours_number, 'hours').format('YYYY-MM-DD HH:mm');
        this.type = studio.getTypeByName(params.typeStudio);
        this.reservation = new Reservation(studio, startdate, endDate, this.type.name, null, (this.type) ? this.type.tarif : null, null, 'rapid');

      }, err => {
        console.log("Une érreur est survenu");
      })
    })
  }

  back() {
    this.emitCodeSmsResponse.emit('booking');
  }

  sendPhoneConfirmation() {
    this.send_code = false;
    this.authService.send_phone_confirmation(this.params.phone).subscribe(res => {
      this.send_code = true;
    }, err => {
      this.error = "numéro de téléphone invalid";
      this.send_code = false;
    })
  }

  valider() {
    this.error = "";
    this.send_code = false;
    if (this.varificationCode != "") {
      this.loading = true;
      this.authservice.valide_account_by_sms(this.params.phone, this.varificationCode).subscribe(res => {

        this.tokenService.saveToken(res["jwt"]);
        this.tokenService.saveUser(res.user);
        this.emitterService.getLoggedUser.emit(res.user);
        localStorage.setItem('type', res.user.type);
        localStorage.setItem('current_type', "artiste");
        //console.log(this.studio, res);
        
        if(this.isIFrame())
         parent.postMessage(JSON.stringify({ userData: res.user }), environment.webflowURL);

        if (this.studio.user && this.studio.user.id == res.user.id) {
          this.error = "Impossbile de faire une réservation dans votre studio";
          this.loading = false;
        } else if (this.studio?.id) {
          this.router.navigate([`/artiste/fiche-studio/${this.studio?.id}`], { queryParams: this.params });
        } else {
          if(this.isIFrame()) parent.location.href = environment.webflowURL + '/reservation';
        }

      }, err => {
        this.error = "code invalide !";
        this.loading = false;
      });
    } else {
      this.error = "veuillez saisir le code de validation";
    }
  }

  validerOld() {
    this.error = "";
    this.send_code = false;
    if (this.varificationCode != "") {
      this.loading = true;
      this.authservice.valide_account_by_sms(this.params.phone, this.varificationCode).subscribe(res => {

        this.tokenService.saveToken(res["jwt"]);
        this.tokenService.saveUser(res.user);
        this.emitterService.getLoggedUser.emit(res.user);
        localStorage.setItem('type', res.user.type);
        localStorage.setItem('current_type', "artiste");
        this.reservationService.get_reservations_by_user_id("payment.type=Pré-Réservation&payment.type=waiting&studio.id=" + this.params.studio).subscribe((reservationFound) => {
          console.log(reservationFound);

          if (reservationFound.length > 0) {
            this.error = "vous avez déja une réservation rapide non traitée pour ce studio";
            this.unpaidReservation = reservationFound[0].id;
            this.reservationEnAttente = true;
          } else {
            this.reservationService.create_reservation(this.reservation, res.user.id, res["jwt"]).subscribe(reservation => {
              console.log("reservation done...");

              // create payment pre-reservation
              this.paymentService.create_payment("", "", reservation.id, "", "Pré-Réservation", res["jwt"]).subscribe(p => {

                console.log("payment done....");
                console.log("réservation créé avec succès")
                if (this.isIFrame()) {
                  parent.location.href = environment.webflowURL + "/app?action=reservations-list&from=create";
                } else {
                  this.router.navigate(['/artiste/reservations']);
                }
              }, err => {
                this.error = "Une érreur est survenue, Réservation non créée";
                this.loading = false;
              });
            });
          }
        }, err => {

        }).add(() => {
          this.loading = false;
        })

      }, err => {
        this.error = "code invalide !";
        this.loading = false;
      });
    } else {
      this.error = "veuillez saisir le code de validation";
    }

  }


  ngAfterViewInit(): void {
    this.getHeightBody();
  }

  private waitForViewChild(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.myElement) {
        resolve();
      } else {
        setTimeout(() => this.waitForViewChild().then(resolve), 100);
      }
    });
  }

  getHeightBody() {
    if (this.isIFrame()) {
      this.waitForViewChild().then(() => {
        const elementHeight = this.myElement.nativeElement.offsetHeight + 233;
        console.log(elementHeight);
        parent.postMessage(JSON.stringify({ iframeHeight: elementHeight, userData: this.user, bookingStep: 'checkAccount' }), environment.webflowURL);
      });
    }
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

  goToReservation() {
    if (this.isIFrame()) {
      let link = environment.webflowURL + '/app?action=reservation-details&id=' + this.unpaidReservation;
      if (link != null && link != "") {
        // Open link in a new tab or window
        //parent.location.href = link;
        window.open(link, '_blank');
      }
    } else {
      this.router.navigate(['/artiste/reservation-details-artiste/' + this.unpaidReservation])
    }
  }

}
