import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Address } from 'cluster';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { UserService } from 'src/app/services/auth/user.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { FormService } from 'src/app/services/form/form.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ReuseRouteService } from 'src/app/services/reuse-route/reuse-route.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inscription-social',
  templateUrl: './inscription-social.component.html',
  styleUrls: ['./inscription-social.component.css']
})
export class InscriptionSocialComponent implements OnInit {
  Form: FormGroup;
  submitted = false;
  type_service = environment.freelancePrestations;
  response: any
  //google place (paris france by default)
  options = {
    types: [],
    componentRestrictions: { country: 'FR' }
  };
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  public handleAddressChange(address: Address) {
    var addressComponents = address;
    this.getAdress(addressComponents);
  }


  constructor(private userService: UserService, private tokenService: TokenStorageService, private location: Location, private formService: FormService, private spinner: NgxSpinnerService, private emitterService: EmitterService, private fb: FormBuilder, private profileService: ProfileService, private title: Title, private router: Router, private stripeService: StripeService) {
    this.Form = this.fb.group({
      adresse: [''],
      type_service: [this.type_service[0].name],
      lat: [''],
      lng: [''],
      type: ['artiste'],
      phone: ['', Validators.required],
      cgu: [false],
      all_city: [false],
    })
  }
  //validator submit
  get f() { return this.Form.controls; }

  ngOnInit(): void {
    this.formService.setControlValue(this.Form, 'type', localStorage.getItem('choosen-type'));
    if (localStorage.getItem('providedUser')) {
      this.router.navigate(['/social-inscription']);
    } else {
      this.router.navigate(['/inscription']);
    }
    this.emitterService.mode.emit("light mode")
    // meta & title
    this.title.setTitle("Studiomap : Inscription");
  }

  reditrection(userType: any) {
    let path = "";
    localStorage.setItem('type', userType);
    if (localStorage.getItem("reservation")) {
      let reservation = JSON.parse(localStorage.getItem("reservation"));
      if (userType == 'gerant' || userType == 'freelance') {
        localStorage.setItem('current_type', "artiste");
      }
      this.router.navigate(["/artiste/fiche-studio/" + reservation.studio]);
    } else {
      if (userType == 'gerant' || userType == 'freelance') {
        localStorage.setItem('current_type', userType);
      }
      switch (userType) {
        case "artiste":
          path = "/" + userType + "/reserver-studio";
          break;
        case "gerant":
          path = "/" + userType + "/tableau-de-bord";
          break;
        case "freelance":
          path = "/" + userType + "r/tableau-de-bord";
          break;
      }
      this.router.navigate([path]);
    }
  }

  async onSubmit() {
    this.submitted = true;
    if (this.Form.value.type == 'freelance') {
      this.formService.requireControl(this.Form, 'adresse');
    } else {
      this.formService.facultatifControl(this.Form, 'adresse');
      this.formService.emptyControl(this.Form, 'type_service');
      this.formService.emptyControl(this.Form, 'adresse');
      this.formService.setControlValue(this.Form, 'all_city', null);
    }

    if (this.Form.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
      return;
    }
    this.spinner.show();
    let data = JSON.parse(localStorage.getItem('signInWithSocialResponse'));
    let user = JSON.parse(localStorage.getItem('providedUser'));

    let avatar: any;

    await fetch(user.photoUrl, { mode: "cors" })
      .then((e) => {
        return e.blob()
      })
      .then((blob) => {
        let b: any = blob
        b.lastModifiedDate = new Date()
        b.name = 'avatar'
        avatar = b;
      })


    this.profileService.create_profile(data.user.id, user.email, user.name, this.Form.value.phone, this.Form.value.adresse, this.Form.value.lng, this.Form.value.lat, this.Form.value.type_service, this.Form.value.all_city).subscribe(profile => {
      this.profileService.update_profile(profile.id, null, avatar, data["jwt"]).subscribe(res => {
      })
      this.userService.update(data.user.id, this.Form.value.type, data["jwt"]).subscribe(updatedUser => {
      })
      this.stripeService.create_customer(data.user.id, user.email, user.name).subscribe(token => {
        let generated_social_user = {
          "id": data.user.id,
          "email": user.email,
          "username": user.email,
          "stripe": {
            "customer_id": token.customer_id,
          },
          "type": (this.Form.value.type == "freelance") ? "artiste" : this.Form.value.type,
          "profile": {
            "all_city": false,
            "id": profile.id,
            "first_name": user.firstName,
            "last_name": user.lastName,
            "user": data["user"]["id"],
            "full_name": user.name,
            "phone": "0" + String(this.Form.value.phone * 1),
            "avatar": {
              "url": user.photoUrl,
            },
          }
        }

        this.tokenService.saveToken(data["jwt"]);
        this.tokenService.saveUser(generated_social_user);
        this.emitterService.getLoggedUser.emit(generated_social_user);
        localStorage.setItem('type', this.Form.value.type)
        if (this.Form.value.type == 'gerant' || this.Form.value.type == 'freelance') {
          localStorage.setItem('current_type', this.Form.value.type);
        }
      })
      Swal.fire('Félicitation', 'Votre nouveau compte a été créé avec succès', 'success');
      this.reditrection(this.Form.value.type);
      this.removeStorage();
    }).add(() => {
      this.spinner.hide();
    })

  }

  removeStorage() {
    localStorage.removeItem('providedUser');
    localStorage.removeItem('signInWithSocialResponse');
  }

  select() {
    this.Form.controls["type_service"].setValue(this.type_service[0].name);
  }

  getAdress(addressComponents) {
    this.Form.patchValue({
      lat: addressComponents.geometry.location.lat().toString(),
      lng: addressComponents.geometry.location.lng().toString(),
      adresse: addressComponents.formatted_address
    });
  }

  back() {
    this.location.back();
  }

}
