export class Reservation {
    private start_date: string;
    private end_date: string;
    private type: string;
    private prestations: any;
    private total: string;
    private pay: string;
    private studio: string;
    private passculture: string;
    private payment_details: string;
    private name: string;

    /**
     * 
     * @param studio 
     * @param start_date 
     * @param end_date 
     * @param type 
     * @param prestations 
     * @param total 
     * @param pay 
     * @param passculture
     * @param payment_details
     */
    constructor(studio: any, start_date: string, end_date: string, type: string, prestations: any, total: any, pay: any, name?: string, passculture?: any, payment_details?: any) {
        this.studio = studio;
        this.start_date = start_date;
        this.end_date = end_date;
        this.type = type;
        this.prestations = prestations;
        this.total = total;
        this.pay = pay;
        this.passculture = passculture;
        this.payment_details = payment_details;
        this.name = (name) ? name : 'classic';
    }

    payment() {
        this.prestations = "[" + this.prestations.toString().replaceAll(/"/g, '') + "]"
    }
}
