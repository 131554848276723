import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { ProfileService } from 'src/app/services/profile/profile.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-choose-inscription-type',
  templateUrl: './choose-inscription-type.component.html',
  styleUrls: ['./choose-inscription-type.component.css']
})
export class ChooseInscriptionTypeComponent implements OnInit {
  constructor(

    private emitterService: EmitterService,
    public router: Router, private fb: FormBuilder,
    private authService: AuthService,
    private profileService: ProfileService,
    private sauthService: SocialAuthService) {

  }

  ngOnInit(): void {

    this.emitterService.mode.emit("light mode");
    // meta & title
  }

  signInWithGoogle(): void {
    this.sauthService.signOut();
    this.sauthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: any) => {
      this.checkIfAccountExist('google', user)
    });
  }

  signInWithFB(): void {
    this.sauthService.signOut();
    this.sauthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: any) => {
      this.checkIfAccountExist('facebook', user)
    });
  }

  checkIfAccountExist(provider: string, user: any) {
    this.authService.signInWithSocial(provider, user.authToken).subscribe((data: any) => {
      this.profileService.find("user=" + data["user"]["id"], data["jwt"]).subscribe(res => {
        if (res.length == 0) {
          localStorage.setItem('socialUser', JSON.stringify(user));
          
          localStorage.setItem('signInWithSocialResponse', JSON.stringify(data));
          this.router.navigate(['/choose-user-type']);
        } else {
          Swal.fire("Attention", "l'adresse email est déjà utilisée !!", 'info');
        }
      })


    }, err => {
      if (err.error.statusCode) {
        if (provider == "google") {
          try {
            this.sauthService.signOut();
          } catch (ex) { }
        }
        Swal.fire("Échec de la connexion", "Veuillez utiliser un autre mode de connexion", 'info');
      }
    })

  }
}
