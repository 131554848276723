import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
// import 'moment/locale/fr';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {

  constructor() {
    moment.locale('fr');
  }

  //  mercredi 9 juin 2021 14:00
  showAllDate(date: any,heure_fin?:boolean) {
    if (heure_fin) {
      return moment(date).utcOffset(0).add("hour", 1).format("LLLL");
    } else {
      return moment(date).utcOffset(0).format("LLLL");
    }
  }
  
  //  mercredi 9 juin 2021
  showAllDateWithoutHour(date: any,heure_fin?:boolean) {
    if (heure_fin) {
      return moment(date).utcOffset(0).add("hour", 1).format("dddd LL");
    } else {
      return moment(date).utcOffset(0).format("dddd LL");
    }
  }

  //  mercredi 9 juin 2021
  showAllDateWithouthour(date: any) {
    return moment(date).utcOffset(0).format("LL");
  }

  // 09/04/1986
  showLocalFormat(date: any) {
    return moment(date).utcOffset(0).format("L");
  }

  //  14h00
  showHourMinute(date: any, heure_fin: boolean) {
    // moment().utcOffset("-01:00");
    if (heure_fin) {
      return moment(date).utcOffset(0).add("hour", 1).format(`HH`) + "h" + moment(date).utcOffset(0).format(`mm`)
    } else {
      return moment(date).utcOffset(0).format(`HH`) + "h" + moment(date).utcOffset(0).format(`mm`)
    }
  }

  //  jun jui
  showMonth(date: any) {
    return moment(date).utcOffset(0).format('MMM');
  }

  //  Lun. 30 août
  showFullDate(date: any) {
    return moment(date).utcOffset(0).format("ddd Do MMM");
  }

  //  19 05
  showDayNumber(date: any) {
    return moment(date).utcOffset(0).format('DD');
  }

  // 2021-06-17 16:00
  showDateHour(date: any,heure_fin?:boolean) {
    if (heure_fin) {
      return moment(date).utcOffset(0).add("hour", 1).format(`YYYY-MM-DD HH:mm`)
    } else {
      return moment(date).utcOffset(0).format(`YYYY-MM-DD HH:mm`)
    }
  }


  formatNgbToMoment(date: NgbDate, time: any) {
    // NgbDates use 1 for Jan, Moement uses 0, must substract 1 month for proper date conversion
    var ngbObj = JSON.parse(JSON.stringify(date));
    var newMoment = moment().utcOffset(0);

    if (ngbObj) {
      ngbObj.month--;
      newMoment.month(ngbObj.month);
      newMoment.date(ngbObj.day);
      newMoment.year(ngbObj.year);
    }

    // Convert date to "Mon Feb 01" format
    if (newMoment.isValid()) {
      return moment(newMoment.format('YYYY-MM-DD') + ' ' + time).format();
    } else {
      return '';
    }
  }

  isBetween(date: string, startDate: string, endDate: string) {
    // moment('2010-10-20').isBetween('2010-10-19', '2010-10-25'); // true
    return moment(date).isBetween(startDate, endDate) || (date == startDate) || (date == endDate);
  }

  // 2021-06-12 20:00
  formatResevation(date: NgbDate, time: any) {
    if (!time) {
      time = '00';
    }
    var ngbObj = JSON.parse(JSON.stringify(date));
    var newMoment = moment().utcOffset(0);

    if (ngbObj) {
      ngbObj.month--;
      newMoment.month(ngbObj.month);
      newMoment.date(ngbObj.day);
      newMoment.year(ngbObj.year);
    }

    // Convert date to "Mon Feb 01" format
    if (newMoment.isValid()) {
      return moment(newMoment.format('YYYY-MM-DD') + ' ' + time).format("YYYY-MM-DD HH:mm");
    } else {
      return '';
    }
  }

  hours_24() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      let number: string = '';
      if (String(i).length == 1) {
        number = '0' + i;
      } else {
        number = String(i);
      }
      hours.push(number);
    }
    return hours;
  }
}
