<span class="" *ngIf="loading && !unpaidReservation"><i class="fa fa-spinner fa-spin"></i>
  Chargement...</span>
<div *ngIf="reservationEnAttente && !loading" class="text-danger fs-12 pt-2">
  <h3 class="m-0">{{error}}</h3>
  <button type="button" class="w-100 btn rounded btn-lg btn-danger mt-4" (click)="goToReservation()">Détails
    réservation</button>
</div>
<div #myElement>
  <div *ngIf="!reservationEnAttente && !!unpaidReservation">
    <h3 class="m-0">Réserve ta session immédiatement (⏱️ 2 min)</h3>
    <div class="row pt-3 m-0">
      <div class="col-md-12 p-0">
        <form [formGroup]="Form">
          <div *ngIf="studioPartenaire">
            <div class="form-group px-1">
              <input (click)="setIFrameHeight()" type="radio" id="classic" formControlName="type" name="type"
                value="classic" placeholder="Nom complet">
              <label for="classic" class="px-2">Réserver <span class="fw-bold">"{{studio.name}}"</span> à une date
                précise</label>
            </div>
            <div class="form-group p-1 pt-3">
              <input (click)="setIFrameHeight( !emptyObject(user) ? 170 : 0 )" type="radio" id="rapid"
                formControlName="type" name="type" value="rapid">
              <label for="rapid" class="px-2">Trouvez votre bonheur : réservez par critéres de
                recherche. <a
                  title="En cas de non-réponse de {{studio.name}}, ne laissez pas votre recherche s'arrêter là ! Définissez vos critères de recherche et découvrez des options alternatives. Tous les studios dans vos critères sront contactés jusqu'a vous obtenir un rendez-vous. Vous receverez ensuite une notification pour règler vôtre facture.">
                  <i class="fa fa-info-circle"></i>
                </a>
              </label>
            </div>
          </div>
          <input *ngIf="!isClassic()" type="hidden" name="type" formControlName="type" value="rapid" />
          <div *ngIf="emptyObject(user)">
            <input formControlName="complete_name" placeholder="Nom complet" type="text"
              class="form-control" [ngClass]="{'border-danger':Form.controls.complete_name.errors && submitForm}">
            <input formControlName="email" placeholder="Adresse email" type="text"
              class="form-control" [ngClass]="{'border-danger':Form.controls.email.errors && submitForm}">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text input-phone" style="padding: 0.7rem 0.75rem;">+33</span>
              </div>
              <input mask="(00) 00000000||000000000" formControlName="phone" placeholder="Téléphone" type="text"
                class="form-control" [ngClass]="{'border-danger':Form.controls.phone.errors && submitForm}">
            </div>
          </div>
        </form>
        <form [formGroup]="reservationForm" onChange="totalPrice" *ngIf="!isClassic() && !emptyObject(user)">
          <!-- reservation day -->
          <div *ngIf="!type">
            <span class="text-gray fs-12">Prix max de la session</span><br>            
            <h2 class="text-danger pb-2 fs-24 m-0">{{maxPrice()}} € / heure</h2>
            <h2 class="pb-2 fs-24 m-0">Total: {{totalPrice()}} €</h2> 
            <hr>
          </div>
          <div>
            <div class="alert alert-warning" *ngIf="!studioPartenaire">
              <i class="fa fa-info-circle"></i>
              Les dix premiers studios dans vos critères sront contactés jusqu'a vous obtenir un rendez-vous. 
              Vous receverez ensuite une notification pour règler vôtre facture. Le montant sera calculé sur la base du plus chère des Studios et sera ajusté avant encaissement par rapport aux choix final.
            </div>
            <div class="d-flex justify-content-between my-3">
              <div class="input-group">
                <input #placesRef formControlName="adresse" ngx-google-places-autocomplete [options]='placesOptions'
                  (onAddressChange)="handleAddressChange($event)" placeholder="" type="text" class="form-control m-0"
                  [value]="formatted_address">
                <div class="input-group-append">
                  <button (click)="currentPosition()" title="ma position" class="btn border rounded-end border-start-0" style="box-shadow: none;">
                    <i class="fa fa-location-arrow"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-floating my-3">
              <select formControlName="studio_type" class="form-select my-0">
                <option *ngFor="let type of types_studio" [value]="type.key">{{type.label}}</option>
              </select>
              <label for="floatingSelect">Type de studio</label>
            </div>
            <div class="d-flex justify-content-between my-3">
              <div class="input-group">
                <input class="form-control m-0" placeholder="dd/mm/yyyy" (ngModelChange)="selectDay()" [minDate]="toDay"
                  formControlName="day" ngbDatepicker #d2="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn border rounded-end border-start-0" (click)="showDatePicker(d2)" type="button"><i
                      class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-floating">
              <select formControlName="hours_number" class="form-select my-0" id="floatingSelect"
                (change)="syncHoraireStep()" aria-label="Floating label select example">
                <option *ngFor="let hoursChoice of hoursChoices" [value]="hoursChoice"
                  [disabled]="!nbHourEnabled(hoursChoice)">{{hoursChoice}}</option>
              </select>
              <label for="floatingSelect">Nombre d'heures</label>
            </div>

            <div class="d-flex mt-3">
              <div class="p-2 align-self-center w-100">
                <div class="pb-3 align-self-center text-nowrap text-start">Plage horaire ({{horairesSlider.minValue}}:00
                  - {{horairesSlider.maxValue}}:00)</div>
                <div class="custom-slider" style="height: 100%">
                  <ngx-slider [(value)]="horairesSlider.minValue" [(highValue)]="horairesSlider.maxValue"
                    (mouseup)="syncHoraireStep()"  (touchend)="syncHoraireStep()" [options]="horairesSlider.options"
                    [step]="reservationForm.controls.hours_number.value">
                  </ngx-slider>
                </div>
              </div>
            </div>

            <div class="d-flex mt-3">
              <div class="p-2 align-self-center w-100">
                <div class="pb-3 align-self-center text-nowrap text-start">Distance (km)</div>
                <div class="custom-slider" style="height: 100%">
                  <ngx-slider [(value)]="distanceSlider.minValue" [(highValue)]="distanceSlider.maxValue"
                    [options]="distanceSlider.options">
                  </ngx-slider>
                  {{distanceSlider.minValue}}
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="p-2 align-self-center w-100">
                <div class="pb-3 align-self-center text-nowrap text-start">Prix (€)</div>
                <div class="custom-slider" style="height: 100%">
                  <ngx-slider [(value)]="priceSlider.minValue" [(highValue)]="tarif"
                    [options]="priceSlider.options">
                  </ngx-slider>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="p-2 align-self-center w-100">
                <div class="pb-3 align-self-center text-nowrap text-start">Options :</div>
                <div class="">
                  <label *ngFor="let option of webflowOptions" class="form-check-label mx-1">
                    <input name="webflowOptions[]" type="checkbox" class="form-check-input">
                    <span class="form-check-sign p-1">{{option.value}}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="p-2 align-self-center w-100">
                <div *ngIf="!studio">
                  <span class="text-gray fs-12">Prix max la session</span><br>
                  <h2 class="text-danger pb-2 fs-24 m-0">{{tarif}}€ / heure</h2>
                </div>
                <div class="pb-3 align-self-center text-start">{{studioList.length}} Studio seront contactés.</div>
                <div class="alert small alert-dark-gray">
                  Dès qu'un studio acceptera vôtre demande vous serez notifier pour payer la réservation.
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- !reservation day -->
        <button type="button"
          class="w-100 btn rounded btn-lg btn-danger mt-4" (click)="nextStep(Form.value)"
          *ngIf="!loading">{{!isSearchPaymentStep() ? 'Chercher' : '>  Suivant'}}
        </button>
        <button type="button"
          class="w-100 btn rounded btn-lg btn-dark-gray mt-4" (click)="clearStudios()"
          *ngIf="isSearchPaymentStep()"> < Précedent
        </button>
        <button class="buttonload w-100 btn rounded btn-lg mt-4 btn-secondary btn-outline-dark " *ngIf="loading">
          <i class="fa fa-spinner fa-spin"></i> Chargement
        </button>
        <div *ngIf="error != ''" class="text-danger fs-12 pt-2">
          {{error}}
        </div>
        <span *ngIf="studio.user && !studioPartenaire" style="font-size: 10px;">* Studio non partenaire</span>
      </div>
    </div>
  </div>
</div>