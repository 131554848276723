<div class="col-md-9 mx-auto text-start container pb-5" style="min-height: 700px;">
    <i (click)="back()" class="fa fa-chevron-left mb-5 pointer float-start me-2"></i>
    <h5>Paramètres</h5>
    <div class="col-lg-6 mx-auto pt-4" *ngIf="currentUser">
        <div class="card my-5">
            <div class="row g-0">
                <div class="card-body mt-4">
                    <h4>Mon compte</h4>
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label text-uppercase fw-bold">Identifiant</label>
                        <p>{{currentUser.email}}</p>
                    </div>
                    <div class="mb-3 d-flex justify-content-between">
                        <label for="exampleFormControlInput1" class="form-label text-uppercase fw-bold">Mot de
                            passe</label>
                        <a class="text-primary pointer" (click)="changePassord()"> Cliquer ici</a>
                    </div>
                    <!-- <hr>
                    <div class="mb-3 d-flex justify-content-between">
                        <label for="exampleFormControlInput1" class="form-label text-uppercase fw-bold">Notification</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox">
                        </div>
                    </div>
                    <div class="mb-3 d-flex justify-content-between">
                        <label for="exampleFormControlInput1" class="form-label text-uppercase fw-bold">Géolocalisation</label>
                        <div class="form-check form-switch">
                            <input checked  class="form-check-input" type="checkbox">
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>