<button class="d-none" id="openPassCutureModal" data-bs-toggle="modal" data-bs-target="#passCultureModal"></button>
<!-- Modal -->
<div class="modal fade" id="passCultureModal" tabindex="-1" aria-labelledby="passCultureModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div [formGroup]="form">
                    <button id="closepassCultureModal" type="button" class="btn-close float-end"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="text-center mb-2">
                        <h3 class="mt-3">Vous avez réservé sur le Pass Culture ?</h3>
                        <img class="w-75 mb-4" src="assets/landing-background/Pass-Culture.jpeg" alt="">
                        <h6>Saisissez votre code contre-marque</h6>
                        <input (keyup)="handleKeyUp($event)" (input)="changeTextToUppercase('contremarque')" [ngClass]="{'border-danger':submitted && controls.contremarque.errors}" class="form-control w-75 mx-auto text-center bg-light-gray" type="text"
                            formControlName="contremarque">
                            <div class="text-danger mb-3" *ngIf="submitted && ( controls.contremarque.errors.maxlength || controls.contremarque.errors.minlength)">contremarque invalide</div>
                            <div class="text-danger mb-3" *ngIf="submitted && controls.contremarque.errors.required==true">contremarque requise</div>
                        <button (click)="onSubmit()" type="button" class="btn btn-danger text-uppercase">confirmer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
