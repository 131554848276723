import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Contact } from 'src/app/classes/contact.model';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { ContactService } from 'src/app/services/contact/contact.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  Form: FormGroup;
  submitted = false;
  constructor(public router: Router, private spinner: NgxSpinnerService, private title: Title, private emitterService: EmitterService, private fb: FormBuilder, private contactService: ContactService, private tokenStorage: TokenStorageService) {
    this.Form = this.fb.group({
      full_name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      object: ['', Validators.required],
      message: ['', Validators.required],
      user: [''],
      as_read: [''],
    })
  }

  get f() {
    return this.Form.controls;
  }


  ngOnInit(): void {
    this.emitterService.mode.emit("light mode");
    // meta & title
    this.title.setTitle("Studiomap : Contact");
    if (this.tokenStorage.getUser().id) {
      let user = this.tokenStorage.getUser();
      let c = new Contact(user.profile.full_name, user.email, user.profile.phone, "", "", user.id, false);
      this.Form.setValue(c);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.Form.invalid) {
      Swal.fire('Error', 'Veuillez vérifier vos informations', 'error')
      return
    }
    this.spinner.show();
    this.contactService.create(this.Form.value).subscribe(res => {

      Swal.fire('Envoyé', 'votre message a bien été envoyé', 'success');
    }, err => {

    }).add(() => {
      this.spinner.hide();
    })
  }


}
