<div #myElement>
  <h3>Vous avez déjà un compte ?</h3>
  <button class="buttonload w-100 btn btn-outline-dark rounded btn-lg mt-4 btn-secondary" *ngIf="loading"><i
      class="fa fa-spinner fa-spin"></i> Vérification...</button>
  <div *ngIf="!loading">
    <button type="button" (click)="hasAccount('hasAccount')" class="btn btn-danger w-100 text-uppercase mt-3">
      Oui</button>
    <button type="button" (click)="hasntAccount('validSms')"
      class="btn btn-outline-dark w-100 text-uppercase my-1">Non</button>
    <button type="button" (click)="back()" class="btn btn-dark-gray w-100 text-uppercase"><i
        class="fa fa-chevron-circle-left me-2 pointer"></i> Précédent</button>
  </div>

  <div *ngIf="error" class="text-danger fs-12 pt-2">
    {{error}}
  </div>
</div>