import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }


  createAccountConnect(full_name: string, email: string, iban: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + 'stripes/account', {
        full_name,
        email,
        iban,
      }, { headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() } }
      ).toPromise().then((res: any) => {
        if (res.success) {
          resolve(res.data.id);
        } else {
          reject("iban invalid")
        }
      })
    })
    return promise
  }

  createPaymentLink(data: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + 'stripes/create-payment-link', data
      ).toPromise().then((res: any) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
    return promise
  }

  updateAccountConnect(accountId: string, email: string, full_name: string, iban: string, userId: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + 'stripes/accountUpdate', {
        accountId,
        email,
        full_name,
        iban,
        userId
      }, { headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() } }
      ).toPromise().then((res: any) => {
        if (res.success) {
          resolve(res.data.id);
        } else {
          reject("iban invalid")
        }
      })
    })
    return promise
  }

  createRefund(chargeId: string): Observable<any> {
    return this.http.post(environment.api + 'stripes/createRefund', JSON.stringify({ "charge_id": chargeId }),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  capture(payment_intent: string, capture: boolean): Observable<any> {
    return this.http.post(environment.api + 'stripes/capture', {
      payment_intent,
      capture,
    },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }


  /** 
   * @returns PaymentMethod
   */
  getListPaymentMethod(): Observable<any> {
    return this.http.post(environment.api + 'stripes/getListPaymentMethod', JSON.stringify({ "customer": this.tokenStorageService.getUser().stripe.customer_id }),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  /**
   * 
   * @param paymentMethodId 
   * @returns 
   */
  deletePaymentMethod(paymentMethodId: string): Observable<any> {
    return this.http.post(environment.api + 'stripes/deletePaymentMethod', JSON.stringify({ "paymentMethod": paymentMethodId }),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  /**
   * 
   * @param PaymentMethod 
   * @returns default payment method
   */
  defaultPaymentMethod(PaymentMethod: any): Observable<any> {
    return this.http.post(environment.api + 'stripes/defaultPaymentMethod', JSON.stringify({ "customer": this.tokenStorageService.getUser().stripe.customer_id, "payementMethod": PaymentMethod }),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  /**
   *  object :  {
      *  "items": [
          {"price": product.token_price}
        ],
        "metadata": {"abonnment_id": abonnementCreated.id}
      }
   * @param form 
   * @returns Subscription
   */

  createSubscription(form: any): Observable<any> {

    form["customer"] = this.tokenStorageService.getUser().stripe.customer_id

    return this.http.post(environment.api + 'stripes/createSubscription', JSON.stringify(form),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  /**
   * 
   * @param invoiceToken 
   * @returns 
   */
  getInvoice(invoiceToken: string): Observable<any> {

    return this.http.post(environment.api + 'stripes/getInvoice', { "invoiceToken": invoiceToken },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  createAccountLink(accountId: string): Observable<any> {
    return this.http.post(environment.api + 'stripes/accountLink', {
      accountId
    },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }

  checkIsVerify(user: string): Observable<any> {
    return this.http.post(environment.api + 'stripes/getAccountConnect/' + user, {
    },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }

  create_customer(user: string, email: string, full_name: string): Observable<any> {
    return this.http.post(environment.api + 'stripes', {
      user,
      email,
      full_name,
    },
      httpOptions
    );
  }


  createPaymentMethod(card: any): Observable<any> {
    let data = {
      number: card.creditCard,
      mois: card.expirationDate.slice(0, 2),
      annee: card.expirationDate.slice(2),
      cvc: card.cvc,
      customer: this.tokenStorageService.getUser().stripe.customer_id
    }
    return this.http.post(environment.api + 'stripes/createPaymentMethod', JSON.stringify(data)
      ,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      }
    );
  }

  createPaymentIntent(total: any, customer: string, paymentMethodId: string, email: any, studioName: any): Observable<any> {

    let amount = Number(total * 100);

    let description = "Studio " + studioName;

    return this.http.post(environment.api + 'stripes/createPaymentIntent', {
      amount,
      customer,
      paymentMethodId,
      email,
      description
    },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }

  getPaymentIntent(idPayment: string): Observable<any> {

    return this.http.post(environment.api + 'stripes/getPaymentIntent', {
      idPayment
    },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }

  confirmPaymentIntent(idPayment: string): Observable<any> {
    return this.http.post(environment.api + 'stripes/confirmPaymentIntent', {
      idPayment
    },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }


  update(id: string, account_id: string): Observable<any> {
    return this.http.put(environment.api + 'stripes/' + id, {
      account_id
    },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }
  checkCustomer() {
    const promise = new Promise((resolve, reject) => {
      this.http.post(environment.api + 'stripes/checkCustomer',
        { "user": this.tokenStorageService.getUser().id, "email": this.tokenStorageService.getUser().email, "full_name": this.tokenStorageService.getUser().profile.full_name },
        {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
        }
      ).toPromise().then((res: any) => {
        if (res.success) {
          resolve(res.data.customer_id);
        } else {
          reject("une érreur est survenu");
        }
      }).catch(() => {
        reject("une érreur est survenu");
      })
    })
    return promise
  }
}
