import { Component, OnInit } from '@angular/core';
import { EmitterService } from 'src/app/services/emitter/emitter';

@Component({
  selector: 'app-charte-studio-map',
  templateUrl: './charte-studio-map.component.html',
  styleUrls: ['./charte-studio-map.component.css']
})
export class CharteStudioMapComponent implements OnInit {

  constructor(private emitterService: EmitterService) { }

  ngOnInit(): void {
    this.emitterService.mode.emit("light mode");
  }

}
