import { environment } from "src/environments/environment";
import { Profile } from "./profile.model";
import { Studio } from "./studio.model";

export class User {
    confirmed: boolean;
    blocked: boolean;
    username: string;
    email: string;
    type: string;
    provider: string;
    profile: Profile;
    stripe: string;
    studios: Array<Studio> = [];
    abonnements: [];
    id: string

    constructor(data: Partial<User>) {
        Object.assign(this, data);
        this.profile = new Profile(data.profile);
        let studios: Array<Studio> = [];
        if (data.studios) {
            data.studios.forEach(studioItems => {
                studios.push(new Studio(studioItems));
            });
            this.studios = studios;
        }
    }

    avatar(){
        return (this.profile?.avatar?.url) ? environment.api.replace(/\/$/, '') + this.profile.avatar.url : environment.defaultAvatar;
    }
}