import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PassCulture } from 'src/app/classes/passculture.model';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})

export class PassCultureService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create_in_db(data: any) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + "passcultures", formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  validate_reservation(code: string, body: any) {
    return this.http.post(environment.api + "passcultures/get/validate/" + code, JSON.stringify(body), {
      headers: { "content-type": "application/json" }
    });
  }

  getDetailsReservationFromPassCulture(code: string): Observable<any> {

    return this.http.get(environment.api + "passcultures/get/details-reservation/" + code, {
      headers: { "content-type": "application/json" }
    });
  }

  getDetailsOfferFromPassCulture(id: string): Observable<any> {

    return this.http.get(environment.api + "passcultures/get/details-offer/" + id, {
      headers: {  "content-type": "application/json" }
    });

  }

  create(pass: any): Observable<any> {
    return this.http.post(environment.api + 'passcultures/', JSON.stringify(pass), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  update(id: string, isConsumes: boolean): Observable<any> {
    return this.http.put(environment.api + 'passcultures/' + id, JSON.stringify({ "is_consumes": isConsumes }), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  find(code: string): Observable<any> {
    return this.http.get(environment.api + "passcultures/check/" + code, {
      headers: { "content-type": "application/json" }
    });
  }
}