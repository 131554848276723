import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmitterService } from '../emitter/emitter';

@Injectable({
  providedIn: 'root'
})
export class MenuSwitchService {
  constructor(public emitterService: EmitterService,private spinner:NgxSpinnerService,public router: Router) { }

  async switch(){
      this.spinner.show();
      await this.delay(1000);
      this.spinner.hide();
      let path=(localStorage.getItem('type')=='gerant')?'/gerant/tableau-de-bord':'/freelancer/tableau-de-bord';
      (localStorage.getItem('current_type') == 'artiste') ? (localStorage.setItem('current_type',localStorage.getItem('type')), this.router.navigate([path]), this.emitterService.mode.emit('light mode')) : (localStorage.setItem('current_type','artiste'), this.router.navigate(['/artiste/reserver-studio']));
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
