import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  currentUser:any;
  constructor(private title:Title,private emitterService: EmitterService,private token:TokenStorageService,private location:Location,private authService:AuthService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.currentUser=this.token.getUser();
    this.emitterService.mode.emit("light mode");
    // meta & title
    this.title.setTitle("Studiomap : Paramètres");
  }
  back() {
    this.location.back();
  }

  changePassord(){
    this.spinner.show();
    this.authService.forgotPassword(this.currentUser.email).subscribe(
      res => {
        this.token.signOut();
        Swal.fire('Envoyé', 'Le lien de réinitialisation a été envoyé.\nVeuillez vérifier votre couriel.', 'success');
        this.spinner.hide();
      },
      err => {
        Swal.fire('Érreur', 'Une érreur s\' est produite. Veuillez réessayer.', 'error');
        this.spinner.hide();
      }
    )
  }

}
