<form *ngIf="initialized && stepLoginPhone == ''" [formGroup]="Form" class="p-3" (ngSubmit)="onSubmit('connexion')">
    <div class="card mx-auto shadow-sm-none container p-md-5 my-5 bg-transparent" style="max-width: 400px;">
        <h5 class="fw-bold mb-4">CONNEXION</h5>
        <p class="mb-3">Connectez-vous avec votre <span class="text-danger">nom d'utilisateur</span> ou adresse mail</p>
        <input (keyup.enter)="onSubmit('connexion')" type="email" class="form-control border"
            [ngClass]="{'border-danger':f.identifier.errors && submitted}" formControlName="identifier"
            placeholder="Email">
        <input (keyup.enter)="onSubmit('connexion')" type="password" class="form-control border"
            [ngClass]="{'border-danger':f.password.errors && submitted}" formControlName="password"
            placeholder="Mot de passe" required>
        <button class="btn btn-danger text-uppercase" (click)="onSubmit('connexion')" type="button" *ngIf="!loadingLoginWithPhone">connexion</button>
        <button class="w-100 btn rounded btn-lg btn-secondary btn-outline-dark mt-2" *ngIf="loadingLoginWithPhone"><i
            class="fa fa-spinner fa-spin"></i>
        Chargement...</button>
        <div *ngIf="error!=''" class="text-danger fs-12 pt-2">
            {{error}}
        </div>
        <a  *ngIf="emailConfirmation" class="text-primary pointer" (click)="resendConfirmation()" data-bs-dismiss="modal"
        aria-label="Close">je n'ai pas reçu l'émail d'activation</a>

        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="pointer mt-3" *ngIf="!fromBooking">Mot de passe
            oublié ?</a>
        <p class="mt-4 mb-0" *ngIf="!fromBooking">Vous n'avez pas de compte ?</p>
        <a class="pointer text-danger" routerLink="/inscription" *ngIf="!fromBooking">Inscription</a>
        <a class="pointer" routerLink="/" *ngIf="!fromBooking">Continuer sans compte</a>
        <p class="pointer mt-3">se connecter avec :</p>
        <button class="btn border btn-white mt-2 pe-4" type="button" (click)="nextActionSignInWithPhone()">
            Numéro téléphone
        </button>
        <button class="btn border btn-white mt-2 pe-4" type="button" (click)="signInWithFB()">
            <img class="me-2" height="30" width="30" src="assets/icons/facebook-btn.svg" alt="facebook"> Facebook
        </button>
        <button class="btn border btn-white mt-2 pe-5" (click)="signInWithGoogle()" alt="google login" type="button">
            <img class="me-2" height="25" width="25" src="assets/icons/google-btn.svg" alt="google"> Google
        </button>
        <button *ngIf="isIFrame() && fromBooking" type="button" class="btn btn-dark-gray w-100 text-uppercase mt-2"
            (click)="back()"><i class="fa fa-chevron-circle-left me-2 pointer"></i> Précédent</button>
    </div>
</form>

<div *ngIf="stepLoginPhone == 'send-code'" class="p-3">
    <div class="card mx-auto shadow-sm-none container p-md-5 my-5 bg-transparent" style="max-width: 400px;">
        <h5 class="fw-bold mb-4">CONNEXION</h5>
        <p class="mb-3">Connectez-vous avec votre <span class="text-danger">numéro de téléphone</span></p>

        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text input-phone">+33</span>
                </div>
                <input [(ngModel)]="phoneNumber" type="tel" id="phone" name="phone" class="form-control"
                    placeholder="Numéro de téléphone" required mask="(00) 00000000||000000000">
            </div>
        </div>

        <button class="btn btn-danger text-uppercase" (click)="sendCodeConfirmation()" type="button"
            *ngIf="!loadingLoginWithPhone">continuer</button>
        <button (click)="back()" type="button" class="btn btn-dark-gray w-100 text-uppercase mt-2"
            *ngIf="!loadingLoginWithPhone"><i class="fa fa-chevron-circle-left me-2 pointer"></i> Précédent</button>
        <button class="w-100 btn rounded btn-lg btn-secondary btn-outline-dark" *ngIf="loadingLoginWithPhone"><i
                class="fa fa-spinner fa-spin"></i>
            Chargement...</button>
        <div *ngIf="error!=''" class="text-danger fs-12 pt-2">
            {{error}}
        </div>
    </div>
</div>

<div *ngIf="stepLoginPhone == 'validation-code'" class="p-3">
    <div class="card mx-auto shadow-sm-none container p-md-5 my-5 bg-transparent" style="max-width: 400px;">
        <h5 class="fw-bold mb-4">CONNEXION</h5>
        <p class="mb-3">Connectez-vous avec votre <span class="text-danger">numéro de téléphone</span></p>
        <input [(ngModel)]="varificationCode" type="text" class="form-control border" placeholder="Code de validation">
        <button class="btn btn-danger text-uppercase" (click)="nextActionSignInWithPhone()" type="button"
            *ngIf="!loadingLoginWithPhone">valider</button>
        <button (click)="back()" type="button" class="btn btn-dark-gray w-100 text-uppercase mt-2"
            *ngIf="!loadingLoginWithPhone"><i class="fa fa-chevron-circle-left me-2 pointer"></i> Précédent</button>
        <button class="w-100 btn rounded btn-lg btn-secondary btn-outline-dark" *ngIf="loadingLoginWithPhone"><i
                class="fa fa-spinner fa-spin"></i>
            Chargement...</button>
        <div *ngIf="error!=''" class="text-danger fs-12 pt-2">
            {{error}}
        </div>
    </div>
</div>



<!-- Modal -->
<form [formGroup]="Form">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Mot de passe oublié ?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input (keyup.enter)="onSubmit('forgotPassword')"
                        [ngClass]="{'border-danger':f.email.errors && submitForgotPassword}" type="email"
                        class="form-control" formControlName="email" placeholder="Email">
                </div>
                <div class="modal-footer border-0">
                    <button (click)="onSubmit('forgotPassword')" type="button" class="btn btn-primary">Envoyer</button>
                </div>
            </div>
        </div>
    </div>
</form>


<button type="button" id="openCompteInactiveModal" data-bs-toggle="modal" data-bs-target="#compteInactivated"
    class="d-none"></button>

<div class="modal fade" id="compteInactivated" tabindex="-1" aria-labelledby="compteInactivatedLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0">
                <h2 class="modal-title mx-auto" id="compteInactivatedLabel">Error</h2>
                <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>Votre compte n'est pas activé, Veuillez vérifier votre boite de réception.</p>
                <a class="text-primary pointer" (click)="resendConfirmation()" data-bs-dismiss="modal"
                    aria-label="Close">je n'ai pas reçu l'émail d'activation</a>
            </div>
            <div class="modal-footer border-0">
                <button type="button" class="btn btn-primary mx-auto" data-bs-dismiss="modal"
                    aria-label="Close">Ok</button>
            </div>
        </div>
    </div>
</div>