import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Hour } from 'src/app/classes/hour.model';
import { Reservation } from 'src/app/classes/reservation.model';
import { Studio } from 'src/app/classes/studio.model';
import { Type } from 'src/app/classes/type.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { FormService } from 'src/app/services/form/form.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ReservationsService } from 'src/app/services/reservations/reservations.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { environment } from 'src/environments/environment';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { MapService } from 'src/app/services/map/map.service';
import { StudioService } from 'src/app/services/studio/studio.service';
import Swal from 'sweetalert2';

interface RangeSliderModel {
  minValue: number;
  maxValue: number;
  options: Options;
}

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.css']
})
export class BookingFormComponent implements OnInit {
  total: number;
  totalWitoutReduction: number;
  totalHours: number;
  @ViewChild('myElement', { static: false }) myElement: ElementRef;
  @ViewChild('ngTimeElement', { static: false }) ngTimeElement: ElementRef;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  Form: FormGroup;
  reservationForm: FormGroup;
  @Input() studio: Studio;
  @Input() type: Type;
  @Input() user: any;
  // emit reservation data to parent (booking conatiner)
  @Output() emitReservation = new EventEmitter<any>();
  @Output() emitAccountStatus = new EventEmitter<any>();
  //preparedObjectToUrl: any;
  targetedStep = "";
  formatted_address = ""
  place: any;
  // datepicker
  fullDay = true;
  toDay: NgbDate | null;
  firstDay: string;
  submitForm = false;
  error: string = "";
  hoursChoices = [];
  loading: boolean = true;
  ngTimePickerShow: boolean = false;
  selectedFromHour: any;
  // calendar
  horaireFirstDay: Array<Hour> = [];
  horaireSecondDay: Array<Hour> = [];
  secondDay: string;
  icalEvents: any;
  from: any;
  to: any;
  upToMin: boolean;
  tarif = 30;
  studioList = [];
  types_studio = [{key:'ENREGISTREMENT', label:'Studio d’enregistrement'}, {key:'PHOTOGRAPHIE', label:'Studio photo & vidéo'}];

  webflowOptions = [{key:"studio_fumeur_?",value:"Studio Fumeur ?"},{key:"studio_avec_internet_?",value:"Studio avec internet ?"},
  {key:"studio_avec_cabine_?",value:"Studio avec cabine ?"},{key:"studio_avec_salle_de_repos_?",value:"Studio avec salle de repos ?"},
  {key:"studio_avec_cuisine_?",value:"Studio avec cuisine ?"},{key:"prestation_-_enregistrement",value:"Prestation - Enregistrement"},
  {key:"prestation_-_mix",value:"Prestation - Mix"},{key:"prestation_-_mastering",value:"Prestation - Mastering"},
  {key:"prestation_-_prod_+_beatmaking",value:"Prestation - Prod + Beatmaking"},{key:"studio_avec_toilettes_?",value:"Studio avec toilettes ?"}];

  studioPartenaire: boolean = true;
  reservationEnAttente: boolean = false;
  preparedLink: any;
  unpaidReservation: any;

  placesOptions = {
    types: ['geocode'],// 'establishment' / 'address' / 'geocode'
    componentRestrictions: { country: 'FR' }
  };

  horairesSlider: RangeSliderModel = {
    minValue: 8,
    maxValue: 10,
    options: {
      floor: 6,
      ceil: 24,
      step: 2,
      showTicksValues: false
    }
  };

  distanceSlider: RangeSliderModel = {
    minValue: 0,
    maxValue: 10,
    options: {
      floor: 0,
      ceil: 30,
      step: 5,
      showTicksValues: true
    }
  };
  priceSlider: RangeSliderModel = {
    minValue: 0,
    maxValue: 200,
    options: {
      floor: 0,
      ceil: 200,
      step: 10,
      tickValueStep: 10,
      showTicksValues: false
    }
  };

  isStudioOwner = false;
  constructor(private tokenService: TokenStorageService, private stripeService: StripeService, private authservice: AuthService,
    private mapSrvice: MapService, private profileService: ProfileService, private paymentService: PaymentService,
    private router: Router, private reservationService: ReservationsService, private formService: FormService, private fb: FormBuilder,
    private route: ActivatedRoute, private calendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>, private studioService: StudioService) {
    this.Form = this.fb.group({
      complete_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      type: ['classic', Validators.required]
    });

    this.reservationForm = this.fb.group({
      hours_number: [1, Validators.required],
      day: ['', Validators.required],
      adresse: ['', Validators.required],
      studio_type: ['ENREGISTREMENT',  Validators.required],
      webflowOptions: [[]],
    });
    
    this.toDay = calendar.getToday();
    this.firstDay = this.dateAdapter.toModel(this.calendar.getToday());
    this.reservationForm.controls['day'].setValue(this.firstDay);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.preparedLink = { ...params };
    });

    this.studioPartenaire = (this.studio.user) ? true : false;
    if(!this.studioPartenaire) this.Form.controls['type'].setValue('rapid');
    this.isStudioOwner = this.studio.user && this.studio.user.id == this.tokenService.getUser().id;
    this.reservationForm.controls.hours_number.setValue((this.studio.minhours_day) ? this.studio.minhours_day : 1);
    this.route.queryParams.subscribe(params => {
      this.reservationForm.patchValue(params);
    })
    this.tarif = (this.studio && this.studio.types[0]) ? this.studio.types[0].tarif : 30;
    this.formatted_address = this.studio?.adresse || "";
    this.hoursChoices = Array(10).toString().split(',').map((e,i) => i);
    this.reservationService.get_reservations_by_user_id("etat=false&studio.id=" + this.preparedLink.studio).subscribe((res) => {
      this.unpaidReservation = [];
      if (res.length > 0) {
        this.error = "Vous avez déja une réservation rapide non traitée pour ce studio";
        this.unpaidReservation = res[0].id;
        this.reservationEnAttente = true;
      }
    }, err => {
      this.unpaidReservation = [];
    }).add(() => {
      this.loading = false;
      this.setIFrameHeight();
    })
  }

  goToReservation() {
    if (this.isIFrame()) {
      let link = environment.webflowURL + '/app?action=reservation-details&id=' + this.unpaidReservation;
      if (link != null && link != "") {
        // Open link in a new tab or window
        //parent.location.href = link;
        window.open(link, '_blank');
      }
    } else {
      this.router.navigate(['/artiste/reservation-details-artiste/' + this.unpaidReservation])
    }
  }

  ngAfterViewInit(): void {
    //this.setIFrameHeight();
  }

  nbHourEnabled(value) {
    return !this.studio?.minhours_day || value >= this.studio.minhours_day
  }

  emptyObject(user: any) {
    return Object.keys(user).length === 0;
  }

  nextStep(value: any) {
    console.log("=> call function reserver");
    this.error = "";
    this.submitForm = true;
    if (this.studioPartenaire && this.isClassic()) {
      if (!this.emptyObject(this.user)) {
        this.router.navigate([`/artiste/fiche-studio/${this.studio.id}`], { queryParams: this.preparedLink });
      } else {
        this.login(value);
      }
    } else {
      if (!this.emptyObject(this.user)) {
        if(!this.isSearchPaymentStep())
          this.suggestedStudios();
        else
          this.reserver(value);
      } else {
        this.signin(value);
      }
    }
  }

  reserver(value: any) {
    this.loading = true;
    let startdate = moment(moment(this.reservationForm.value.day, 'DD-MM-YYYY')).format('YYYY-MM-DD HH:mm');
    let endDate = moment(moment(this.reservationForm.value.day, 'DD-MM-YYYY')).add(this.Form.value.hours_number - 1, 'hours').format('YYYY-MM-DD HH:mm');
    let reservation = new Reservation(null, startdate, endDate, this.reservationForm.value.studio_type, null, this.tarif, null, null);
    this.reservationService.create_reservation(reservation, this.user.id).subscribe(res => {
      let studios = { "array": this.studioList.map(studio => ({ "contact": studio.id, "send_msg": false, "reservation": res.id })) };
      this.reservationService.contactStudio(studios).subscribe(studio => {
        this.paymentService.create_payment("", "", res.id, "", "Pré-Réservation").subscribe(p => {
          if (this.isIFrame()) {
            parent.location.href = environment.webflowURL + "/app?action=reservations-list&from=create";
          } else {
            this.router.navigate(['/artiste/reservations']);
          }
        }, err => {
          this.error = "Une érreur est survenue, Réservation non créée";
          this.loading = false;
          this.setIFrameHeight();
        });
      }, error => {
        Swal.fire("Attention", "Une érreur est survenue", "error");
        return;
      });
    }, err => {
      console.log("Une érreur est survenue, Réservation non créée")
      this.error = "Une érreur est survenue, Réservation non créée";
      this.loading = false;
      this.setIFrameHeight();
    });
  }
  
  signin(value: any) {
    
    this.loading = true;
    this.profileService.getByEmail({ email: value.email, phone: value.phone, check: true }).subscribe(available => {

      if (available.foundLoginPhone) {
        console.log("=> foundLoginPhone");

        this.authservice.send_phone_confirmation(value.phone).subscribe(res => {
          this.targetedStep = 'validSms';
          this.loading = false;
          this.preparedLink["step"] = "validSms";
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { ...this.preparedLink, ...value },
            queryParamsHandling: 'merge'
          }).then(() => {
            this.emitAccountStatus.emit({ ...this.preparedLink, ...value });
          });
        }, err => {
          this.loading = false;
          this.error = "Numéro de téléphone invalid.";
        });
        return
      }
      if (available.foundUser) {
        console.log("=> foundUser");
        this.loading = false;
        this.targetedStep = 'booking';
        this.preparedLink["step"] = "booking";
        this.preparedLink["return"] = environment.webflowURL + "/studios/" + this.preparedLink["slug"];

        let allParams = { ...this.preparedLink, ...value };
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: allParams,
          queryParamsHandling: 'merge'
        }).then(() => {
          this.router.navigate(['/connexion'], { queryParams: allParams });
        });
        this.loading = false;
        return
      }

      if ((available.foundPhone && !available.foundLoginPhone && !available.foundUser) || (!available.foundLoginPhone && !available.foundUser && !available.foundPhone)) {
        console.log("=> not found any data");
        this.authservice.register_with_phone(value.phone, value.email, '', 'phone').subscribe(res => {
          this.profileService.create_profile(res.user.id, value.email, value.complete_name, value.phone, "", "", "", "", "false").subscribe(profile => {
            this.stripeService.create_customer(res.user.id, value.email, value.complete_name).subscribe(token => {
              this.authservice.send_phone_confirmation(value.phone).subscribe(res => {
                this.targetedStep = 'validSms';
                this.preparedLink["step"] = "validSms";
                this.loading = false;
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: { ...this.preparedLink, ...value },
                  queryParamsHandling: 'merge'
                }).then(() => {
                  this.emitAccountStatus.emit('validSms');
                });

              }, err => {
                this.loading = false;
                this.error = "Numéro de téléphone invalid. ";
              })
            })
          })
        }, err => {
          this.loading = false;
          this.error = err.error?.message?.map(e => e.messages && e.messages[0] ? e.messages[0].message : '').join('; ') || 'Veuillez Vérifier vos informations';
        })
        return
      }
    });
  }

  login(value: any) {
    this.loading = true;
    this.profileService.getByEmail({ email: value.email, phone: value.phone, check: true }).subscribe(available => {

      if (available.foundLoginPhone) {
        this.authservice.send_phone_confirmation(value.phone).subscribe(res => {
          this.targetedStep = 'validSms';

          this.preparedLink["step"] = "validSms";
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { ...this.preparedLink, ...value },
            queryParamsHandling: 'merge'
          }).then(() => {
            this.emitAccountStatus.emit({ ...this.preparedLink, ...value });
          });
        }, err => {
          this.loading = false;
          this.error = "Numéro de téléphone invalid";
        });
        return
      }
      if (available.foundUser) {
        this.targetedStep = 'booking';
        this.preparedLink["step"] = "booking";


        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { ...this.preparedLink, ...value },
          queryParamsHandling: 'merge'
        }).then(() => {


          this.router.navigate(['/connexion'], { queryParams: { ...this.preparedLink, ...value } });
        });
        this.loading = false;
        return
      }

      if ((available.foundPhone && !available.foundLoginPhone && !available.foundUser) || (!available.foundLoginPhone && !available.foundUser && !available.foundPhone)) {
        this.authservice.register_with_phone(value.phone, value.email, '', 'phone').subscribe(res => {
          this.profileService.create_profile(res.user.id, value.email, value.complete_name, value.phone, "", "", "", "", "false").subscribe(profile => {
            this.stripeService.create_customer(res.user.id, value.email, value.complete_name).subscribe(token => {
              this.authservice.send_phone_confirmation(value.phone).subscribe(res => {
                this.targetedStep = 'validSms';
                this.preparedLink["step"] = "validSms";
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: { ...this.preparedLink, ...value },
                  queryParamsHandling: 'merge'
                }).then(() => {
                  this.emitAccountStatus.emit('validSms');
                });

              }, err => {
                this.loading = false;
                this.error = "Numéro de téléphone invalid";
              })
            })
          })
        })
        return
      }
    });
  }

  async suggestedStudios() {
    if (this.reservationForm.invalid) {
      this.error = "Veuillez Vérifier vos informations";
      return
    }
    this.loading = true;
    this.studioList = [];
    let webFlowOptions = {};
    let lat = this.place.geometry.location.lat(), lng = this.place.geometry.location.lng();
    let startDate = moment(this.reservationForm.value.day, 'DD-MM-YYYY').set({hour: this.horairesSlider.minValue, minute:0, second:0, millisecond:0}).format('YYYY-MM-DD HH:mm');
    let endDate = moment(this.reservationForm.value.day, 'DD-MM-YYYY').set({hour: this.horairesSlider.maxValue, minute:0, second:0, millisecond:0}).format('YYYY-MM-DD HH:mm');
    let webFlowParams = Object.keys(webFlowOptions)
      .filter((key) => webFlowOptions[key] === true)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(webFlowOptions[key])}`)
      .join('&');
    try {
      this.studioService.get_studio_by_filter(
        this.reservationForm.value.studio_type,
        lat, lng,
        this.distanceSlider.minValue,
        this.distanceSlider.maxValue,
        this.priceSlider.minValue,
        this.tarif, '', '', startDate, endDate).subscribe(res => {
          this.studioList = res.slice(0, 10);
          this.loading = false;
          parent.postMessage(JSON.stringify({studios: this.studioList}), environment.webflowURL);
          this.setIFrameHeight(100);
        });

    } catch (err) {
      console.error(err);
    }
  }

  clearStudios() {
    this.studioList=[];
    parent.postMessage(JSON.stringify({studios: this.studioList}), environment.webflowURL);
    this.setIFrameHeight();
  }

  setIFrameHeight(addNumber?: number) {
    if (this.isIFrame()) {
      setTimeout(() => {
        const elementHeight = (this.myElement.nativeElement.offsetHeight + 300 + ((addNumber) ? addNumber : 0)) || document.body.scrollHeight;
        parent.postMessage(JSON.stringify({ iframeHeight: elementHeight, userData: this.user, bookingStep: 'booking' }), environment.webflowURL);
      }, 200);
    } else {
      if (typeof parent !== 'undefined')
        parent.location.href = environment.webflowURL + "/studios/" + this.preparedLink["slug"];
    }
  }

  isClassic() {
    return this.Form.value.type === 'classic';
  }

  isSearchPaymentStep() {
    return !this.loading && !this.isClassic() && !this.emptyObject(this.user) && this.studioList.length
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

  ngTimePicker() {
    this.ngTimePickerShow = !this.ngTimePickerShow;
    setTimeout(() => this.setIFrameHeight(), 10);
  }

  showDatePicker(d2) {
    this.ngTimePickerShow = false;
    d2.toggle();
    this.setIFrameHeight(60);
  }

  handleAddressChange(place: any) {
    this.formatted_address = place.formatted_address;
    this.place = place;
  }

  currentPosition() {
    this.mapSrvice.getMyLocation().subscribe(location => {
      const params = { lat: location.coords.latitude, lng: location.coords.longitude };
      console.log(location);
      //this.formatted_address = location.formatted_address;
    }, err => {
      // paris by default
      Swal.fire('Veuillez activer la position de votre navigateur', "", 'info');
    });
  }

  totalPrice() {
    let maxPrice = this.maxPrice();
    return ((maxPrice + ((maxPrice * 0.15 + 10) * 1.20)) * this.reservationForm.value.hours_number).toFixed(2);
  }

  maxPrice() {
    let maxPrice = this.tarif;
    if(this.studioList && this.studioList.length > 0) {
      let prices = this.studioList?.map(s => s.types?.find(t => t.name = this.reservationForm.value.studio_type)?.tarif);
      if(prices)
        maxPrice = prices?.reduce((acc, val) => {
          return acc > val ? acc : val;
        });
    }
    return maxPrice;
  }

  syncHoraireStep() {
    this.horairesSlider.options.step = this.reservationForm.controls.hours_number.value * 1;
    this.horairesSlider.maxValue = Math.max((this.reservationForm.controls.hours_number.value * 1) + this.horairesSlider.minValue, this.horairesSlider.maxValue);
  }

  passParamsToUrl() {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.preparedLink,
      queryParamsHandling: 'merge',
      // do not trigger navigation
    });
  }

}
