import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import Swal from 'sweetalert2';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormService } from 'src/app/services/form/form.service';
import { ParrainageService } from 'src/app/services/parrainage/parrainage.service';
import { Title } from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/auth/user.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.css']
})
export class InscriptionComponent implements OnInit {
  Form: FormGroup;
  FormSocial: FormGroup;
  submitted = false;
  path = "";
  codeParrainage: any;
  step = 0;
  params: any = {};
  modeInscription: string = "";
  type_service = environment.freelancePrestations;

  stepLoginPhone: string = "send-code";
  phoneNumber: string = "";
  fullName: string = "";
  email: string = "";
  varificationCode: string = "";
  typeUser: string = "";
  loadingRegisterWithPhone: boolean = false;


  //google place (paris france by default)
  options = {
    types: [],
    componentRestrictions: { country: 'FR' }
  };
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    var addressComponents = address;
    this.getAdress(addressComponents);
  }
  constructor(private userService: UserService, private authservice: AuthService, private location: Location, private tokenService: TokenStorageService, private sauthService: SocialAuthService, private parrainageService: ParrainageService, private route: ActivatedRoute, private formService: FormService, private spinner: NgxSpinnerService, private emitterService: EmitterService, private fb: FormBuilder, private profileService: ProfileService, private authService: AuthService, private title: Title, private router: Router, private stripeService: StripeService) {
    this.Form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      adresse: [''],
      type_service: [this.type_service[0].name],
      lat: [''],
      lng: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      type: ['artiste', Validators.required],
      full_name: ['', Validators.required],
      phone: ['', Validators.required],
      cgu: [false],
      csm: [false],
      all_city: [false],
      indicatif: ['+33', Validators.required]
    }, { validators: this.checkPasswords });

    this.FormSocial = this.fb.group({
      adresse: [''],
      phone: ['', Validators.required],
      cgu: [false],
      csm: [false]
    })

  }
  //validator submit
  get f() { return this.Form.controls; }

  get fSocial() { return this.FormSocial.controls; }

  ngOnInit(): void {
    this.emitterService.mode.emit("light mode")
    // meta & title
    this.title.setTitle("Studiomap : Inscription");

    this.route.queryParams.subscribe(res => {
      this.codeParrainage = res.code;
      this.params = res;
      this.step = (this.params.step) ? this.params.step : 0;
      this.modeInscription = (this.params.modeInscription) ? this.params.modeInscription : "email";
      let type = (this.params.typeUser) ? this.params.typeUser : "artiste";
      this.typeUser = (this.params.typeUser) ? this.params.typeUser : "artiste";
      this.formService.setControlValue(this.Form, 'type', type);
    });
  }

  nextStep(typeInscription: string, typeUSer?: string) {
    if (this.step == 0) {

      this.router.navigate(['/inscription'], { queryParams: { step: 1, modeInscription: typeInscription } });
    } else if (this.step == 1) {

      this.router.navigate(['/inscription'], { queryParams: { step: 2, modeInscription: typeInscription, typeUser: typeUSer } });
    }
  }

  async onSubmitSocial() {

    this.submitted = true;
    if (this.FormSocial.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
      return;
    }
    this.spinner.show();
    let data = JSON.parse(localStorage.getItem('signInWithSocialResponse'));
    let user = JSON.parse(localStorage.getItem('socialUser'));

    let avatar: any;

    await fetch(user.photoUrl, { mode: "cors" })
      .then((e) => {
        return e.blob()
      })
      .then((blob) => {
        let b: any = blob
        b.lastModifiedDate = new Date()
        b.name = 'avatar'
        avatar = b;
      });

    this.profileService.create_profile(data.user.id, user.email, user.name, this.FormSocial.value.phone, "", "", "", "", "false").subscribe(profile => {

      this.profileService.update_profile(profile.id, null, avatar, data["jwt"]).subscribe(res => {
      })
      this.userService.update(data.user.id, this.typeUser, data["jwt"]).subscribe(updatedUser => {
      })
      this.stripeService.create_customer(data.user.id, user.email, user.name).subscribe(token => {

        let generated_social_user = {
          "id": data.user.id,
          "email": user.email,
          "username": user.email,
          "stripe": {
            "customer_id": token.customer_id,
          },
          "type": (this.typeUser == "freelance") ? "artiste" : this.typeUser,
          "profile": {
            "all_city": false,
            "id": profile.id,
            "first_name": user.firstName,
            "last_name": user.lastName,
            "user": data["user"]["id"],
            "full_name": user.name,
            "phone": transformPhoneNumber(this.FormSocial.value.phone),
            "avatar": {
              "url": user.photoUrl,
            },
          }
        }

        this.tokenService.saveToken(data["jwt"]);
        this.tokenService.saveUser(generated_social_user);
        this.emitterService.getLoggedUser.emit(generated_social_user);
        localStorage.setItem('type', this.typeUser)
        if (this.typeUser == 'gerant' || this.typeUser == 'freelance') {
          localStorage.setItem('current_type', this.typeUser);
        }
        this.userService.getMe(data["jwt"]).subscribe((userData) => {

          if (parent) {
            parent.postMessage(JSON.stringify({ userData: userData }), environment.webflowURL);
          }


          data.user["type"] = this.typeUser;
          //Swal.fire('Félicitation', 'Votre nouveau compte a été créé avec succès', 'success');
          this.removeStorage();
          this.reditrection(data);

        });

      });

    }).add(() => {
      this.spinner.hide();
    })


  }

  removeStorage() {
    localStorage.removeItem('providedUser');
    localStorage.removeItem('signInWithSocialResponse');
  }


  onSubmit() {

    console.log("=> on submit inscription");

    this.submitted = true;
    if (this.Form.value.type == 'freelance') {
      this.formService.requireControl(this.Form, 'adresse');
    } else {
      this.formService.facultatifControl(this.Form, 'adresse');
      this.formService.emptyControl(this.Form, 'type_service');
      this.formService.emptyControl(this.Form, 'adresse');
      this.formService.setControlValue(this.Form, 'all_city', null);
    }

    if (this.Form.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
      return;
    }
    this.spinner.show();
    this.authService.register(this.Form.value.email, this.Form.value.email, this.Form.value.password, this.Form.value.type).subscribe(res => {
      // parrainage code if exist
      if (this.codeParrainage) {
        this.parrainageService.create(res.user.id, this.codeParrainage).subscribe(parrainage => {
        }, err => {
        })
      }
      this.profileService.create_profile(res.user.id, this.Form.value.email, this.Form.value.full_name, this.Form.value.phone, this.Form.value.adresse, this.Form.value.lng, this.Form.value.lat, this.Form.value.type_service, this.Form.value.all_city).subscribe(profile => {
        this.stripeService.create_customer(res.user.id, this.Form.value.email, this.Form.value.full_name).subscribe()
        res.user['profile'] = profile;
        Swal.fire('Félicitation', 'Votre nouveau compte a été créé avec succès, Veuillez vérifier votre couriel pour l\'activation de votre compte', 'success');
        this.router.navigate(["/connexion"]);

        this.spinner.hide();
      }, err => {
        console.log(err);
      })
    }, err => {
      console.log(err);
      if (err.error.message[0].messages[0].message == "Email is already taken.") {
        Swal.fire('Erreur', 'Adresse email déjà utilisée', 'error')
      }
      this.spinner.hide();
    })
  }

  select() {
    this.Form.controls["type_service"].setValue(this.type_service[0].name);
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { notSame: true }
  }

  getAdress(addressComponents) {
    this.Form.patchValue({
      lat: addressComponents.geometry.location.lat().toString(),
      lng: addressComponents.geometry.location.lng().toString(),
      adresse: addressComponents.formatted_address
    });
  }

  signInWithGoogle(): void {
    this.sauthService.signOut();
    this.sauthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: any) => {
      this.checkIfAccountExist('google', user)
    });
  }

  signInWithFB(): void {
    this.sauthService.signOut();
    this.sauthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: any) => {
      this.checkIfAccountExist('facebook', user)
    });
  }

  checkIfAccountExist(provider: string, user: any) {
    this.authService.signInWithSocial(provider, user.authToken).subscribe((data: any) => {
      this.profileService.find("user=" + data["user"]["id"], data["jwt"]).subscribe(res => {
        if (res.length == 0) {
          localStorage.setItem('socialUser', JSON.stringify(user));

          localStorage.setItem('signInWithSocialResponse', JSON.stringify(data));
          this.router.navigate(['/choose-user-type']);
        } else {
          Swal.fire("Attention", "l'adresse email est déjà utilisée !!", 'info');
        }
      })


    }, err => {
      if (err.error.statusCode) {
        if (provider == "google") {
          try {
            this.sauthService.signOut();
          } catch (ex) { }
        }
        Swal.fire("Échec de la connexion", "Veuillez utiliser un autre mode de connexion", 'info');
      }
    })

  }

  back() {
    this.location.back();
  }

  sendCodeConfirmation() {
    this.phoneNumber = transformPhoneNumber(this.phoneNumber);

    if (this.phoneNumber != "" && this.email != "" && this.fullName != "") {
      this.loadingRegisterWithPhone = true;
      this.profileService.getByEmail({ email: "", phone: this.phoneNumber, check: true }).subscribe(available => {
        if (available.foundUser) {
          Swal.fire('Erreur', "un utilisateur déja existe avec ces informations", 'error');
          this.loadingRegisterWithPhone = false;
        } else if(available.foundLoginPhone){
          Swal.fire('Erreur', "un utilisateur déja existe avec cette numéro de téléphone", 'error');
          this.loadingRegisterWithPhone = false;
        }else{

          this.authService.register(this.phoneNumber, this.email, "", this.typeUser, "phone").subscribe(res => {
            this.profileService.create_profile(res.user.id, this.email, this.fullName, this.phoneNumber, "", "", "", "", "false").subscribe(profile => {
              this.stripeService.create_customer(res.user.id, this.email, this.fullName,).subscribe(token => {
                this.authService.send_phone_confirmation(this.phoneNumber).subscribe(res => {
                  this.loadingRegisterWithPhone = false;
                  this.nextActionSignInWithPhone();
                }, err => {
                  
                  this.loadingRegisterWithPhone = false;
                  Swal.fire('Erreur', "numéro de téléphone invalid", 'error');
                })
              })
            })

          }, err => {
            
            this.loadingRegisterWithPhone = false;
            if (err.error.message[0].messages[0].message == "Email is already taken." || err.error.message[0].messages[0].message == "Email already taken") {
              Swal.fire('Erreur', 'Adresse email déjà utilisée', 'error');
            } else {
              Swal.fire('Erreur', "numéro de téléphone invalid", 'error');
            }

          });

        }
      }, err => {

        Swal.fire('Erreur', "une érreur est survenu", 'error');
        this.loadingRegisterWithPhone = false;
      });
    } else {
      this.loadingRegisterWithPhone = false;
      Swal.fire('Erreur', "veuillez remplir les champs vide", 'error');
    }
  }



  nextActionSignInWithPhone(): void {
    this.phoneNumber = transformPhoneNumber(this.phoneNumber);
    if (this.stepLoginPhone == "send-code") {
      this.router.navigate(['/connexion'], { queryParams: { step_phone: "validation-code", phone: this.phoneNumber } });
    } else if (this.stepLoginPhone == "validation-code") {
      this.loadingRegisterWithPhone = true;

      this.authservice.valide_account_by_sms(this.phoneNumber, this.varificationCode).subscribe(res => {
        this.loadingRegisterWithPhone = false;
        let user = res.user;
        let generated_social_user = {
          "id": user.id,
          "email": user.email,
          "username": user.profile.full_name,
          "stripe": {
            "customer_id": user.stripe.customer_id,
          },
          "type": "artiste",
          "profile": {
            "all_city": false,
            "id": user.profile.id,
            "first_name": "",
            "last_name": "",
            "user": user.id,
            "full_name": user.profile.full_name,
            "phone": transformPhoneNumber(user.profile.phone),
          }
        }
        this.tokenService.saveToken(res["jwt"]);
        this.tokenService.saveUser(generated_social_user);
        this.emitterService.getLoggedUser.emit(generated_social_user);
        localStorage.setItem('type', "artiste");
        localStorage.setItem('current_type', "artiste");

        this.reditrection(res);
      }, err => {

        Swal.fire('Erreur', "code invalide !", 'error');
        this.loadingRegisterWithPhone = false;
      });
    } else {
      this.router.navigate(['/connexion'], { queryParams: { step_phone: "send-code" } });
    }
  }

  reditrection(data: any) {

    localStorage.setItem('type', data.user.type);

    if (data.user.type == 'gerant' || data.user.type == 'freelance') {
      localStorage.setItem('current_type', data.user.type);
    }
    switch (data.user.type) {
      case "artiste":
        this.path = "/" + data.user.type + "/reserver-studio";
        break;
      case "gerant":
        this.path = "/" + data.user.type + "/tableau-de-bord";
        break;
      case "freelance":
        this.path = "/" + data.user.type + "/tableau-de-bord";
        break;
    }


    this.router.navigate([this.path]);

  }

  objectToParams(obj: { [key: string]: any }): string {
    const params = new URLSearchParams();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        params.append(key, value.toString());
      }
    }

    return params.toString();
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

}


function transformPhoneNumber(phone){
  return "0" + String(phone * 1);
}