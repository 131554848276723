import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.css']
})
export class ShareButtonsComponent implements OnInit {
  @Input() description: string;
  @Input() url: string;
  constructor(private token: TokenStorageService) { }

  ngOnInit(): void {

  }

}
