<div class="col-md-8 mx-auto text-start container pb-5">
    <div class="py-lg-5">
        <i (click)="back()" class="fa fa-chevron-left mb-5 pointer float-start me-2"></i>
        <h5>INSCRIPTION</h5>
        <form [formGroup]="Form" (ngSubmit)="onSubmit()" class="col-md-8 mx-auto">
            <div class="row g-3">
                <div *ngIf="Form.value.type=='freelance'">
                    <div class="col-md-6">
                        <label for="passe" class="form-label">Type de service</label>
                        <select formControlName="type_service" class="form-select" required>
                            <option *ngFor='let service of type_service' [value]="service.name">{{service.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-12">
                        <label for="email" class="form-label">Adresse</label>
                        <input [ngClass]="{'border-danger':f.adresse.errors && submitted}" type="text"
                            class="form-control" formControlName="adresse" placeholder="Adresse" #placesRef
                            ngx-google-places-autocomplete [options]='options'
                            (onAddressChange)="handleAddressChange($event)" placeholder="Entrer adresse">
                    </div>
                    <div class="col-md-12 mt-3">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" formControlName="all_city">
                            <label for="">Disponible partout en France</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <label for="country" class="form-label">Numéro de téléphone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-phone" style="padding: 0.7rem 0.75rem;">+33</span>
                        </div>
                        <input type="text" class="form-control col-8" mask="(00) 00000000||000000000" formControlName="phone"
                            placeholder="Téléphone" [ngClass]="{'border-danger':f.phone.errors && submitted}"
                            formControlName="phone">
                    </div>
                </div>
            </div>
            <div class="form-check mt-2">
                <input formControlName="cgu" type="checkbox" class="form-check-input">
                <label class="form-check-label link">Je reconnais avoir lu et accepte <a routerLink="/cgu"
                        class="text-primary"> les Conditions Générales d'Utilisation.</a></label>
            </div>
            <div class="col-md-4 mt-4">
                <button [disabled]="!Form.value.cgu" class="btn btn-danger w-100" type="submit">CONTINUER</button>
            </div>
        </form>
    </div>
</div>