import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { FormService } from 'src/app/services/form/form.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ReservationsService } from 'src/app/services/reservations/reservations.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.css']
})
export class ConnexionComponent implements OnInit {

  step = 0;
  initialized = false;
  Form: FormGroup;
  path = "";
  submitted = false;
  submitForgotPassword = false;
  reservationLink: any;
  paramsObject: any = {};
  stepLoginPhone: string = "";
  phoneNumber: string = "";
  varificationCode: string = "";
  loadingLoginWithPhone: boolean = false;
  error: string = "";
  fromBooking: boolean = true;
  emailConfirmation: boolean = false;


  constructor(private spinner: NgxSpinnerService,
    private authservice: AuthService,
    private location: Location,
    private formService: FormService,
    private tokenService: TokenStorageService,
    private title: Title,
    private reservationService: ReservationsService,
    private emitterService: EmitterService,
    public router: Router, public route: ActivatedRoute, private fb: FormBuilder,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private profileService: ProfileService,
    private sauthService: SocialAuthService) {
    this.Form = this.fb.group({
      identifier: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      email: [''],
    })
  }

  ngOnInit(): void {
    this.initialized = true;
    this.emitterService.mode.emit("light mode");
    // meta & title
    this.title.setTitle("Studiomap : Connexion");

    this.route.queryParams.subscribe(params => {
      this.stepLoginPhone = (params.step_phone) ? params.step_phone : "";
      this.phoneNumber = (params.phone) ? params.phone : "";
      this.Form.controls.identifier.setValue((params.email) ? params.email : "");

      this.paramsObject = (params.return) ? params : {};
      let linkData: string = this.route.snapshot['_routerState'].url;

      //if url is defined we set the redirection url (only remove else)
      if (params.url != '')
        this.reservationLink = params.url;

      if (params.studio) {
        this.reservationLink = linkData.replace("/connexion", "artiste/reserver/" + params.studio);
      } else
        if (params.hours_number && params.day) {
          const objString = '?' + Object.keys(params).map(key => {
            return `${key}=${encodeURIComponent(params[key])}`;
          }).join('&');
          this.reservationLink = linkData.replace("/connexion", "booking-container" + objString);
          this.fromBooking = true;
        } else {
          this.fromBooking = false;
        }
    })
  }

  ngAfterViewInit(): void {
    this.setIFrameHeight();
  }

  get f() {
    return this.Form.controls;
  }

  back() {
    this.location.back();
    this.fromBooking = true;
  }

  sendCodeConfirmation() {
    this.error = "";
    if (this.phoneNumber != "") {
      this.loadingLoginWithPhone = true;
      this.profileService.getByEmail({ email: "", phone: this.phoneNumber, check: true }).subscribe(available => {
        if (available.foundLoginPhone) {
          this.authService.send_phone_confirmation(this.phoneNumber).subscribe(res => {
            this.loadingLoginWithPhone = false;
            this.nextActionSignInWithPhone();
          }, err => {
            this.loadingLoginWithPhone = false;
            this.error = "numéro de téléphone invalid";
          });
        } else {
          this.error = "vous n'êtes pas inscrit";
          this.loadingLoginWithPhone = false;
        }
      }, err => {
        this.error = "une érreur est survenu";
        this.loadingLoginWithPhone = false;
      });
    } else {
      this.loadingLoginWithPhone = false;
      this.error = "veuillez saisir votre numéro de téléphone";
    }
  }

  nextActionSignInWithPhone(): void {
    this.error = "";
    if (this.stepLoginPhone == "send-code") {
      this.router.navigate(['/connexion'], { queryParams: { step_phone: "validation-code", phone: this.phoneNumber, url: this.reservationLink } });
    } else if (this.stepLoginPhone == "validation-code") {
      this.loadingLoginWithPhone = true;

      this.authservice.valide_account_by_sms(this.phoneNumber, this.varificationCode).subscribe(res => {
        this.loadingLoginWithPhone = false;
        this.tokenService.saveToken(res["jwt"]);
        this.tokenService.saveUser(res.user);
        this.emitterService.getLoggedUser.emit(res.user);
        localStorage.setItem('type', "artiste");
        localStorage.setItem('current_type', "artiste");

        if(this.isIFrame())
         parent.postMessage(JSON.stringify({ userData: res.user }), environment.webflowURL);

        this.reditrection(res);
      }, err => {

        this.error = "code invalide !";
        this.loadingLoginWithPhone = false;
      });
    } else {
      this.router.navigate(['/connexion'], { queryParams: { step_phone: "send-code", url: this.reservationLink } });
    }
  }

  signInWithGoogle(): void {
    this.sauthService.signOut();
    this.sauthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: any) => {
      this.checkIfAccountExist('google', user)
    });
  }

  signInWithFB(): void {
    this.sauthService.signOut();
    this.sauthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: any) => {
      this.checkIfAccountExist('facebook', user)
    });
  }

  checkIfAccountExist(provider: string, user: any) {
    this.authService.signInWithSocial(provider, user.authToken).subscribe((data: any) => {
      this.profileService.find("user=" + data["user"]["id"], data["jwt"]).subscribe(res => {
        if (res.length == 0) {

          if ("return" in this.paramsObject) {
            this.error = "Échec de la connexion, utilisateur introuvable";
            //Swal.fire("Échec de la connexion", "Utilisateur introuvable", 'info');
          } else {
            localStorage.setItem('socialUser', JSON.stringify(user));
            localStorage.setItem('signInWithSocialResponse', JSON.stringify(data));
            //inscription?step=1&modeInscription=email
            this.router.navigate(['/inscription'], { queryParams: { "step": 1, "modeInscription": "social" } });

          }

        } else {
          this.tokenService.saveToken(data["jwt"]);
          localStorage.setItem('type', data.user.type);
          if (data.user.type == 'gerant' || data.user.type == 'freelance') {
            localStorage.setItem('current_type', data.user.type);
          }
          this.reditrection(data);
          let generated_social_user: any = {}
          generated_social_user = data.user;
          if (data.user.type == "freelance") {
            generated_social_user.type = "artiste";
          }
          if (!data.user.stripe) {
            generated_social_user.stripe = { customer_id: "" };
          }
          generated_social_user.profile = res[0];
          this.tokenService.saveUser(generated_social_user);
          this.emitterService.getLoggedUser.emit(generated_social_user);

          if(this.isIFrame())
            parent.postMessage(JSON.stringify({ userData: data.user }), environment.webflowURL);

        }
      })


    }, err => {
      if (err.error.statusCode) {
        if (provider == "google") {
          try {
            this.sauthService.signOut();
          } catch (ex) { }
        }
        if ("return" in this.paramsObject) {
          this.error = "Échec de la connexion, Veuillez utiliser un autre mode de connexion"
        } else {
          Swal.fire("Échec de la connexion", "Veuillez utiliser un autre mode de connexion", 'info');
        }

      }
    })

  }

  async reditrection(data: any) {
    localStorage.setItem('type', data.user.type);


    if ("return" in this.paramsObject) {
      if (data.user.type == 'gerant' || data.user.type == 'freelance') {
        localStorage.setItem('current_type', "artiste");
      }

      let params_return = this.paramsObject.return;
      let paramsToSend = this.paramsObject;

      //delete paramsToSend.return;
      delete paramsToSend.action;
      const queryString = this.objectToParams(paramsToSend);


      this.router.navigate(['/booking-container'], { queryParams: paramsToSend });

      /*
      if (this.isIFrame()) {

        console.log("=> nanigate after login");
        console.log(params_return + "?" + queryString);
        
        
        parent.location.href = params_return + "?" + queryString;
      } else {
        this.router.navigate(['/booking-container'], { queryParams: paramsToSend });
      }
      */

    } else {
      if (this.reservationLink) {
        if (data.user.type == 'gerant' || data.user.type == 'freelance') {
          localStorage.setItem('current_type', "artiste");
        }
        this.router.navigateByUrl(this.reservationLink);
      } else {
        if (data.user.type == 'gerant' || data.user.type == 'freelance') {
          localStorage.setItem('current_type', data.user.type);
        }
        switch (data.user.type) {
          case "artiste":
            this.path = "/" + data.user.type + "/reserver-studio";
            break;
          case "gerant":
            this.path = "/" + data.user.type + "/tableau-de-bord";
            break;
          case "freelance":
            this.path = "/" + data.user.type + "/tableau-de-bord";
            break;
        }
        this.router.navigate([this.path]);
      }
    }


  }

  onSubmit(value: string): void {
    this.error = "";
    if (value == 'connexion') {
      this.submitted = true;
      if (this.Form.invalid) {
        if (this.isIFrame()) {
          this.error = 'Veuillez vérifier vos informations'
        } else {
          Swal.fire('Error', 'Veuillez vérifier vos informations', 'error')
        }
        return
      }
      if (this.isIFrame()) {
        this.loadingLoginWithPhone = true;
      } else {
        this.spinner.show();
      }
      this.authService.login(this.Form.value.identifier, this.Form.value.password).subscribe(
        data => {
          let encapsulatedUser: any = {}
          encapsulatedUser = data.user;
          if (data.user.type == "freelance") {
            encapsulatedUser.type = "artiste";
          }
          if (!data.user.stripe) {
            encapsulatedUser.stripe = { customer_id: "" };
          }
          if (data.user.profile != null) {
            this.tokenStorage.saveToken(data.jwt);
            this.profileService.get_profile_by_id(data.user.profile.id).subscribe(profile => {
              encapsulatedUser.profile = profile;
              this.emitterService.getLoggedUser.emit(encapsulatedUser);
              this.tokenStorage.saveUser(encapsulatedUser);
              this.reservationService.salary_bilan(data.user.id).then((team: any) => {
                (team.length > 0) ? this.emitterService.menu_has_salary_bilan.emit(true) : this.emitterService.menu_has_salary_bilan.emit(false);
              })
              this.reservationService.prestations_assigned_to_gerant(data.user.id).then((team: any) => {
                (team.length > 0) ? this.emitterService.menu_has_calendar_prestation.emit(true) : this.emitterService.menu_has_calendar_prestation.emit(false);
              })
              this.reditrection(data);
            })

            // update webflow website
            if (parent) {
              console.log("=> start postMessage");

              parent.postMessage(JSON.stringify({ userData: data.user }), environment.webflowURL);
              console.log("=> end postMessage");
            }

          } else {
            if (this.isIFrame()) {
              this.error = "Vous n'avez pas encore terminer votre inscription " + (data.user.provider != "local") ? '(' + data.user.provider + ')' : '' + ". <br>Cliquer sur le bouton continuer pour compléter votre inscription";
            } else {
              Swal.fire({
                title: 'Attention',
                text: "Vous n'avez pas encore terminer votre inscription " + (data.user.provider != "local") ? '(' + data.user.provider + ')' : '' + ". \nCliquer sur le bouton continuer pour compléter votre inscription",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: `Continuer`,
                cancelButtonText: `Annuler`,
                cancelButtonColor: 'red'
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.router.navigate(["/inscription-type"]);
                }
              })
            }
          }

          if (this.isIFrame()) {
            this.loadingLoginWithPhone = false;
          } else {
            this.spinner.hide();
          }
        },
        err => {
          if (this.isIFrame()) {
            this.loadingLoginWithPhone = false;
          } else {
            this.spinner.hide();
          }
          if (err.error.data[0].messages[0].id == 'Auth.form.error.invalid') {
            if (this.isIFrame()) {
              this.error = 'Email ou mot de passe invalide';
            } else {
              Swal.fire('Error', 'Email ou mot de passe invalide', 'error')
            }
          }
          else if (err.error.data[0].messages[0].id == 'Auth.form.error.password.local') {
            if (this.isIFrame()) {
              this.error = 'Veuillez utiliser un autre mode de connexion';
            } else {
              Swal.fire("Échec de la connexion", "Veuillez utiliser un autre mode de connexion", 'info');
            }
          }
          else if (err.error.data[0].messages[0].id == 'Auth.form.error.confirmed') {
            if (this.isIFrame()) {
              this.error = "Votre compte n'est pas activé, Veuillez vérifier votre boite de réception.";
              this.emailConfirmation = true;
            } else {
              document.getElementById('openCompteInactiveModal').click();
            }
          }
          else if (err.error.data[0].messages[0].id == 'Auth.form.error.blocked') {
            if (this.isIFrame()) {
              this.error = 'Votre compte est bloqué';
            } else {
              Swal.fire('Error', 'Votre compte est bloqué', 'error');
            }
          }
          else {
            if (this.isIFrame()) {
              this.error = 'Email ou mot de passe invalide';
            } else {
              Swal.fire('Error', 'Email ou mot de passe invalide', 'error');
            }
          }
        }
      );
    }


    if (value == 'forgotPassword') {
      this.submitForgotPassword = true;
      this.formService.requireControl(this.Form, 'email');
      if (this.Form.controls['email'].invalid) {
        if (this.isIFrame()) {
          this.error = 'Veuillez vérifier votre adresse email'
        } else {
          Swal.fire('Error', 'Veuillez vérifier votre adresse email', 'error');
        }
        return
      }
      if (this.isIFrame()) {
        this.loadingLoginWithPhone = true;
      } else {
        this.spinner.show();
      }
      this.authService.forgotPassword(this.Form.value.email).subscribe(
        res => {
          this.Form.reset();
          if (this.isIFrame()) {
            this.error = 'Le lien de réinitialisation a été envoyé.\nVeuillez vérifier votre couriel.';
          } else {
            Swal.fire('Envoyé', 'Le lien de réinitialisation a été envoyé.\nVeuillez vérifier votre couriel.', 'success');
          }
          this.modalAction("btn-close");
          if (this.isIFrame()) {
            this.loadingLoginWithPhone = false;
          } else {
            this.spinner.hide();
          }
        },
        err => {
          if (this.isIFrame()) {
            this.loadingLoginWithPhone = false;
          } else {
            this.spinner.hide();
          }
          if (err.error.data[0]) {
            if (err.error.data[0].messages[0].id == 'Auth.form.error.email.format') {
              if (this.isIFrame()) {
                this.error = 'Veuillez entrer une adresse email valide';
              } else {
                Swal.fire('Error', 'Veuillez entrer une adresse email valide', 'error');
              }
              console.log(err);
            }
            else if (err.error.data[0].messages[0].id == "Auth.form.error.user.not-exist") {
              if (this.isIFrame()) {
                this.error = 'Adresse email n\'exite pas';
              } else {
                Swal.fire('Error', 'Adresse email n\'exite pas', 'error');
              }
              console.log(err);
            }
          }
          else {
            if (this.isIFrame()) {
              this.error = 'Une érreur s\'est produite veuillez réessayer';
            } else {
              Swal.fire('Error', 'Une érreur s\'est produite veuillez réessayer', 'error');
            }
            console.log(err);
          }
          this.modalAction("btn-close");
        }
      )

    }
  }

  modalAction(className: string) {
    let toggleButton: HTMLElement = document.getElementsByClassName(className)[0] as HTMLElement;
    toggleButton.click();
  }

  resendConfirmation() {
    if (this.isIFrame()) {
      this.loadingLoginWithPhone = true;
    } else {
      this.spinner.show();
    }
    this.authService.resendConfirmation(this.Form.value.identifier).subscribe(res => {
      if (this.isIFrame()) {
        this.error = "Un nouveau émail d'activation a été envoyé à votre boite.";
      } else {
        Swal.fire("Envoyé", "Un nouveau émail d'activation a été envoyé à votre boite.", "success");
      }
    }, err => {
      if (this.isIFrame()) {
        this.error = "Une érreur est survenue";
      } else {
        Swal.fire("Érreur", "Une érreur est survenue", "error");
      }
    }).add(() => {
      if (this.isIFrame()) {
        this.loadingLoginWithPhone = false;
      } else {
        this.spinner.hide();
      }
    })
  }

  objectToParams(obj: { [key: string]: any }): string {
    const params = new URLSearchParams();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        params.append(key, value.toString());
      }
    }

    return params.toString();
  }

  setIFrameHeight() {
    if (this.isIFrame()) {
      setTimeout(() => {
        const elementHeight = document.body.scrollHeight;
        parent.postMessage(JSON.stringify({ iframeHeight: elementHeight }), environment.webflowURL);
      }, 200);
    }
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }
}
