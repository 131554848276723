import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Studio } from 'src/app/classes/studio.model';
import { Type } from 'src/app/classes/type.model';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { StudioService } from 'src/app/services/studio/studio.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-booking-container',
  templateUrl: './booking-container.component.html',
  styleUrls: ['./booking-container.component.css']
})
export class BookingContainerComponent implements OnInit {

  loading:boolean=true;
  studio!: Studio;
  type!: Type;
  targetedStep = "booking";
  user: any;
  preparedObjectToUrl: any;
  constructor(private router: Router, private route: ActivatedRoute, private studioService: StudioService, private tokenStorageService: TokenStorageService) {
  }

  ngOnInit(): void {
    this.user = this.tokenStorageService.getUser();
    
    this.route.queryParams.subscribe(params => {
      if (params.step) {
        this.targetedStep = (Object.keys(this.user).length !== 0) ? 'booking' : params.step;
      }
      if (!params.studio) {
        this.loading=false;
        this.studio = new Studio({});
      } else
        this.studioService.get_studio_by_id_model(params.studio).subscribe(studio => {
          this.studio = studio;
          this.loading=false;
          this.type = this.studio.getTypeByName(params.typeStudio);
        }, err => {
            this.loading=false;
        })
    });
  }

  getEmittedReservation(value: any) {
    this.targetedStep = 'checkAccount';
    this.preparedObjectToUrl = { ...{ step: this.targetedStep }, ...value };
    this.passParamsToUrl();
  }

  getEmittedAccountStatus(value: any) {
    this.targetedStep = 'validSms';
    this.preparedObjectToUrl = { ...{ step: this.targetedStep }, ...value };
    this.passParamsToUrl();
  }

  getEmittedCodeSmsResponse(value: any) {
    this.targetedStep = value;
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

  passParamsToUrl() {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.preparedObjectToUrl,
      queryParamsHandling: 'merge',
      // do not trigger navigation
    });
  }

}

