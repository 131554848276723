import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { ParrainageService } from 'src/app/services/parrainage/parrainage.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ReservationsService } from 'src/app/services/reservations/reservations.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paypal-response',
  templateUrl: './paypal-response.component.html',
  styleUrls: ['./paypal-response.component.css']
})
export class PaypalResponseComponent implements OnInit {
  paymentId: string;
  PayerID: string;
  token: string;
  device: string;
  message: string = "";
  showButtonListReservation: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private paypalService: PaymentService,
    private spinner: NgxSpinnerService,
    private tokenService: TokenStorageService,
    private reservationService: ReservationsService,
    private paymentService: PaymentService,
    private parrainageService: ParrainageService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(res => {
      this.paymentId = res.paymentId;
      this.PayerID = res.PayerID;
      this.token = res.token;
      this.device = res.device;
    })
    if (this.router.url.includes('/success-paypal') && this.device == 'web' && JSON.parse(localStorage.getItem('reservation')) && this.paymentId && this.PayerID) {
      this.message = "Votre réservation est en cours de traitement. Veuillez ne pas fermer cette page pendant que nous traitons votre demande. Nous vous remercions de votre patience.";
      this.paypalService.executePaypalPayment(this.paymentId, this.PayerID, JSON.parse(localStorage.getItem('reservation')).pay).subscribe(res => {
        this.saveReservation(res)
      }, err => {
        this.message = "Désolé, une erreur s'est produite. Veuillez réessayer ou contacter le support.";
        this.showButtonListReservation = true;
        //Swal.fire('Érreur', "une erreur s'est produite veuillez réessayer", 'error');
      })
    } else {
      if (this.isIFrame()) {
        parent.location.href = environment.webflowURL + "/app?action=reservations-list&from=create";
      } else {
        this.router.navigate(['/']);
      }

    }
    if (this.router.url.includes('/cancel-paypal') && this.device == 'web') {
      if (this.isIFrame()) {
        this.message = "Désolé, une erreur s'est produite lors du paiement. Veuillez réessayer ou contacter le support.";
        this.showButtonListReservation = true;
      } else {
        this.router.navigate(['artiste/fiche-studio/' + JSON.parse(localStorage.getItem('studio')).id]);
        Swal.fire('Érreur', "Désolé, une erreur s'est produite lors du paiement. Veuillez réessayer ou contacter le support.", 'error');
      }

      this.removeStorage();
    }
  }

  saveReservation(payment: any) {
    this.spinner.show();
    let currentReservation = JSON.parse(localStorage.getItem('reservation'));
    if (currentReservation.id != null && currentReservation.id != "") {
      console.log("traitement reservation....");
      console.log(currentReservation);

      this.paymentService.update(currentReservation.payment.id, { payment_id: payment.id, type: "paypal", }).subscribe(payment => {
        this.removeStorage();
        if (this.isIFrame()) {
          parent.location.href = environment.webflowURL + "/reservation-confirmee";
          //parent.location.href = environment.webflowURL + "/app?action=reservation-details&id=" + currentReservation.id;
        } else {
          this.router.navigate(['/artiste/reservation-details-artiste/' + currentReservation.id]);
          Swal.fire('Félicitation', "Réservation créée avec succès", "success");
        }
      }, err => {
        Swal.fire('Erreur', "une érreur est survenue", "error");
      })

    } else {
      this.reservationService.create_reservation(currentReservation, this.tokenService.getUser().id).subscribe(
        reservation => {
          this.paymentService.create_payment(payment.id, "", reservation.id, "", "paypal").subscribe(p => {
            // set used parrainage to false
            if (localStorage.getItem('listUsedParrainage')) {
              let usedParrainage = []
              JSON.parse(localStorage.getItem('listUsedParrainage')).forEach(element => {
                usedParrainage.push({ id: element.id, etat: false })
              });
              this.parrainageService.update(usedParrainage).subscribe(usedP => {

              })
            }
            this.removeStorage();
            if (this.isIFrame()) {
              parent.location.href = environment.webflowURL + "/reservation-confirmee";
              //parent.location.href = environment.webflowURL + "/app?action=reservations-list&from=create";
            } else {
              this.router.navigate(['/artiste/reservations']);
              Swal.fire('Félicitation', "Réservation créée avec succès", "success");
            }


          }, err => {
            this.spinner.hide();
          })
        },
        err => { this.spinner.hide(); },
      ).add(() => {
        this.spinner.hide();
      });
    }

  }

  removeStorage() {
    localStorage.removeItem('reservation');
    localStorage.removeItem('studio');
    localStorage.removeItem('listUsedParrainage');
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

  goToListReservations() {
    if (this.isIFrame()) {
      parent.location.href = environment.webflowURL + "/app?action=reservations-list";
    } else {
      this.router.navigate(['/artiste/reservations']);
    }
  }

}
