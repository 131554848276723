<div class="container">
  <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
    <div data-bs-toggle="collapse" data-bs-target="#bd-docs-nav" aria-controls="navbarsExampleDefault"
      aria-expanded="false" aria-label="Toggle navigation"
      class="col-4 d-flex align-items-center col-md-4 text-dark text-decoration-none">
      <i style="color: #464353;" class="fas fa-bars text-white text-form-color fs-4 pointer"></i>
    </div>

    <div class="nav col-12 col-md-auto d-none d-md-block justify-content-center">
      <span class="icon-logo-white fs-2 pointer"></span>
    </div>

    <div class="col-md-4 col-8 text-end">
      <img [src]="image" alt="mdo" width="40" height="40" class="pointer rounded-circle cover" id="navbarDropdown"
        role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <ul [ngClass]="{'profileShow': showProfileMenu}" class="dropdown-menu" aria-labelledby="navbarDropdown">
          <div class="text-center my-4">
            <img [src]="image" alt="mdo" width="100" height="100" class="rounded-circle cover">
            <p class="mt-2">{{user.profile.full_name}}</p>
          </div>
          <button *ngIf="currentType!='artiste'" class="btn btn-danger w-100 text-uppercase" (click)="menu_switcher()">
            <span>{{switch_button_text}}</span>
          </button>
          <li class="pointer"><a class="d-flex dropdown-item dropdown-item-profile" routerLink="/profil"><i class="align-self-center pe-2 icon-profile fs-5"></i><span>Mon profil</span></a></li>
          <!-- <li class="pointer" *ngIf="currentType=='gerant'" routerLink="/gerant/mes-abonnements"><a class="d-flex dropdown-item dropdown-item-profile"><i class="align-self-center pe-2 icon-abonnement fs-5"></i>Mon abonnement</a></li> -->
          <li class="pointer" routerLink="/gerant/mes-moyens-paiement"><a class="d-flex dropdown-item dropdown-item-profile"><i class="align-self-center pe-2 icon-payment fs-5"></i>Mes moyens de paiement</a></li>
          <li class="pointer" routerLink="/parametres"><a class="d-flex dropdown-item dropdown-item-profile"><i class="align-self-center pe-2 icon-cogs fs-5"></i>Paramètres</a></li>
          <li class="pointer"><hr class="dropdown-divider"></li>
          <li class="pointer" routerLink="/parrainer-ami"><a class="d-flex dropdown-item dropdown-item-profile"><i class="align-self-center pe-2 icon-sponsor fs-5"></i>Parrainer un ami</a></li>
          <!-- <li class="pointer" (click)=comming_soon() *ngIf="currentType=='gerant'"><a class="d-flex dropdown-item dropdown-item-profile"><i class="align-self-center pe-2 icon-gift fs-5"></i>Offrir une carte cadeau</a></li> -->
        </ul>
    </div>
  </header>
</div>