import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-nav',
  templateUrl: './default-nav.component.html',
  styleUrls: ['./default-nav.component.css']
})
export class DefaultNavComponent implements OnInit, AfterViewInit {
  @ViewChild("fundModal") fundModal:ElementRef;
  showLogin: boolean = false;
  currentRoute: string;
  constructor(private router: Router, private modalService: NgbModal) {
    this.currentRoute = "";
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      }

      if (event instanceof NavigationEnd) {
        this.checkIsReserveStudio(event.url);
      }

      if (event instanceof NavigationError) {
        this.showLogin = false;
      }
    });
  }
  

  ngAfterViewInit(): void {
    //this.openModal();
  }
  
  ngOnInit(): void {
    this.checkIsReserveStudio(this.router.url);
  }

  checkIsReserveStudio(link: string): void {
    this.currentRoute = link;
    if (this.currentRoute.includes("/artiste/reserver-studio")) {
      this.showLogin = true;
    } else {
      this.showLogin = false;
    }
  }

  gotToBlog(){
    if(typeof window !== 'undefined')
     window.location.href = environment.url+"blog/";
  }

  openModal() {
    if(this.fundModal)
      this.modalService.open(this.fundModal, { windowClass: 'fundModal', centered: true, size: 'lg' });
  }

}
