<div class="container my-5 p-5 text-start">
    <h2 class="mb-5 text-center">FAQ STUDIOMAP</h2>
    <h4 class="text-danger">Sommaire</h4>
    <ul id="sommaire">
        <li (click)="scrollToGeneral()">Général</li>
        <li (click)="scrollToUser()">Utilisateur</li>
        <li (click)="scrollToStudio()">Studio</li>
        <li (click)="scrollToFreelance()">Free-lance</li>
    </ul>
    <div class="mb-5" #general></div>
    <h5 class="my-5 text-decoration-underline">Partie Générale :</h5>
    <h5 class="mb-5 "><b>•</b>Qu’est-ce que Studiomap ?</h5>

    <div class="mb-5">
        <p> Studiomap est une plateforme de réservation, référencement et gestion de studios de création artistique
            (musique, photo, vidéo, répétition, etc) et de prestataires freelances.</p>
        <p>Studiomap permet aux artistes amateurs et professionnels de trouver les studios ou prestataires dont ils ont
            besoin pour développer leurs projets créatifs.</p>
    </div>

    <h5 class="mb-5 "><b>•</b>Dans quels pays/villes Studiomap est implanté ?</h5>
    <p>Studiomap est une application qui vise à s’implanter dans chaque ville où les activités culturelles et créatives
        se développent. </p>
    <p>Notre secteur de départ est la région parisienne et l’île de France.</p>
    <p>Nous allons cependant être rapidement disponibles dans toutes les grandes métropoles de France, Belgique et
        Suisse et visons un développement à l'international d’ici quelques années.</p>
    <h5 class="mb-5 "><b>•</b>Quels types de studio sur Studiomap ?</h5>
    <p>Studiomap est une application qui vise à simplifier le processus de réservation dans les milieux créatifs. Nous
        entendons par “milieux créatifs” :</p>
    <ul>
        <li>- les studios d’enregistrement</li>
        <li>- les studios de répétition</li>
        <li>- les studios de photo et de vidéo </li>
        <li>- les studios de danse</li>
    </ul>
    <h5 class="mb-5 "><b>•</b>Quels types de free-lance sur Studiomap ?</h5>
    <p>Studiomap est une application qui vise à simplifier la mise en relation avec les acteurs des milieux créatifs qui
        travaillent en free-lance. Nous entendons par "freelances créatifs” :</p>

    <ul>
        <li>- les Photographes et Réalisateurs </li>
        <li>- les Graphistes & Designers</li>
        <li>- Les Beatmakers & Ingénieurs du Son</li>
        <li>- Les Modèles & Figurants </li>
    </ul>

    <div class="mb-5" #utilisateur></div>
    <h5 class="my-5 text-decoration-underline">Partie Utilisateur (artiste) :</h5>
    <h5 class="mb-5 "><b>•</b>Comment fonctionne Studiomap ?</h5>

    <p>Studiomap accompagne les artistes dans le processus de réservation de studios et la recherche de prestataires
        créatifs pour réaliser leurs projets.</p>
    <p>En s’inscrivant sur notre plateforme, vous accédez à une sélection personnalisée de studios/prestataires selon
        vos critères de préférence (distance, prix, prestations, matériel, etc) puis réservez en quelques clics une
        session adaptée à vos besoins et à l’heure de votre choix sur n’importe quel appareil.</p>
    <p>Vous pouvez finaliser votre réservation en procédant au paiement de votre session sur notre plateforme et recevez
        une confirmation électronique. Si besoin, modifiez ou annulez votre réservation jusqu’à 24h avant la séance sur
        l’interface Studiomap.</p>
    <p>Après avoir effectué votre séance, laissez un commentaire au studio pour faire connaître votre avis aux autres
        utilisateurs de Studiomap.</p>

    <h5 class="mb-5 "><b>•</b>Quels sont les avantages de Studiomap ?</h5>

    <p>Studiomap vous permet de réserver avec rapidité et facilité votre session auprès de nos studios et prestataires
        partenaires. Ne perdez plus votre temps en recherches interminables, Studiomap vous recommande gratuitement les
        studios dont vous avez besoin 😉</p>
    <p>Triez les studios de notre répertoire en fonction des disponibilités, de la proximité géographique, du prix, du
        type de prestations et bien d’autres critères.</p>
    <p>Nous vous proposons des tarifs préférentiels et des réductions sur certains horaires et une flexibilité dans la
        gestion de vos réservations.</p>
    <p>Grâce à nos partenariats exclusifs et à notre interface pensée par des artistes, pour les artistes, nous
        optimisons l’administration des studios et prestataires afin que vous puissiez vous concentrer entièrement sur
        votre création artistique.</p>
    <p>Téléchargez notre application pour réserver vos séances et prestations où vous voulez et quand vous voulez en
        quelques secondes seulement.</p>


    <h5 class="mb-5 "><b>•</b>Dois-je créer un compte Studiomap ?</h5>

    <p>La création d’un compte n’est pas obligatoire pour profiter de la map des studios en tant qu’artiste.</p>
    <p>Néanmoins, pour pouvoir réserver une séance dans un studio partenaire ou entrer en contact avec un prestataire
        freelance, l’inscription où la connexion est obligatoire. </p>


    <h5 class="mb-5 "><b>•</b>Comment réserver une séance dans un studio ?</h5>
    <p>Pour réserver une séance : </p>

    <ul>
        <li>- Inscrivez-vous ou connectez-vous </li>
        <li>- Allez sur l'écran principal</li>
        <li>- Sélectionnez l’onglet “Studios”</li>
        <li>- Sélectionnez le type de studio que vous recherchez</li>
        <li>- Géolocalisez-vous sur la carte afin de découvrir les studios les plus proches de chez vous. Vous pouvez
            également cliquer sur l’onglet filtre à droite de la barre de recherche pour sélectionner les studios sur
            différents critères</li>
        <li>- Sélectionnez le studio de votre choix et cliquez sur l’onglet Réserver</li>
        <li>- Sélectionnez la date et l’heure de votre prestations ainsi que le type de prestations souhaitées </li>
        <li>- Entrez votre moyen de paiement et validez.</li>
        <li>- En cas d’erreur et d’imprévus, vous avez 24 heures pour annuler ou modifier une séance</li>
    </ul>
    <p>L’équipe de Studiomap se tient à votre disposition par mail ou par téléphone si vous avez la moindre question 😉.
    </p>

    <h5 class="mb-5 "><b>•</b>Comment puis-je m’assurer de la qualité du studio que je réserve avec Studiomap ?</h5>

    <p>Les studios s’inscrivant sur Studiomap sont des partenaires dont l’existence est vérifiée et la qualité contrôlée
        la plupart du temps. </p>

    <p>Par ailleurs, un système de notation sur 5 des studios ainsi que la possibilité pour chaque utilisateur de
        laisser un avis permettent à l’utilisateur de s’assurer de la qualité de chaque studio où il réserve par
        l’application Studiomap.</p>

    <h5 class="mb-5 "><b>•</b>Comment puis-je obtenir des réductions sur mes sessions en studio ? </h5>

    <p>Rejoindre Studiomap, c’est rejoindre une communauté vivante et créative pour accompagner ses projets artistiques.
    </p>
    <p>Les réductions sont à l’entière discrétion des studios qui peuvent choisir de mettre un tarif préférentiel en
        heure creuse afin d’attirer plus de clientèle.</p>
    <p>Par ailleurs, Studiomap organise régulièrement des jeux concours ainsi que des promotions permettant de
        bénéficier de réductions sur les sessions studio.</p>

    <p>Suivez-nous sur les réseaux pour être au courant de l’actualité et bénéificer nombreux avantages (jeux concours,
        code promo, ect). </p>

    <h5 class="mb-5 "><b>•</b>Comment m’inscrire ?</h5>
    <p>Pour s’inscrire : </p>


    <ul>
        <li>- Téléchargez l’application</li>
        <li>- Cliquez sur le bouton s’inscrire</li>
        <li>- Sélectionnez le cas d’utilisation qui correspond à votre activité selon que vous soyez artiste, gérant de
            studio, free-lance créatif.</li>
        <li>- Remplissez les champs </li>
        <li>- Confirmez votre mail par le lien que vous recevrez</li>
    </ul>
    <p>Ça y est, c’est fini ! Vous pouvez dès à présent réserver une séance et entrer en contact avec les personnes de
        votre choix pour créer.</p>


    <h5 class="mb-5 "><b>•</b>Comment modifier mon mot de passe ?</h5>


    <p>Pour modifier un mot de passe il suffit de se connecter à son profil </p>
    <p>Cliquez sur Paramètres </p>
    <p>Cliquez sur Sécurité</p>
    <p>Cliquez sur modifier mon mot de passe en fin de page.</p>
    <p>Vous recevrez un lien de modifications par mail. Suivez les instructions </p>


    <h5 class="mb-5 "><b>•</b>Puis-je contacter un studio avant de réserver ?</h5>


    <p>Studiomap a pour but premier de faciliter la collaboration artistique.</p>
    <p>Pour le cas des studios partenaires, il n’est pas nécessaire de contacter le studio via l’application STUDIOMAP
        avant de réserver. Toutes les informations nécessaires et actualisées sont dans la description. Vous pouvez
        directement sélectionner le jour et l'horaire qui vous convient et réservez en ligne si le créneau est
        disponible.</p>
    <p>Pour les studios non-partenaires, nous mettons les informations actualisées à notre disposition (numéro de
        téléphone, mail et adresse du studio). Néanmoins, nous ne pourrons vous garantir d’avoir une séance disponible
        au moment qui vous convient tout comme nous ne pourrons vous garantir la qualité du studio., C’est pour cela que
        nous vous invitons toujours à réserver auprès d’un de nos studios partenaires afin de garantir une fiabilité et
        la meilleure expérience possible de réservation.</p>


    <h5 class="mb-5 "><b>•</b>Comment modifier/annuler ma réservation ?</h5>


    <p>Vous pouvez modifier une réservation jusqu’à 24h avant le jour de la réservation en allant dans l’onglet “Mes
        réservations”.</p>
    <p>Si vous annulez 24h avant la séance, vous serez remboursé intégralement du prix de la séance. </p>
    <p>Si vous annulez moins de 24h avant, vous serez remboursé de 50% du prix de la séance. </p>
    <p>Si vous annulez moins de 3h avant la séance, vous ne serez pas remboursé.</p>
    <p>En dehors de ces délais, sauf motif impérieux d’annulation, une commission vous sera facturée et prélevée par
        Studiomap ainsi que par le studio.</p>


    <h5 class="mb-5 "><b>•</b>Comment laisser mon avis sur un studio ?</h5>


    <p>A la fin de votre séance vous recevrez une notification vous invitant à laisser un commentaire.</p>
    <p>Celui-çi ne sera pas forcément publié mais il sera étudié par l’équipe de Studiomap.</p>
    <p>Vous pourrez également laisser un commentaire à tout moment sur une réservation dans l’onglet “Mes réservations”
        jusqu’à 5 jours après le déroulement de la séance.</p>

    <h5 class="mb-5 "><b>•</b>Quels sont les moyens de paiement pour ma réservation ?</h5>


    <p> pouvez payer par carte bancaire via Stripe, ou par Paypal. </p>
    <p>Enfin, certains studios acceptent le moyen de paiement par espèce. </p>
    <p> pourrez alors payer votre séance sur place, il faudra néanmoins payer une commission en ligne sur notre
        plateforme via les moyens de paiement évoqués précédemment pour réserver la séance.</p>

    <h5 class="mb-5 "><b>•</b>Quand suis-je débité ? Quand dois-je payer pour ma réservation de studio ? </h5>


    <p>L’argent est séquestré sur un compte de dépôt et ne sera débité qu’une fois la séance terminée si tout s’est bien
        passé.</p>
    <p>La commission est débitée immédiatement. </p>
    <p>En cas de problème, vous pouvez contacter le support et faire une demande de remboursement. Si votre demande est
        acceptée, comptez entre 48h et 72h pour voir votre remboursement opéré. </p>

    <h5 class="mb-5 "><b>•</b>Les paiements par carte bancaire sont-ils sécurisés ?</h5>


    <p>Tous nos modules de paiement sont extrêmement sécurisés. Nous utilisons des intermédiaires financiers bien
        établis tels que Stripe ou Paypal pour garantir la sécurité de vos paiements sur notre plateforme.</p>

    <h5 class="mb-5 "><b>•</b>Comment ajouter/supprimer une carte bancaire ?</h5>


    <p>Pour ajouter une carte bancaire :</p>

    <ul>
        <li>- rendez-vous sur l’onglet paramètre</li>
        <li>- cliquez sur moyen de paiement </li>
        <li>- cliquez sur ajouter un moyen de paiement </li>
        <li>- entrez les numéros de votre carte ba</li>
        <li>- enregistrez le moyen de paiement</li>
    </ul>

    <p>Pour supprimer un moyen de paiement</p>

    <ul>
        <li>- rendez-vous sur l’onglet paramètre</li>
        <li>- cliquez sur moyen de paiement déjà enregistrés </li>
        <li>- cliquez sur le bouton “supprimer”</li>
    </ul>


    <h5 class="mb-5 "><b>•</b>Comment faire si je n’ai pas reçu de confirmation par mail ?</h5>


    <p>Si vous n’avez pas reçu de mail de confirmation, vous pouvez joindre notre équipe par mail à help@studiomap.fr.
        Nous vous répondrons dans le 48h grand max :)</p>

    <h5 class="mb-5 "><b>•</b>Comment signaler un problème qui a eu lieu lors d’une séance ?</h5>


    <p>Pour signaler un problème qui a eu lieu lors de votre séance, vous devez simplement nous envoyer un mail à
        l’adresse help@studiomap.fr avec les informations suivantes : </p>

    <!-- li -->
    <li>- nom, prénom </li>
    <li>- numéro de réservation</li>
    <li>- date et heure de réservation</li>
    <li>- quelques lignes explicatives de votre problème.</li>
    <!-- li -->

    <p>Un chargé d’affaires prendra contact avec vous dans le 48h suivant la réception du mail afin de régler votre
        problème le plus rapidement possible. </p>


    <h5 class="mb-5 "><b>•</b>Comment faire une demande de remboursement ? Quel est le délai ?</h5>


    <p>Pour opérer une demande de remboursement, veuillez envoyer un mail à l’adresse hello@studiomap.fr en indiquant
        dans l’objet de votre mail “Remboursement numéro de réservation” ainsi que quelques lignes explicatives de votre
        demande de remboursement.</p>
    <p>Si votre demande est jugée comme légitime, vous serez remboursé intégralement dans un délai de 48h à 72h 😉..
    </p>
    <p><b>En cas d’autre question n’hésitez pas à nous contacter en cliquant sur le lien en bas de page “Contactez-nous”
            ou par mail à l’adresse hello@studiomap.fr</b></p>

    <div class="mb-5" #studio></div>
    <h5 class="mb-5 text-decoration-underline">Partie Studio :</h5>


    Comment fonctionne Studiomap ?


    <p>Studiomap accompagne les managers de studio dans la gestion de leurs équipes et des réservations. Nous avons
        développé une interface intuitive pour vous faciliter les tâches administratives et vous permettre d’optimiser
        l’utilisation de votre studio.</p>
    <p>Nous référençons votre studio sur notre map interactive, que vous soyez partenaire ou non. Ainsi, vous gagnez en
        visibilité et vous attirer de nouveaux clients.</p>
    <p>D’autre part, notre système de réservation simplifié vous permet de gérer automatiquement les réservations et
        annulations via l’application, sans mauvaises surprises. Plus d’appels intempestifs ou de mails pour demander
        des devis, on s’occupe de tout !</p>
    <p>Enfin, avec Studiomap vous avez aussi la possibilité de recevoir les paiements directement via l’application et
        de les partager à votre guise avec les membres de votre équipe. </p>
    <p>Quelle que soit l’expertise ou la taille de votre studio, nous avons les outils pour simplifier la gestion de
        votre entreprise et accroître votre volume de réservation. Studiomap, c’est LA plateforme de référence pour les
        managers de studio indépendants et professionnels, au service de la création et de ceux qui la font vivre.</p>


    Quels sont les avantages de Studiomap ?


    <p>Studiomap est l’intermédiaire qui comble le vide entre les studios de création et leurs clients. Il y a des
        millions d’artistes amateurs et professionnels dans la création audiovisuelle à travers le monde qui rêvent de
        faire accélérer leurs projets. </p>
    <p>Seulement, la difficulté qu’ils éprouvent à trouver un prestataire qui leur corresponde vraiment les dissuade
        bien souvent (distance, prix, affinité musicale, difficulté de réservation, peur de se lancer…).</p>
    <p>Parce que nous connaissons le monde des studios et que nous partageons le désir viscéral de création des
        artistes, nous avons imaginé l’outil parfait pour aider les managers de studio à développer leurs services de
        gestion et de réservation.</p>

    <p>Résultats ?</p>
    <!-- li -->
    <li>- Une hausse de la quantité de réservations avec une optimisation des heures creuses. </li>
    <li>- Un allègement des tâches administratives important et une augmentation des revenus </li>
    <li>- Des clients satisfaits et une hausse de la réputation de nos studios partenaires</li>
    <li>- Un gain de temps considérable et des économies sur les dépenses marketing des studios </li>

    <!-- li -->

    <p>Grâce à Studiomap, vous travaillez avec des artistes qui vous correspondent et vous parvenez à offrir des
        prestations de meilleure qualité. Notre tarification progressive pour les studios partenaires s’adapte à vos
        besoins, afin de vous accompagner dans l’expansion de votre activité commerciale.</p>
    <p>N’attendez plus pour essayer, inscrivez-vous gratuitement dès aujourd’hui !</p>

    <h5 class="mb-5 ">Comment inscrire mon studio sur Studiomap ?</h5>


    <p>Pour s’inscrire : </p>

    <!-- li -->
    <li>- téléchargez l’application</li>
    <li>- cliquez sur le bouton s’inscrire</li>
    <li>- sélectionnez gérant de studio ou free-lance créatif.</li>
    <li>- Remplissez les champs d’information</li>
    <li>- Confirmez votre mail par le lien que vous recevrez</li>
    <!-- li -->
    <p>Et voilà ! Vous êtes désormais visible par des milliers d’artistes qui peuvent réserver une séance dans votre
        studio en un clique 🤠</p>

    <h5 class="mb-5 ">Comment modifier mon mot de passe ?</h5>

    <!-- li -->
    <li>- Pour modifier votre mot de passe :</li>
    <li>- Connectez vous à votre profil</li>
    <li>- Cliquez sur mon compte</li>
    <li>- Sélectionnez “editer mon profil” </li>
    <li>- Cliquez sur “modifier mon mot de passe” en fin de page.</li>
    <!-- li -->

    <p>Vous recevrez un mail pour réinitialiser votre mot de passe et vous connecter sur votre compte.</p>

    <h5 class="mb-5 ">
        Quelles sont les modalités d’inscription ?
    </h5>

    <p>En tant que gérant de Studio vous devez remplir les informations suivantes lors de votre inscription : </p>
    <!-- li -->
    <li>- Nom prénom</li>
    <li>- Adresse mail</li>
    <li>- Nom du studio</li>
    <li>- Adresse du studio</li>
    <li>- Numéro de téléphone </li>
    <li>- Photos du studios</li>
    <li>- Siret (facultatif)</li>
    <!-- li -->

    <p>Pensez bien à cocher la phase “Je suis un studio” lors de votre première inscription</p>

    <h5 class="mb-5 ">
        Comment fonctionnent les abonnements et commissions ?
    </h5>


    <p>Pour le fonctionnement des abonnements, veuillez consulter notre grille tarifaire.</p>
    <p>Pour les commissions, Studiomap charge une commission de 5% sur l’ensemble du prix d’une réservation. Cette
        commission sert à assurer les frais de fonctionnement, les frais de remboursement ainsi que les frais
        d'annulation des clients. </p>


    <h5 class="mb-5 ">
        Comment fonctionne la partie « Gérant de studio » de la plateforme ?
    </h5>

    <!-- li -->
    <li>- La partie gérant de studio dispose de 5 modules :</li>
    <li>- un module “Studio” sur lequel vous pouvez ajouter, modifier ou supprimer vos studios de l’application
        Studiomap.</li>
    <li>- un module gestion de “Planning” sur lequel vous pouvez voir votre calendrier et vos réservations.</li>
    <li>- un module management “d'Équipe" sur lequel vous pouvez inscrire les disponibilité salariés, prestataires,
        stagiaires et voir leur disponibilité dans le planning d’équipe</li>
    <li>- Une messagerie </li>
    <li>- un module “Bilan prestataires” qui permet de déterminer quel employés vous doit combien sur quelle période de
        temps.</li>
    <!-- li -->

    <p>Vous disposez également d’un module profil et paramètre qui vous permet d’indiquer différentes informations qui
        serviront à améliorer votre expérience Studiomap. </p>

    <h5 class="mb-5 ">
        Comment mettre en avant mon studio sur la plateforme ? // Comment donner plus de visibilité à mon studio ?
    </h5>

    <p>Pour mettre en avant votre studio sur la plateforme de manière gratuite : </p>
    <!-- li -->
    <li>- Renseignez toutes les informations de votre Studio</li>
    <li>- Mettez les photos les plus professionnelles possibles (si vous avez besoins de photographies professionnelles,
        nous pouvons vous aider)</li>
    <li>- Répondez le plus rapidement possible aux demandes de réservation</li>
    <li>- Obtenez les meilleurs avis</li>
    <!-- li -->


    <p>Pour mettre en avant votre studio sur la plateforme de manière payante :</p>
    <!-- li -->
    <li>- Cliquez sur l’onglet “Mes studios”</li>
    <li>- Cliquez sur l’onglet “Sponsoriser mon studio”</li>
    <li>- Ajoutez une période de temps et un budget</li>
    <li>- Vous apparaîtrez désormais en premier dans les résultats de recherche sur une période déterminée. Si vous avez
        de bons avis, il est possible que votre studio reste, par la suite parmi, les premiers dans les résultats de
        recherche.</li>
    <!-- li -->

    <p><b>•</b>Comment optimiser l’utilisation de mon studio grâce à Studiomap ?</p>

    <h5 class="mb-5 ">
        Comment paramétrer les modalités de mes séances (prix, horaires, etc) ?
    </h5>


    <p>Pour paramétrer les modalités de mes séances :</p>

    <!-- li -->
    <li>- Cliquez sur l’onglet “Mes studios”</li>
    <li>- Cliquez sur le studio à paramétrer </li>
    <li>- Indiquer dans la partie tarification le prix de l’heure en journée, le prix de l’heure en séance de nuit et le
        prix de l’heure le week-end (si vos prix varient en fonction du moment de la journée).</li>
    <!-- li -->


    <h5 class="mb-5 ">
        Comment suis-je averti des réservations dans mon studio ?
    </h5>


    <p>Lorsque le paiement de la réservation est effectué, vous recevez une notification vous indiquant qu’une demande
        de réservation a été réalisée. Vous avez ensuite le choix d’accepter ou refuser la réservation.</p>
    <p>Notez qu’une fois une réservation acceptée, vous ne pouvez plus annuler celle-çi dans les 10 minutes qui suivent
        (ce délai étant prévu pour vous permettre d’annuler en cas d’erreur). </p>
    <p>Auquel cas, si vous acceptez une réservation que vous n'êtes pas en mesure d’assurer, le client sera remboursé
        intégralement, vous serez prélevé de frais équivalent à 15% de la séance pour couvrir la gestion de
        l’annulation, vous recevrez une pénalité.</p>


    <h5 class="mb-5 ">
        Comment affecter un de mes employés à une réservation ?
    </h5>


    <p>Lorsque vous recevez une demande de réservation, vous pouvez voir en fonction de la disponibilité de vos employés
        inscrite sur le planning d’équipe qui sera disponible pour assurer la séance.</p>
    <p>En cliquant sur un employé potentiellement disponible dans une réservation sur votre tableau de bord, la
        messagerie de votre téléphone s’ouvre et vous pourrez envoyer un message automatique à l’employé en question.
    </p>

    <h5 class="mb-5 ">
        Comment contacter l’artiste ayant effectué une réservation ?
    </h5>


    <p>Lorsque vous recevez une demande de réservation, vous obtenez sur vos tableaux de bords ou dans l’onglet mes
        réservations l’ensemble des informations disponibles sur l’artiste qui a réservé. </p>
    <p>Vous pourrez ainsi le contacter via la messagerie Studiomap ou directement par téléphone une fois la séance
        acceptée et le paiement effectué. .</p>


    <h5 class="mb-5 ">
        Comment modifier/annuler une réservation ?
    </h5>


    <p>
        Pour modifier ou annuler une réservation, vous pouvez vous rendre dans le menu:
    </p>
    <p>
        Modifier :
    </p>
    <!-- li -->
    <li>- mes réservations</li>
    <li>- cliquez sur la réservation </li>
    <li>- cliquez sur modifier</li>
    <li>- faite une demande de modification en proposant un autre créneau horaire au client</li>
    <li>- envoyez la demande de modification au client</li>
    <!-- li -->

    <p>
        Annuler :
    </p>

    <!-- li -->
    <li>- mes réservations</li>
    <li>- cliquez sur la réservation </li>
    <li>- cliquez sur annuler</li>
    <li>- vous pouvez annuler une réservation jusqu’à 5 heures avant l’heure de la séance. Pour les réservations le jour
        même, vous pouvez annuler jusqu’à 2 heures avant l’heure de la séance..</li>
    <!-- li -->

    <p>Au-delà de ce délai, des frais de gestion et remboursement vous seront facturés. </p>


    <h5 class="mb-5 ">
        Comment et quand est-ce que je reçois le paiement d’une réservation ?
    </h5>


    <p>En fonction du mode de paiement choisi par le client, et du mode de réception que vous choisissez, vous pourrez
        recevoir le paiement d'une réservation sur votre compte bancaire ou sur Paypal.</p>
    <p>Vous pouvez choisir entre un paiement journalier toutes les 48h après une séance, un paiement hebdomadaire toutes
        les deux semaines après une séance, et un paiement mensuel à la fin de chaque mois.</p>

    <h5 class="mb-5 ">
        Comment et quand dois-je payer mon abonnement ?
    </h5>


    <p>Votre abonnement sera activé à partir de 2 heures de réservations (ne faisant pas l’objet d’une promotion) par
        mois sur l’application Studiomap. </p>
    <p>Vous recevez une notification ainsi qu’un mail de facturation et un lien de paiement vous invitant à rentrer vos
        coordonnées bancaires afin de régulariser la facture. </p>
    <p>Si vous ne payez pas, vous pourrez continuer d’utiliser l’application Studiomap gratuitement. Néanmoins, vous
        n'apparaîtrez plus dans les résultats de recherche et sur la carte, et vous n’obtiendrez donc pas de réservation
        de la part de nouveaux clients.</p>
    <p>Si vous avez des questions ou si vous faites face à un problème, vous pouvez nous contacter par mail via
        l’adresse help@studiomap.fr, un de nos collaborateurs vous répondra dans les 48 heures maximums suivant la prise
        de contact.</p>


    <h5 class="mb-5 ">Les paiements par carte bancaire sont-ils sécurisés ?</h5>


    <p>Nos paiements par carte bancaire sont 100% sécurisé. </p>
    <p>Nous externalisons entièrement notre processus de paiement via des intermédiaires de paiement reconnus et
        sécurisés tels que Stripe ou Paypal.</p>
    <p>Nous n’enregistrons aucune de vos informations bancaires. </p>


    <h5 class="mb-5 ">
        Comment ajouter/supprimer une carte/compte bancaire ?
    </h5>


    <p>Pour ajouter ou supprimer une carte ou un compte bancaire. </p>
    <p>Cliquez sur le module Moyen de paiement : </p>

    <!-- li -->
    <li>- “Ajouter” : pour ajouter un nouveau moyen de paiement </li>
    <li>- Si vous voulez supprimer une carte ou un compte bancaire déjà enregistré : </li>
    <li>- Cliquez sur la carte ou le compte en question</li>
    <li>- Cliquez sur “supprimer”.</li>
    <!-- li -->

    <h5 class="mb-5 ">
        Comment faire si je n’ai pas reçu de confirmation par mail ?
    </h5>


    <p>En cas d’absence de mail de confirmation, vous pouvez contacter help@studiomap.fr</p>
    <p>Un de nos collaborateurs se chargera de traiter votre demande dans un délai de 48h maximum.</p>


    <h5 class="mb-5 ">
        Comment signaler un problème qui a eu lieu lors d’une séance ?
    </h5>


    <p>Pour signaler un problème qui a eu lieu lors de votre séance, vous devez simplement nous envoyer un mail à
        l’adresse help@studiomap.fr avec les informations suivantes : </p>

    <!-- li -->
    <li>- nom, prénom </li>
    <li>- numéro de réservation</li>
    <li>- date et heure de réservation</li>
    <li>- quelques lignes explicatives de votre problème.</li>
    <!-- li -->
    <p>Un chargé d’affaires prendra contact avec vous dans le 48h suivant la réception du mail afin de régler votre
        problème le plus rapidement possible. </p>

    <b>En cas d’autre question n’hésitez pas à nous contacter en cliquant sur le lien en bas de page “Contactez-nous” ou
        par mail à l’adresse hello@studiomap.fr</b>



    <div class="mb-5" #freelance></div>
    <h5 class="mb-5 text-decoration-underline">Partie Prestataires freelance :</h5>


    <h5 class="mb-5 ">
        Comment fonctionne Studiomap ?
    </h5>

    <p>(cf plus haut) </p>

    <h5 class="mb-5 ">
        Quels sont les avantages de Studiomap ?
    </h5>

    <p>(cf plus haut)</p>

    <h5 class="mb-5 ">
        Comment m’inscrire sur Studiomap ?
    </h5>

    <p>(cf plus haut)</p>

    <h5 class="mb-5 ">
        Comment modifier mon mot de passe ?
    </h5>

    <p>(cf plus haut)</p>
    <h5 class="mb-5">
        Quelles sont les modalités d’inscription ?
    </h5>

    <p>(cf plus haut)</p>

    <h5 class="mb-5">
        Comment fonctionne l’abonnement ?
    </h5>

    <p>Pour les prestataires freelances, il n’y a qu’un seul abonnement mensuel qui est de 9,99€.</p>
    <p>Cet abonnement est facturé à la fin de chaque mois. Le paiement est réalisé par carte bancaire. </p>

    <h5 class="mb-5">
        Comment fonctionne la partie « Prestataire » de la plateforme ?
    </h5>

    <p>La partie prestataire fonctionne de la même manière que la partie “Artiste” concernant la réservation de Studio.
        Ils peuvent réserver un studio dans l’onglet “Réserver un studio”. </p>
    <p>Les prestataires peuvent réaliser une présentation de leur travail dans l’onglet “Mon portfolio”</p>
    <p>Les prestataires freelances peuvent également proposer leurs services sur une zone géographique donnée, au même
        titre qu’un studio dans l’onglet “Mes services”.</p>
    <p>Enfin les prestataires freelances peuvent communiquer et entrer en contact avec un ensemble de clients potentiels
        par la messagerie Studiomap ou directement par téléphone.</p>

    <h5 class="mb-5">
        Comment mettre en avant mon profil sur la plateforme ?
    </h5>


    <p>Les prestataires freelances peuvent sponsoriser leurs services auprès de la communauté d'artistes Studiomap. </p>


    <h5 class="mb-5">
        Comment paramétrer les modalités de mes prestations ?
    </h5>

    <p>Les freelances sont libres de déterminer le nombre et le prix de leur prestation dans l’onglet “Mes prestations”.
    </p>

    <h5 class="mb-5">
        Comment les clients peuvent-ils me contacter ?
    </h5>


    <p>Les clients peuvent entre en contact avec un freelance lorsqu’il apparaît sur la map via la messagerie Studiomap.
    </p>

    <h5 class="mb-5">
        Comment et quand est-ce que je reçois le paiement d’une prestation ?
    </h5>


    <p>Lorsque le client et le freelance se sont mis d’accord sur une prestation, le paiement de celle-ci a lieu
        directement au sein de l’application afin d’assurer plus de fiabilité, de sécurité et de suivi pour le freelance
        comme pour le client. </p>

    <p>Le paiement est effectué par le client avant le début de la prestation. L’argent est séquestré sur un compte de
        dépôt et est reversé au freelance intégralement une fois qu'une fois la mission accomplie et terminée.</p>
    <p>Studiomap assure ainsi la sécurité du paiement en cas de litige pour le client et pour le freelance.</p>

    <h5 class="mb-5">
        Comment et quand dois-je payer mon abonnement ?
    </h5>

    <p>Votre abonnement sera activé à partir de la première prestation effectuée par mois sur l’application Studiomap.
    </p>
    <p>Vous recevez une notification ainsi qu’un mail de facturation et un lien de paiement vous invitant à rentrer vos
        coordonnées bancaires afin de régulariser la facture. </p>
    <p>Si vous ne payez pas, vous pourrez continuer d’utiliser l’application Studiomap gratuitement. Néanmoins, vous
        n'apparaîtrez plus dans les résultats de recherche et sur la carte, et vous n’obtiendrez donc pas de réservation
        de la part de nouveaux clients.</p>
    <p>Si vous avez des questions ou si vous faites face à un problème, vous pouvez nous contacter par mail via
        l’adresse help@studiomap.fr, un de nos collaborateurs vous répondra dans les 48 heures maximums suivant la prise
        de contact.</p>


    <h5 class="mb-5">
        Les paiements par carte bancaire sont-ils sécurisés ?
    </h5>

    <p>(cf partie Studio)</p>

    <h5 class="mb-5">
        Comment ajouter/supprimer une carte/compte bancaire ?
    </h5>

    <p>(cf partie Studio)</p>

    <h5 class="mb-5">
        (Comment faire si je n’ai pas reçu de confirmation par mail ?)
    </h5>

    <p>(cf partie Studio)</p>

    <h5 class="mb-5">

        Comment signaler un problème qui a eu lieu lors d’une prestation ?
    </h5>

    <p>(cf partie Studio)</p>

    <b>En cas d’autre question n’hésitez pas à nous contacter en cliquant sur le lien en bas de page “Contactez-nous” ou
        par mail à l’adresse hello@studiomap.fr</b>




</div>