import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ArtisteGuard implements CanActivate, CanLoad {
  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router
  ) { }
  canActivate(router: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<any> {
    if (router.url[0].path == "artiste" && this.tokenStorage.getToken()) {
      if (this.tokenStorage.getUser().type == "gerant") {
        localStorage.setItem('current_type', "artiste");
      }
    }
    if (localStorage.getItem('current_type') != "gerant") {
      return true;
    }
    this.router.navigate(['/gerant/tableau-de-bord']);
  }
  canLoad() {
    if (localStorage.getItem('current_type') != "gerant") {
      return true;
    }
    this.router.navigate(['/gerant/tableau-de-bord']);
  }
}
