import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CanAccessGuard implements CanLoad, CanActivate {
  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router
  ) { }
  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<any> {
    if (!!this.tokenStorage.getToken() && !!this.tokenStorage.getUser) {
      return true
    }
    let url = typeof window !== 'undefined' ? window.location.pathname : '';
    this.router.navigate(['/connexion'], {  queryParams: { url: url},   queryParamsHandling: 'merge' });
  }
  canLoad() {
    if (!!this.tokenStorage.getToken() && !!this.tokenStorage.getUser) {
      return true
    }
    this.router.navigate(['/connexion']);
  }
}
