<form [formGroup]="Form" (ngSubmit)="onSubmit()">
    <div class="col-xl-6 col-md-10 mx-auto container py-5">
        <h5>Réinitialiser votre mot de passe</h5>
        <div>
            <div class="col-12">
                <input [ngClass]="{'border-danger':f.password.errors&& submitted}" type="password" class="form-control" formControlName="password" placeholder="Mot de passe">
            </div>
    
            <div class="col-12">
                <input [ngClass]="{'border-danger': (f.confirmPassword.errors || Form.invalid) && submitted}" type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirmer votre mot de passe">
            </div>
            <div class="text-danger text-start" *ngIf="Form.value.confirmPassword!=Form.value.password && submitted">Les mots de passes ne sont pas identique</div>
    
            <button class="btn btn-danger mx-auto col-md-7 col-12 mt-4 text-uppercase"
                type="submit">Valider</button>
        </div>
        
    </div>
</form>