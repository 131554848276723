import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EmitterService } from 'src/app/services/emitter/emitter';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild('general') general: ElementRef;
  @ViewChild('utilisateur') user: ElementRef;
  @ViewChild('freelance') freelance: ElementRef;
  @ViewChild('studio') studio: ElementRef;
  constructor(private emitterService: EmitterService) { }

  ngOnInit(): void {
    this.emitterService.mode.emit("light mode");
  }

  scrollToGeneral(): void {
    this.general.nativeElement.scrollIntoView();
  }
  scrollToStudio(): void {
    this.studio.nativeElement.scrollIntoView();
  }
  scrollToUser(): void {
    this.user.nativeElement.scrollIntoView();
  }
  scrollToFreelance(): void {
    this.freelance.nativeElement.scrollIntoView();
  }

}
