import { Component, OnInit } from '@angular/core';
import { EmitterService } from 'src/app/services/emitter/emitter';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/auth/user.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ReservationsService } from 'src/app/services/reservations/reservations.service';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';

const TOKEN_KEY = 'auth-token';
const Abonnent_To_pay = 'abonnementToPay';
const USER_KEY = 'auth-user';
const current_type = 'current_type';
const choosen_type = 'choosen-type';
const TYPE = 'type';


@Component({
  selector: 'app-connect-with',
  templateUrl: './connect-with.component.html',
  styleUrls: ['./connect-with.component.css']
})
export class ConnectWithComponent implements OnInit {



  path = "";
  title: String = "Connexion en cours...";
  text: String = "veuillez patienter";
  constructor(private angularFireAuth: AngularFireAuth, private reservationService: ReservationsService, private profileService: ProfileService, private tokenService: TokenStorageService, private emitterService: EmitterService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private userService: UserService) { }

  ngOnInit(): void {
    /*
    localStorage.removeItem(Abonnent_To_pay);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(current_type);
    localStorage.removeItem(TYPE);
    localStorage.removeItem(choosen_type);
    this.angularFireAuth.signOut();
    */
    
    this.spinner.show();
    this.emitterService.mode.emit("light mode");

    this.route.queryParams.subscribe(params => {
      if (params.user && params.jwt) {
        this.userService.getMe(params.jwt).subscribe(res => {
          if (res.role.type == "super_admin") {
            this.userService.getById(params.user, params.jwt).subscribe(user => {
              this.tokenService.saveToken(params.jwt);
              let encapsulatedUser: any = {}
              encapsulatedUser = user;
              if (!user.stripe) {
                encapsulatedUser.stripe = { customer_id: "" };
              }
              if (user.type == "freelance") {
                encapsulatedUser.type = "artiste";
              }
              this.profileService.get_profile_by_id(user.profile.id).subscribe(profile => {
                encapsulatedUser.profile = profile;
                this.emitterService.getLoggedUser.emit(encapsulatedUser);
                this.tokenService.saveUser(encapsulatedUser);
                this.reservationService.salary_bilan(user.id).then((team: any) => {
                  (team.length > 0) ? this.emitterService.menu_has_salary_bilan.emit(true) : this.emitterService.menu_has_salary_bilan.emit(false);
                })
                this.reservationService.prestations_assigned_to_gerant(user.id).then((team: any) => {
                  (team.length > 0) ? this.emitterService.menu_has_calendar_prestation.emit(true) : this.emitterService.menu_has_calendar_prestation.emit(false);
                });
                
                if (this.isIFrame()) {
                  console.log("push data to webflow ....");
                  
                  parent.postMessage(JSON.stringify({ userData: user }), environment.webflowURL);
                }

                this.reditrection(user);
              })
            }, err => {
              this.spinner.hide();
              this.title = "Utilisateur non trouvé";
              this.text = "impossible d'effectuer cette opération";
              console.log("not authorized");
            });

          } else {
            this.spinner.hide();
            this.title = "Non autorisé";
            this.text = "impossible d'effectuer cette opération";
            console.log("not authorized");
          }

        }, err => {
          this.spinner.hide();
          this.title = "Non autorisé";
          this.text = "impossible d'effectuer cette opération";
          console.log("not authorized");

        });

      }

    });
  }

  reditrection(user: any) {
    localStorage.setItem('type', user.type);
    if (localStorage.getItem("reservation")) {
      let reservation = JSON.parse(localStorage.getItem("reservation"));
      if (user.type == 'gerant' || user.type == 'freelance') {
        localStorage.setItem('current_type', "artiste");
      }
      this.router.navigate(["/artiste/fiche-studio/" + reservation.studio]);
    } else {
      if (user.type == 'gerant' || user.type == 'freelance') {
        localStorage.setItem('current_type', user.type);
      }
      switch (user.type) {
        case "artiste":
          this.path = "/" + user.type + "/reserver-studio";
          break;
        case "gerant":
          this.path = "/" + user.type + "/tableau-de-bord";
          break;
        case "freelance":
          this.path = "/" + user.type + "r/tableau-de-bord";
          break;
      }
      this.router.navigate([this.path]);
      this.spinner.hide();
    }
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

}
