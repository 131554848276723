import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { ConnexionComponent } from './shared/modules/connexion/connexion.component';
import { ProfilComponent } from './shared/modules/profil/profil.component';
import { InscriptionComponent } from './shared/modules/inscription/inscription.component';
import { CanAccessGuard } from './services/guards/can-access.guard';
import { ConnectedGuard } from './services/guards/connected.guard';
import { HomeComponent } from './modules/artiste/home/home.component';
import { ConfirmationPageComponent } from './shared/modules/confirmation-page/confirmation-page.component';
import { ResetPasswordComponent } from './shared/modules/reset-password/reset-password.component';
import { ArtisteGuard } from './services/guards/artiste.guard';
import { CguComponent } from './shared/modules/cgu/cgu.component';
import { ConnectWithComponent } from './shared/modules/connect-with/connect-with.component';
import { MentionsLegalesComponent } from './shared/modules/mentions-legales/mentions-legales.component';
import { CharteStudioMapComponent } from './shared/modules/charte-studio-map/charte-studio-map.component';
import { ContactComponent } from './shared/modules/contact/contact.component';
import { ChooseTypeUserComponent } from './shared/modules/choose-type-user/choose-type-user.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ParrainerAmiComponent } from './shared/modules/parrainer-ami/parrainer-ami.component';
import { MessagerieComponent } from './shared/modules/messagerie/messagerie.component';
import { SettingsComponent } from './shared/modules/settings/settings.component';
import { PaypalResponseComponent } from './shared/paypal-response/paypal-response.component';
import { InscriptionSocialComponent } from './shared/modules/inscription-social/inscription-social.component';
import { ChooseInscriptionTypeComponent } from './shared/modules/choose-inscription-type/choose-inscription-type.component';
import { CheckTokenGuard } from './services/guards/check-token.guard';
import { PolitiqueCookiesComponent } from './shared/modules/politique-cookies/politique-cookies.component';
import { ConditionsGeneralesVenteComponent } from './shared/modules/conditions-generales-vente/conditions-generales-vente.component';
import { PolitiqueConfidentialiteComponent } from './shared/modules/politique-confidentialite/politique-confidentialite.component';
import { BookingContainerComponent } from './modules/artiste/booking-container/booking-container.component';


const routes: Routes = [
  // default page
  {
    path: '', canActivate: [ConnectedGuard] , loadChildren: () => import('./modules/artiste/reserver-studio/reserver-studio.module').then(m => m.ReserverStudioModule)
  },

  // load typeform
  { path: 'form/:id', loadChildren: () => import('./modules/artiste/model-type-form/model-type-form.module').then(m => m.ModelTypeFormModule) },

  // must be connected modules
  { path: 'profil', component: ProfilComponent, canActivate: [CanAccessGuard,CheckTokenGuard] },
  { path: 'parrainer-ami', component: ParrainerAmiComponent, canActivate: [CanAccessGuard,CheckTokenGuard] },
  { path: 'messagerie', component: MessagerieComponent, canActivate: [CanAccessGuard,CheckTokenGuard] },
  { path: 'parametres', component: SettingsComponent, canActivate: [CanAccessGuard,CheckTokenGuard] },
  // should be not connected moduldes
  { path: 'connexion', component: ConnexionComponent, canActivate: [ConnectedGuard] },
  { path: 'inscription-type', component: ChooseInscriptionTypeComponent, canActivate: [ConnectedGuard] },
  { path: 'inscription', component: InscriptionComponent, canActivate: [ConnectedGuard] },
  { path: 'social-inscription', component: InscriptionSocialComponent, canActivate: [ConnectedGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [ConnectedGuard] },

  // landing page
  { path: 'contact', component: ContactComponent },
  { path: 'politique-confidentialite', component: PolitiqueConfidentialiteComponent },
  { path: 'cgu', component: CguComponent },
  { path: 'cgv', component: ConditionsGeneralesVenteComponent },
  { path: 'cookies', component: PolitiqueCookiesComponent },
  { path: 'charte-studio-map', component: CharteStudioMapComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent },
  { path: 'choose-user-type', component: ChooseTypeUserComponent },
  { path: 'connect-with', component: ConnectWithComponent },

  // shared
  { path: 'success-paypal', component: PaypalResponseComponent },
  { path: 'cancel-paypal', component: PaypalResponseComponent },
  { path: 'confirmation', component: ConfirmationPageComponent },

  // lazy loadings
  { path: 'gerant', canLoad: [CanAccessGuard], loadChildren: () => import('./modules/gerant-studio/gerant-studio.module').then(m => m.GerantStudioModule) },
  { path: 'freelancer', canLoad: [CanAccessGuard], loadChildren: () => import('./modules/freelance/freelance.module').then(m => m.FreelanceModule) },
  { path: 'artiste', canActivate: [ArtisteGuard], loadChildren: () => import('./modules/artiste/artiste.module').then(m => m.ArtisteModule) },
  { path: 'passculture', loadChildren: () => import('./modules/artiste/passculture-page/passculture-page.module').then(m => m.PassculturePageModule) },
  { path: 'confirmation-reservation', loadChildren: () => import('./modules/gerant-studio/confirmation/confirmation.module').then(m => m.ConfirmationModule) },
  { path: 'payment-reservation', loadChildren: () => import('./modules/artiste/offline-payment/offline-payment.module').then(m => m.OfflinePaymentModule) },
  { path: 'booking-container', component:BookingContainerComponent },
  // example to use booking-container 
  // https://dev.studiomap.fr/booking-container?studio=63978e44b9610a001ac17cb3&typeStudio=ENREGISTREMENT
  {
    path: '**',
    component: RedirectComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled'
  }),
    ReactiveFormsModule,
    FormsModule],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }


