import { Component, Input, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { MenuSwitchService } from 'src/app/services/menu-swicth/menu-switch.service';

@Component({
  selector: 'app-light-connected-nav',
  templateUrl: './light-connected-nav.component.html',
  styleUrls: ['./light-connected-nav.component.css']
})
export class LightConnectedNavComponent implements OnInit {
  @Input() image:any;
  @Input() switch_button_text:any;
  @Input() showProfileMenu:boolean;
  user:any;
  currentType=localStorage.getItem('type');
  selectedType=localStorage.getItem('current_type');
  
  constructor(private menuService:MenuSwitchService,private tokenStorageService:TokenStorageService) { }

  menu_switcher(){
    this.menuService.switch();
  }

  comming_soon() {
    var myModal = document.getElementById('openCSModal')
    myModal.click();
  }
  
  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
  }

}
