import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
const CURRENT_POSITION = 'current_position';

@Injectable({
  providedIn: 'root'
})

export class MapService {

  constructor() {}
  
  getMyLocation(): Observable<any> {
    return Observable.create(observer => {
      if(typeof window !== 'undefined' && window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next(position);
            observer.complete();
          },
          (error) => observer.error(error)
        );
      } else {
        observer.error('Unsupported Browser');
      }
    });
  }
   
 }
