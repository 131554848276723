import { User } from "./user.model";

export class Profile {
    all_city: any;
    isSponsored: boolean;
    is_draft: boolean;
    full_name: string;
    phone: string;
    adresse: string;
    lat: string;
    lng: string;
    type_service: string;
    user: User;
    description: string;
    name: string;
    avatar: any;
    id: string

    constructor(data: Partial<Profile>) {
        Object.assign(this, data);
    }
}