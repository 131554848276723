<div class="card mx-auto container p-5 my-5 bg-transparent" style="max-width: 400px;" *ngIf="step == 0">
    <h5 class="fw-bold mb-4">INSCRIPTION</h5>

    <button class="btn border btn-white py-3" type="button" (click)="nextStep('phone')">Numéro téléphone</button>
    <button class="btn border btn-white mt-3 py-3" (click)="nextStep('email')">email et mot de passe</button>
    <button class="btn border btn-white mt-3 pe-4" type="button" (click)="signInWithFB()">
        <img class="me-2" height="30" width="30" src="assets/icons/facebook-btn.svg" alt="facebook"> Facebook
    </button>
    <button class="btn border btn-white mt-3 pe-5" (click)="signInWithGoogle()" alt="google login" type="button">
        <img class="me-2" height="25" width="25" src="assets/icons/google-btn.svg" alt="google"> Google
    </button>
    <button *ngIf="isIFrame()" type="button" class="btn btn-dark-gray w-100 text-uppercase mt-2" (click)="back()"><i
            class="fa fa-chevron-circle-left me-2 pointer"></i> Précédent</button>

</div>
<div class="card mx-auto container p-5 my-5 bg-transparent" style="max-width: 400px;" *ngIf="step == 1">
    <h5 class="fw-bold">Je m'inscris en tant que :</h5>
    <button
        style="background: url(/assets/inscription-buttons/artiste.jpg);background-size:cover;background-position: center;height: 100px;"
        (click)="nextStep(modeInscription,'artiste')" class="text-white btn text-uppercase"
        type="button"><b>artiste</b></button>
    <button
        style="background: url(/assets/inscription-buttons/studio.jpg);background-size:cover;background-position: center;height: 100px;"
        (click)="nextStep(modeInscription,'gerant')" class="text-white btn text-uppercase my-3"
        type="button"><b>studio</b></button>
    <button type="button" (click)="back()" class="btn btn-dark-gray w-100 text-uppercase"><i
            class="fa fa-chevron-circle-left me-2 pointer"></i> Précédent</button>
</div>
<div class="col-md-8 mx-auto text-start container pb-5" *ngIf="step == 2 && modeInscription == 'email'">
    <div class="py-lg-5">
        <i (click)="back()" class="fa fa-chevron-left mb-5 pointer float-start me-2 m-1"></i>
        <h5>INSCRIPTION</h5>
        <form [formGroup]="Form" (ngSubmit)="onSubmit()">
            <div class="row g-3">
                <div class="col-12">
                    <input type="text" [ngClass]="{'border-danger':f.full_name.errors && submitted}"
                        class="form-control" id="prenom" placeholder="Pseudo" formControlName="full_name">
                </div>


                <div class="col-12">
                    <label for="email" class="form-label">Adresse email</label>
                    <input type="email" class="form-control" [ngClass]="{'border-danger':f.email.errors && submitted}"
                        formControlName="email" placeholder="exemple@mail.com">
                    <div class="invalid-feedback">
                        Please enter a valid email address for shipping updates.
                    </div>
                </div>
                <div *ngIf="Form.value.type=='freelance'">
                    <div class="col-12">
                        <label for="email" class="form-label">Adresse</label>
                        <input [ngClass]="{'border-danger':f.adresse.errors && submitted}" type="text"
                            class="form-control" formControlName="adresse" placeholder="Adresse" #placesRef
                            ngx-google-places-autocomplete [options]='options'
                            (onAddressChange)="handleAddressChange($event)" placeholder="Entrer adresse">
                    </div>
                    <div class="col-md-12 mt-3">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" formControlName="all_city">
                            <label for="">Disponible partout en France</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <label for="country" class="form-label">Numéro de téléphone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-phone">+33</span>
                        </div>
                        <input type="text" class="form-control col-8" mask="(00) 00000000||000000000" formControlName="phone"
                            placeholder="Téléphone" [ngClass]="{'border-danger':f.phone.errors && submitted}"
                            formControlName="phone">
                    </div>
                </div>

                <div class="col-md-6">
                    <label for="passe" class="form-label">Mot de passe</label>
                    <input [ngClass]="{'border-danger':f.password.errors && submitted}" formControlName="password"
                        type="password" class="form-control col-md-4" placeholder="Taper un mot de passe">
                    <input [ngClass]="{'border-danger':f.confirmPassword.errors && submitted}"
                        formControlName="confirmPassword" type="password" class="form-control col-md-4"
                        placeholder="Confirmer le mot de passe">
                    <div class="text-danger" *ngIf="Form.value.confirmPassword!=Form.value.password && submitted">Les
                        mots de passes ne sont pas identique</div>
                </div>
                <div class="col-md-6">
                    <label for="passe" class="form-label">J'utilise Studiomap pour</label>
                    <select (change)="select()" formControlName="type" class="form-select">
                        <option value="artiste">Trouver un studio.</option>
                        <option value="gerant">Gérer mon studio.</option>
                    </select>
                    <select *ngIf="Form.value.type=='freelance'" formControlName="type_service" class="form-select">
                        <option *ngFor='let service of type_service' [value]="service.name">{{service.name}}</option>
                    </select>
                </div>
            </div>

            <div class="form-check mt-2">
                <input formControlName="cgu" type="checkbox" class="form-check-input">
                <label class="form-check-label link">Je reconnais avoir lu et accepte <a routerLink="/cgu"
                        class="text-primary"> les Conditions Générales d'Utilisation.</a></label>
            </div>
            <div class="form-check mt-2">
                <input formControlName="csm" type="checkbox" class="form-check-input">
                <label class="form-check-label link">j'ai lu et m'engage à respecter <a routerLink="/charte-studio-map"
                        class="text-primary"> la charte Studiomap</a> et celle du studio dans lequel je me rends</label>
            </div>
            <div class="col-md-4 mt-4">
                <button [disabled]="!Form.value.cgu || !Form.value.csm" class="btn btn-danger w-100"
                    type="submit">CONTINUER</button>
            </div>
        </form>
    </div>
</div>

<div class="col-md-8 mx-auto text-start container pb-5" *ngIf="step == 2 && modeInscription == 'social'">
    <div class="py-lg-5">
        <i (click)="back()" class="fa fa-chevron-left mb-5 pointer float-start me-2 m-1"></i>
        <h5>INSCRIPTION</h5>
        <form [formGroup]="FormSocial" (ngSubmit)="onSubmitSocial()" class="col-md-8 mx-auto">
            <div class="row g-3">
                <div class="col-lg-12">
                    <label for="country" class="form-label">Numéro de téléphone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-phone" style="padding: 0.7rem 0.75rem;">+33</span>
                        </div>
                        <input type="text" class="form-control col-8" mask="(00) 00000000||000000000" formControlName="phone"
                            placeholder="Téléphone" [ngClass]="{'border-danger':fSocial.phone.errors && submitted}"
                            formControlName="phone">
                    </div>
                </div>
            </div>
            <div class="form-check mt-2">
                <input formControlName="cgu" type="checkbox" class="form-check-input">
                <label class="form-check-label link">Je reconnais avoir lu et accepte <a routerLink="/cgu"
                        class="text-primary"> les Conditions Générales d'Utilisation.</a></label>
            </div>
            <div class="form-check mt-2">
                <input formControlName="csm" type="checkbox" class="form-check-input">
                <label class="form-check-label link">j'ai lu et m'engage à respecter <a routerLink="/charte-studio-map"
                        class="text-primary"> la charte Studiomap</a> et celle du studio dans lequel je me rends</label>
            </div>
            <div class="col-md-4 mt-4">
                <button [disabled]="!FormSocial.value.cgu" class="btn btn-danger w-100" type="submit">CONTINUER</button>
            </div>
        </form>
    </div>
</div>




<div *ngIf="stepLoginPhone == 'send-code' && modeInscription == 'phone' && step == 2 " class="p-3">
    <div class="card mx-auto shadow-sm-none container p-md-5 my-5 bg-transparent" style="max-width: 400px;">
        <h5 class="fw-bold mb-4">INSCRIPTION</h5>
        <p class="mb-3">inscrivez-vous avec votre <span class="text-danger">numéro de téléphone</span></p>

        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text input-phone">+33</span>
                </div>
                <input [(ngModel)]="phoneNumber" type="tel" id="phone" name="phone" class="form-control"
                    placeholder="Numéro de téléphone" required mask="(00) 00000000||000000000">
            </div>
            <div class="input-group">
                <input type="text" class="form-control" id="prenom" placeholder="Pseudo" [(ngModel)]="fullName"
                    required>
            </div>
            <div class="input-group">
                <input type="email" class="form-control" [(ngModel)]="email" placeholder="exemple@mail.com" required>
            </div>
        </div>

        <button class="btn btn-danger text-uppercase" (click)="sendCodeConfirmation()" type="button"
            *ngIf="!loadingRegisterWithPhone">continuer</button>
        <button (click)="back()" type="button" class="btn btn-dark-gray w-100 text-uppercase mt-2"
            *ngIf="!loadingRegisterWithPhone"><i class="fa fa-chevron-circle-left me-2 pointer"></i> Précédent</button>
        <button class="w-100 btn rounded btn-lg btn-secondary btn-outline-dark" *ngIf="loadingRegisterWithPhone"><i
                class="fa fa-spinner fa-spin"></i>
            Chargement...</button>
    </div>
</div>

<div *ngIf="stepLoginPhone == 'validation-code' && modeInscription == 'phone' && step == 2 " class="p-3">
    <div class="card mx-auto shadow-sm-none container p-md-5 my-5 bg-transparent" style="max-width: 400px;">
        <h5 class="fw-bold mb-4">INSCRIPTION</h5>
        <p class="mb-3">inscrivez-vous avec votre <span class="text-danger">numéro de téléphone</span></p>
        <input [(ngModel)]="varificationCode" type="text" class="form-control border" placeholder="Code de validation">
        <button class="btn btn-danger text-uppercase" (click)="nextActionSignInWithPhone()" type="button"
            *ngIf="!loadingRegisterWithPhone">valider</button>
        <button (click)="back()" type="button" class="btn btn-dark-gray w-100 text-uppercase mt-2"
            *ngIf="!loadingRegisterWithPhone"><i class="fa fa-chevron-circle-left me-2 pointer"></i> Précédent</button>
        <button class="w-100 btn rounded btn-lg btn-secondary btn-outline-dark" *ngIf="loadingRegisterWithPhone"><i
                class="fa fa-spinner fa-spin"></i>
            Chargement...</button>
    </div>
</div>