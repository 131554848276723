import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { TokenStorageService } from './services/auth/token-storage.service';
import { AuthService } from './services/auth/auth.service';
import { EmitterService } from './services/emitter/emitter';
import { ProfileService } from './services/profile/profile.service';
import { environment } from 'src/environments/environment';
import { MapService } from './services/map/map.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MetaService } from './services/meta/meta.service';
import { HttpClient } from '@angular/common/http';
import { ReservationsService } from './services/reservations/reservations.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from './services/seo/seo.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ContactService } from './services/contact/contact.service';
import { Contact } from './classes/contact.model';
import { PassCultureService } from './services/pass-culture/pass-culture.service';
import { MenuSwitchService } from 'src/app/services/menu-swicth/menu-switch.service';
declare let gtag: Function;
declare let fbq: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {

  submitFormClient: boolean = false;
  submitFormClientQuantity = ["1-100", "100-1000", "1000 - 10000", "> 10000"];
  submitFormFreelance: boolean = false;
  FormClient: FormGroup;
  FormFreelance: FormGroup;

  // auth
  showProfileMenu: boolean = false;
  currentType = localStorage.getItem('type');
  selectedType = localStorage.getItem('current_type');
  isLoggedIn = false;
  title = 'studio-map';
  full_name = "";
  version = 'Angular version 11.2.4';
  items = [];
  currentMode: any = "";
  image: any = "";
  switch_button_text: any = "";
  menu_inject_bilan = false;
  menu_inject_calendar_prestations = false;
  imageSelected = "bg-1";
  imagesHome = ["bg-1", "bg-2", "bg-3", "bg-4", "bg-5", "bg-6"];
  @ViewChild('modal', { static: false }) _modal: any;

  constructor(
    private http: HttpClient,
    private metaService: MetaService,
    private mapService: MapService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdRef: ChangeDetectorRef,
    public router: Router,
    public authService: AuthService,
    public profileService: ProfileService,
    public spinner: NgxSpinnerService,
    public renderer: Renderer2,
    private menuService: MenuSwitchService,
    public emitterService: EmitterService,
    public reservationService: ReservationsService,
    private tokenStorageService: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private fb: FormBuilder,
    private contactService: ContactService,
    private passService: PassCultureService,
    private elementRef: ElementRef
  ) {
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        /*if (isPlatformBrowser(this.platformId)) {
          gtag('config', 'AW-10780673873', { 'page_path': y.url });
          fbq('track', 'PageView');
        }*/
      }
    })
    this.FormClient = this.fb.group({
      full_name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      entreprise: ['', Validators.required],
      besoin: ['Photographe', Validators.required],
      quantite: ['1-100', Validators.required],
    });

    this.FormFreelance = this.fb.group({
      full_name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      portfolio: ['', Validators.required],
      domaine: ['Photographie', Validators.required]
    });

  }


  async ngOnInit() {
    this.getHomeImages();
    this.items = []
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.image = environment.defaultAvatar;
    if (!this.isLoggedIn) {
      this.items.push(
        { name: 'Faire une réservation', link: '/artiste/reserver-studio' },
        { name: 'Créer un compte', link: '/connexion' },
      )
      // update webflow website
      if (this.isIFrame())
        parent.postMessage(JSON.stringify({ userData: {} }), environment.webflowURL);
    }


    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.full_name = user.profile.full_name;
      // update webflow website
      if (this.isIFrame())
        parent.postMessage(JSON.stringify({ userData: user }), environment.webflowURL);

      this.hasToken(user);
      this.reservationService.salary_bilan(user.id).then((team: any) => {
        (team.length > 0) ? this.emitterService.menu_has_salary_bilan.emit(true) : this.emitterService.menu_has_salary_bilan.emit(false);
      })
      this.reservationService.prestations_assigned_to_gerant(user.id).then((team: any) => {
        (team.length > 0) ? this.emitterService.menu_has_calendar_prestation.emit(true) : this.emitterService.menu_has_calendar_prestation.emit(false);
      })
    }
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

  menu_switcher() {
    this.menuService.switch();
  }

  @HostListener('window:message', ['$event'])
  receiveMessageFromParent(event: MessageEvent) {
    if (event.origin !== environment.webflowURL) {
      return; // Reject messages from untrusted sources
    }

    // Access the message data sent from the parent
    const message = event.data;
    switch (message) {
      case 'showMenu':
        this.renderer.addClass(this.document.getElementById('bd-docs-nav'), 'show');
        break;
      case 'showProfileMenu':
        this.showProfileMenu = !this.showProfileMenu;
        break;
      default:
        break;
    }


    // Emit an event to communicate within the Angular app
    // You can define your own event names and payload structure
    // For simplicity, let's emit a simple string event
    // this.messageFromParent.emit(message);
  }

  onActivate(event) {
    // ssr window or document is not define issue
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  onSelectMenu(link: any): void {
    if (link == "open pass modal") {
      this.document.getElementById("openPassCutureModal").click();
      return
    }

    // ssr window or document is not define issue
    if (isPlatformBrowser(this.platformId)) {
      const element = this.document.getElementById('bd-docs-nav');
      this.renderer.removeClass(element, 'show');
      const route = '/' + link;
      this.router.navigate([route]);
    }
  }

  logout(): void {
    // ssr window or document is not define issue
    if (isPlatformBrowser(this.platformId)) {
      const element = this.document.getElementById('bd-docs-nav');
      this.renderer.removeClass(element, 'show');
      this.tokenStorageService.signOut();
      // menu
      this.items = [];
      this.items.push(
        { name: 'Faire une réservation', link: '/artiste/reserver-studio', icon: 'icon-dashboard fs-4 pe-2' },
        { name: 'Créer un compte', link: '/connexion' },
      )
      this.isLoggedIn = !!this.tokenStorageService.getToken();
    }
  }

  private hasToken(user) {
    this.isLoggedIn = true;
    this.image = (user.profile?.avatar?.url) ? user.profile.avatar.url : environment.defaultAvatar;
    if (this.image.indexOf('http') == -1) this.image = environment.api.slice(0, -1) + this.image;
  }
  private getCurrentMode(mode: any): void {
    this.currentMode = mode;
  }

  getHomeImages() {
    const randomIndex = Math.floor(Math.random() * this.imagesHome.length);
    this.imageSelected = this.imagesHome[randomIndex];
    this.emitterService.background = this.imageSelected;
  }

  menuInjectionBilan(hasBilan: boolean) {
    this.menu_inject_bilan = hasBilan;
  }

  menuInjectionCalendar(hasCalendar: boolean) {
    this.menu_inject_calendar_prestations = hasCalendar;
  }

  ngAfterViewChecked() {
    if (!this.tokenStorageService.getToken()) {
      this.isLoggedIn = false;
    }
    this.emitterService.getLoggedUser.subscribe(user => this.hasToken(user));

    // title & meta
    this.emitterService.mode.subscribe(mode => this.getCurrentMode(mode));
    this.setMenu((localStorage.getItem('current_type')) ? localStorage.getItem('current_type') : localStorage.getItem('type'));
    this.emitterService.menu_has_calendar_prestation.subscribe(hasCalendar => this.menuInjectionCalendar(hasCalendar));
    this.emitterService.menu_has_salary_bilan.subscribe(hasBilan => this.menuInjectionBilan(hasBilan));
    // this.emitterService.encapsulated_meta_infos.subscribe(meta_infos => this.metaService.updateMeta(meta_infos));
    // this.emitterService.meta_title.subscribe(title => this.metaService.setTitle(title));
    this.cdRef.detectChanges();
  }

  setMenu(type: string) {
    /*
    this.items = [
      { name: 'Mon profil', link: 'profil', icon: 'icon-profile fs-4 pe-2' },
      { name: 'Mes moyens de paiement', link: 'gerant/mes-moyens-paiement', icon: 'icon-payment fs-4 pe-2' },
      { name: 'Paramètres', link: '/parametres', icon: 'icon-cogs fs-4 pe-2' },
      { name: 'Parrainer un ami', link: '/parrainer-ami', icon: 'icon-sponsor fs-4 pe-2' },
    ];
    */
    this.items = [];

    switch (type) {
      case 'gerant':
        this.switch_button_text = "RECHERCHER DES STUDIOS";
        this.items.push(
          { name: '', icon: 'dropdown-divider' },
          { name: 'tableau de bord', link: 'gerant/tableau-de-bord', icon: 'icon-dashboard fs-4 pe-2' },
          { name: 'Mon profil', link: 'profil', icon: 'icon-profile fs-4 pe-2' },
          { name: 'Mes moyens de paiement', link: 'gerant/mes-moyens-paiement', icon: 'icon-payment fs-4 pe-2' },
          { name: 'mes studios', link: 'gerant/mes-studios', icon: 'icon-mic fs-4 pe-2' },
          { name: 'mon planning', link: 'gerant/mon-planing', icon: 'icon-planing fs-4 pe-2' },
          { name: 'mon équipe', link: 'gerant/mon-equipe', icon: 'icon-team fs-4 pe-2' },
          { name: 'Paramètres', link: '/parametres', icon: 'icon-cogs fs-4 pe-2' },
          //{ name: 'messagerie', link: 'messagerie', icon: 'icon-chat fs-4 pe-2' },
          // { name: 'mes factures ', link: 'gerant/mes-factures', icon: 'icon-receipt fs-4 pe-2' },
          { name: 'contactez-nous ', link: 'contact', icon: 'icon-contact fs-4 pe-2' },
        )
        // inject bilan & calendrier if not empty
        if (this.menu_inject_bilan) {
          this.items.splice(6, 0, { name: 'bilan mes prestations', link: 'gerant/mes-prestations', icon: 'icon-receipt fs-4 pe-2' })
        }
        if (this.menu_inject_calendar_prestations) {
          this.items.splice(6, 0, { name: 'calendrier mes prestations', link: 'gerant/calendrier-mes-prestations', icon: 'icon-planing fs-4 pe-2' })
        }
        break;
      case 'artiste':
        this.switch_button_text = "PASSER EN MODE " + localStorage.getItem('type');
        this.items.push(

          //{ name: 'Pass Culture', link: "open pass modal", icon: 'pass-logo fs-4 me-2', class: "pass-culture-gradient text-white " },
          //{ name: 'Faire une réservation', link: '/artiste/reserver-studio', icon: 'icon-dashboard fs-4 pe-2' },
          { name: 'Mes réservations', link: 'artiste/reservations', icon: 'icon-planing fs-4 pe-2' },
          { name: 'Mon profil', link: 'profil', icon: 'icon-profile fs-4 pe-2' },
          { name: 'Mes moyens de paiement', link: 'gerant/mes-moyens-paiement', icon: 'icon-payment fs-4 pe-2' },
          { name: 'Paramètres', link: '/parametres', icon: 'icon-cogs fs-4 pe-2' },
          { name: 'Parrainer un ami', link: '/parrainer-ami', icon: 'icon-sponsor fs-4 pe-2' },
          //{ name: 'messagerie', link: 'messagerie', icon: 'icon-chat fs-4 pe-2' },
          // { name: 'bilan prestations', action: 'comming_soon', icon: 'icon-receipt fs-4 pe-2' },
          { name: 'contactez-nous ', link: 'contact', icon: 'icon-contact fs-4 pe-2' },
        )
        if (this.menu_inject_calendar_prestations) {
          this.items.splice(3, 0, { name: 'calendrier mes prestations', link: 'gerant/calendrier-mes-prestations', icon: 'icon-planing fs-4 pe-2' })
        }
        break;
      case 'freelance':
        this.switch_button_text = "RECHERCHER DES STUDIOS";
        this.items.push(
          { name: 'Tableau de bord', link: 'freelancer/tableau-de-bord', icon: 'icon-dashboard fs-4 pe-2' },
          { name: 'mes prestations', link: 'freelancer/prestations', icon: 'icon-receipt fs-4 pe-2' },
          // { name: 'mes missions', action: 'comming_soon', icon: 'icon-receipt fs-4 pe-2' },
          { name: 'mon portfolio', link: 'freelancer/portfolio', icon: 'icon-profile fs-4 pe-2' },
          // { name: 'mes clients', action: 'comming_soon', icon: 'icon-cogs fs-4 pe-2' },
          { name: 'messagerie', link: 'messagerie', icon: 'icon-chat fs-4 pe-2' },
          // { name: 'bilan prestations', action: 'comming_soon', icon: 'icon-receipt fs-4 pe-2' },
          { name: 'contactez-nous ', link: 'contact', icon: 'icon-contact fs-4 pe-2' },
        )
        if (this.menu_inject_calendar_prestations) {
          this.items.splice(6, 0, { name: 'calendrier mes prestations', link: 'gerant/calendrier-mes-prestations', icon: 'icon-planing fs-4 pe-2' })
        }
        break;
    }
  }

  switch(action: string) {
    if (action == 'comming_soon') {
      // ssr window or document is not define issue
      if (isPlatformBrowser(this.platformId)) {
        var myModal = this.document.getElementById('openCSModal')
        myModal.click();
      }
    }
  }

  closeById(id: string) {
    // ssr window or document is not define issue
    if (isPlatformBrowser(this.platformId)) {
      let toggleButton: HTMLElement = this.document.getElementById(id) as HTMLElement;
      toggleButton.click();
    }
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  onSubmitFormClient() {
    this.submitFormClient = true;
    if (this.FormClient.invalid) {
      Swal.fire('Error', 'Veuillez vérifier vos informations', 'error')
      return
    }

    this.spinner.show();

    this.contactService.send(new Contact(
      this.client.full_name.value,
      this.client.email.value,
      this.client.phone.value,
      "Prendre contact",
      "<b>Téléphone :</b> 0" + this.client.phone.value + " <br> <b>Entreprise :</b> " + this.client.entreprise.value + " <br> <b>Un Besoin en :</b> " + this.client.besoin.value + " <br> <b>Quantité : </b> " + this.client.quantite.value,
      null,
      false)).subscribe(res => {

        this.closeFormClient();
        Swal.fire('Envoyé', 'votre message a bien été envoyé', 'success');
      }, err => {

      }).add(() => {
        this.spinner.hide();
      });

  }

  onSubmitFormFreelance() {
    this.submitFormFreelance = true;
    if (this.FormFreelance.invalid) {
      Swal.fire('Error', 'Veuillez vérifier vos informations', 'error')
      return
    }

    this.spinner.show();

    this.contactService.send(new Contact(
      this.freelance.full_name.value,
      this.freelance.email.value,
      this.freelance.phone.value,
      "Devenir partenaire",
      "<b>Téléphone :</b> 0" + this.freelance.phone.value + " <br> <b>Domaine d’activité :</b> " + this.freelance.domaine.value + " <br> <b>portfolio :</b> " + this.freelance.portfolio.value,
      null,
      false)).subscribe(res => {
        this.closeFormFreelance();


        Swal.fire('Envoyé', 'votre message a bien été envoyé', 'success');
      }, err => {

      }).add(() => {
        this.spinner.hide();
      });
  }

  get client() {
    return this.FormClient.controls;
  }

  get freelance() {
    return this.FormFreelance.controls;
  }

  modalAction(id: string) {
    if (isPlatformBrowser(this.platformId)) {
      var myModal = document.getElementById(id)
      myModal.click();
    }
  }

  closeFormFreelance() {
    this.modalAction("close-form-freelance");
    this.submitFormFreelance = false;
    this.FormFreelance.reset();
  }

  closeFormClient() {
    this.modalAction("close-form-client");
    this.submitFormClient = false;
    this.FormClient.reset();
  }

  select(value: any) {
    switch (value) {
      case "Photographe":
        this.submitFormClientQuantity = ["1-100", "100-1000", "1000 - 10000", "> 10000"];
        break;
      case "Vidéaste":
        this.submitFormClientQuantity = ["1 vidéo/motion design", "1-6 vidéo/motion design", "< 6 vidéo/motion design"];
        break;
      case "Modele":
        this.submitFormClientQuantity = ["1 modèle", "1-10 modèles", "> 10 modèles"];
        break;
    }
    this.FormClient.controls['quantite'].setValue(this.submitFormClientQuantity[0]);
  }

}

