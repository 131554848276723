import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ProfilComponent } from './shared/modules/profil/profil.component';
import { ConnexionComponent } from './shared/modules/connexion/connexion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InscriptionComponent } from './shared/modules/inscription/inscription.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShimmerModule } from './shared/injected-components/shimmer/shimmer.module';
import { DefaultNavComponent } from './shared/modules/navs/default-nav/default-nav.component';
import { DarkDefaultNavComponent } from './shared/modules/navs/dark-default-nav/dark-default-nav.component';
import { GerantNavComponent } from './shared/modules/navs/dark-connected-nav/dark-connected-nav.component';
import { LightConnectedNavComponent } from './shared/modules/navs/light-connected-nav/light-connected-nav.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ResetPasswordComponent } from './shared/modules/reset-password/reset-password.component';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CguComponent } from './shared/modules/cgu/cgu.component';
import { MentionsLegalesComponent } from './shared/modules/mentions-legales/mentions-legales.component';
import { CharteStudioMapComponent } from './shared/modules/charte-studio-map/charte-studio-map.component';
import { ContactComponent } from './shared/modules/contact/contact.component';
import { ChooseTypeUserComponent } from './shared/modules/choose-type-user/choose-type-user.component';
import { ConnectWithComponent } from './shared/modules/connect-with/connect-with.component';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from 'angularx-social-login';
import { RedirectComponent } from './redirect/redirect.component';
import { FaqComponent } from './shared/modules/faq/faq.component';
import { ParrainerAmiModule } from './shared/modules/parrainer-ami/parrainer-ami.module';
import { MessagerieComponent } from './shared/modules/messagerie/messagerie.component';
// firebase@
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { SettingsComponent } from './shared/modules/settings/settings.component';
import { PaypalResponseComponent } from './shared/paypal-response/paypal-response.component';
import { InscriptionSocialComponent } from './shared/modules/inscription-social/inscription-social.component';
import { ChooseInscriptionTypeComponent } from './shared/modules/choose-inscription-type/choose-inscription-type.component';
import { ConditionsGeneralesVenteComponent } from './shared/modules/conditions-generales-vente/conditions-generales-vente.component';
import { PolitiqueCookiesComponent } from './shared/modules/politique-cookies/politique-cookies.component';
import { PolitiqueConfidentialiteComponent } from './shared/modules/politique-confidentialite/politique-confidentialite.component';
import { PassCultureModalModule } from './shared/modules/modals/pass-culture-modal/pass-culture-modal.module';
import { BookingContainerModule } from './modules/artiste/booking-container/booking-container.module';

const I18N_VALUES = {
  'fr': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    weekLabel: 'sem'
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) { super(); }

  getWeekdayShortName(weekday: number): string { return I18N_VALUES[this._i18n.language].weekdays[weekday - 1]; }
  getWeekLabel(): string { return I18N_VALUES[this._i18n.language].weekLabel; }
  getMonthShortName(month: number): string { return I18N_VALUES[this._i18n.language].months[month - 1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}


@NgModule({
  declarations: [
    AppComponent,
    ProfilComponent,
    ConnexionComponent,
    InscriptionComponent,
    DefaultNavComponent,
    GerantNavComponent,
    DarkDefaultNavComponent,
    LightConnectedNavComponent,
    ResetPasswordComponent,
    CguComponent,
    ConnectWithComponent,
    MentionsLegalesComponent,
    CharteStudioMapComponent,
    ContactComponent,
    ChooseTypeUserComponent,
    RedirectComponent,
    FaqComponent,
    MessagerieComponent,
    SettingsComponent,
    PaypalResponseComponent,
    InscriptionSocialComponent,
    ChooseInscriptionTypeComponent,
    ConditionsGeneralesVenteComponent,
    PolitiqueCookiesComponent,
    PolitiqueConfidentialiteComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    ShimmerModule,
    GooglePlaceModule,
    SocialLoginModule,
    ParrainerAmiModule,
    AngularFireModule.initializeApp(environment.firebase),
    PassCultureModalModule,
    BookingContainerModule
  ],

  providers:
    [
      I18n,
      { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                environment.googleProvider
              )
            },
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider(environment.facebookProvider)
            }
          ],
          onError: (err) => {
            // console.error(err);
          }
        } as SocialAuthServiceConfig,
      }
    ],  // define custom NgbDatepickerI18n provider
  bootstrap: [AppComponent]
})
export class AppModule { }
