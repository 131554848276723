<div class="text-start container mt-5 p-5">
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><strong><span
                style="font-weight: bold;">INTRODUCTION&nbsp;</span></strong></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>La présente
            Politique de confidentialité s&apos;applique à la manière
            dont STUDIOMAP (&laquo; nous &raquo;, &laquo; notre &raquo; ou &laquo; STUDIOMAP &raquo;) traite les
            informations d'identification personnelle (&laquo; Données personnelles &raquo; ou &laquo; Données
            &raquo;) en relation avec Studiomap.fr (&laquo; Site &raquo; ou &laquo; Studiomap.fr &raquo;), ses
            visiteurs, et les personnes qui créent des comptes sur le Site (&laquo; Compte(s) utilisateur &raquo;).
            L&apos;objectif de la présente Politique de confidentialité est de fournir à ces visiteurs et titulaires
            de compte (&laquo; Vous &raquo;) des informations sur la manière dont STUDIOMAP traite les données
            personnelles, notamment :&nbsp;</span></p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">les Données que
                nous collectons ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">ce que nous
                allons en faire ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">les processus que
                nous allons suivre ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">et quels sont Vos
                droits en ce qui concerne ces
                Données.&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; "><span>Les
            Données personnelles seront collectées et traitées conformément
            à la législation et/ou à la réglementation nationale ou étatique applicable, y compris, mais sans
            s&apos;y limiter, le Règlement de l&apos;union européenne sur la protection des données 2016/679 (le
            &laquo; RGPD &raquo;), la loi californienne de 2018 sur la protection de la vie privée des consommateurs
            (&laquo; CCPA &raquo;) et la loi japonaise sur la protection les informations personnelles (&laquo; APPI
            &raquo;).&nbsp;</span></p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>Modifications et mises à jour.&nbsp;</span></strong><span>Nous vous encourageons à consulter
            régulièrement la présente
            Politique de protection des données personnelles afin de vous tenir au courant de toute modification. Pour
            les mises à jour importantes, nous Vous informerons via le Site et/ou la méthode que Vous avez choisie
            lors de votre inscription ou sur Votre Compte utilisateur. &nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>DÉFINITIONS&nbsp;</span></strong>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>Cette Politique de confidentialité fait partie des&nbsp;</span><a routerLink="/cgu">Conditions
            Générales d&rsquo;Utilisation de
            Studiomap.fr</a><span>. En plus des termes en majuscules des Conditions Générales d’Utilisation, il
            existe quelques autres termes importants pour la présente Politique de confidentialité. Vous pouvez
            trouver les définitions de ces termes ci-après : </span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>&laquo; Réglementation applicable en matière de protection
                des données &raquo;&nbsp;</span></strong><span>désigne toute loi ou réglementation relative au
            traitement des Données personnelles qui s'applique au traitement par STUDIOMAP de Vos Données
            personnelles, y compris le RGPD, le CCPA et l'APPI. </span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>&laquo; Client &raquo;&nbsp;</span></strong>désigne une personne physique ou morale
        (représentée par son représentant légal ou toute autre personne dûment autorisée), qui accède à ou crée
        un Compte utilisateur sur dans le but de commander des services et/ou des livrables elle-même ou pour le compte
        de ses propres clients (qui peuvent être des personnes physiques ou morales <strong><span>&laquo; Client(s)
                final(aux)
                &raquo;</span></strong><span>).&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>&laquo;&nbsp;</span><strong><span>Responsable de traitement&nbsp;</span></strong><span>&raquo; désigne la
            personne physique ou morale, l&rsquo;autorité
            publique, un service ou toute autre organisme qui détermine la finalité du traitement des Données
            personnelles.&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>&laquo; Données &raquo;&nbsp;</span></strong><span>ou&nbsp;</span><strong><span>&laquo;
                Données personnelles &raquo;&nbsp;</span></strong><span>désigne toute information relative à une
            personne physique, y compris toute information considérée comme personnelle en vertu de la Réglementation
            applicable en matière de protection des données et/ou toute information par laquelle une personne peut
            être identifiée directement ou indirectement (par exemple par référence à un identifiant d'utilisateur,
            un numéro d'identification, des données de localisation, un nom d'utilisateur en ligne, ou un ou plusieurs
            éléments spécifiques liés à l'état physique ou de santé d'une personne, à sa nationalité, à son
            identité culturelle ou sociale). &nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>&laquo; Livrable &raquo;&nbsp;</span></strong><span>désigne les images, vidéos et/ou autres
            supports commandés par le Client ;</span><br><strong><span>&laquo; Photographe
                &raquo;&nbsp;</span></strong><span>désigne un prestataire de
            services indépendant offrant ou souhaitant offrir des services de&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>photographie, de vidéographie et/ou d&apos;autres services connexes sur
            Studiomap.fr ;&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>&laquo;&nbsp;</span><strong><span>Traitement&nbsp;</span></strong><span>&raquo; ou
            &laquo;&nbsp;</span><strong><span>traiter&nbsp;</span></strong><span>&raquo; désigne toute opération ou
            ensemble d&apos;opérations
            effectuées par STUDIOMAP concernant les Données Personnelles, telles que la collecte,
            l&apos;enregistrement, l&apos;organisation, la conservation, l&apos;adaptation ou la modification,
            l&apos;extraction, la consultation, l&apos;utilisation, la communication par transmission,&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>diffusion ou toute
            autre forme de mise à disposition, le rapprochement
            ou l'interconnexion ainsi que l&apos;archivage et/ou la suppression ;&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; ">
        <span>&laquo;&nbsp;</span><strong><span>Sous-traitant&nbsp;</span></strong><span>&raquo; désigne une personne
            physique ou morale, une autorité publique,
            un service ou toute autre organisme qui traite les Données personnelles conformément aux instructions du
            Responsable du traitement ;&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; ">
        <span>&laquo;&nbsp;</span><strong><span>Services&nbsp;</span></strong><span>&raquo; désigne les services de
            photographie, vidéographie et/ou autres
            services liés aux médias commandés par les Clients et/ou les Clients finaux via Studiomap.fr et fournis
            par le(s) Photographe(s) indépendant(s) ;&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><strong><span>1. DONNÉES
                PERSONNELLES&nbsp;</span></strong></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>STUDIOMAP collecte
            différentes Données personnelles en fonction de la
            manière dont Vous interagissez avec le Site.&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.1.&nbsp;</span></strong>
    </p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Si
            Vous&nbsp;</span><strong><span>visitez&nbsp;</span></strong><span>Studiomap.fr</span><br><strong><span>1.1.1.&nbsp;</span></strong><span>Nous
            collectons les Données personnelles suivantes :&nbsp;</span></p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                adresse électronique lorsque Vous vous abonnez
                à l&apos;une de nos newsletters de Nous soumettez une demande ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Les
                informations de mesure d&apos;audience, y compris
                la région des visiteurs en agrégé ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Les
                informations communiquées via le
                chatbot.&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.1.2.&nbsp;</span></strong><span>Vos Données personnelles sont collectées pour le(s)
            finalité(s)
            suivante(s) :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                surveiller et améliorer les performances de
                Studiomap.fr (y compris les tests A/B et autres tests similaires) ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                mesurer le nombre de visiteurs ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                rendre Studiomap.fr facile et agréable à
                utiliser ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                communiquer avec Vous concernant les actualités,
                produits et/ou opportunités&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; "><span>de
            STUDIOMAP ;&nbsp;</span></p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                traiter les demandes formulées via le
                chatbot.&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.1.3.&nbsp;</span></strong><span>Nous traitons ces Données selon les bases légales suivantes
            :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                consentement,&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Notre
                intérêt légitime à améliorer notre site et
                nos services ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Notre
                intérêt légitime dans le fonctionnement du
                site ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Notre
                intérêt légitime à surveiller notre site
                pour détecter les fraudes et/ou toute autre&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>utilisation illégale ou inadmissible.</span><br><span>Si Vous
            vous&nbsp;</span><strong><span>inscrivez&nbsp;</span></strong><span>en tant
            que&nbsp;</span><strong><span>photographe&nbsp;</span></strong><span>sur Studiomap.fr&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.2.1.&nbsp;</span></strong><span>Nous collectons les Données personnelles suivantes
            :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                nom complet ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                adresse électronique et votre numéro de téléphone ; &nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                adresse postale ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Vos
                informations professionnelles, y compris Vos
                réseaux sociaux et/ou votre&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>portfolio.</span><br><strong><span>1.2.2.&nbsp;</span></strong><span>Vos Données personnelles sont
            collectées pour le(s) finalité(s)
            suivante(s) :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour créer et
                maintenir Votre compte utilisateur
                STUDIOMAP ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour Vous
                permettre d&apos;offrir Vos services en tant
                que photographe et/ou vidéaste&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>indépendant ;&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour Vous
                permettre de préciser Votre emplacement,
                Votre disponibilité, Votre&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>équipement et Votre ou Vos domaines d&apos;expérience, d&apos;intérêt
            et d&apos;expertise afin&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>d&apos;effectuer des services pour les clients de STUDIOMAP
            ;&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Vous permettre de
                participer à tout concours, toute
                promotion ou tout autre élément &nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>similaire proposé par le biais de Studiomap.fr ;&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                vous jumeler avec un Service ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour communiquer
                avec Vous concernant Votre Compte utilisateur, Vos&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>opportunités, les services ou médias que Vous fournissez et/ou avez
            fournis, les études de photographes et/ou les concours et promotions ou informations
            similaires.&nbsp;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><span style="font-family:'Times New Roman';">&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.2.3.&nbsp;</span></strong><span>Nous traitons ces Données selon les bases légales suivantes
            :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span
                style=" font-size:9pt;">L&rsquo;exécution du contrat conclu entre Vous et
                STUDIOMAP ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Notre
                intérêt légitime à améliorer notre site et
                nos services ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Notre
                intérêt légitime à surveiller notre site
                pour détecter toute fraude et/ou autre&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>utilisation illégale ou inadmissible.&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.2.4.&nbsp;</span></strong><span>Les photographes sont jumelés avec des Services à l'aide d'une
            technologie qui tient compte de l'emplacement du photographe, de la disponibilité indiquée, de ses
            domaines d'expertise, de son expérience et d'autres facteurs. En plus des droits énumérés à l’article
            8, les photographes ont le droit de demander une intervention humaine dans le processus de jumelage. Pour
            les droits liés à cet article, veuillez consulter l’article 10. &nbsp;</span>
    </p>

    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.3.&nbsp;</span></strong>
    </p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Si Vous
            vous&nbsp;</span><strong><span>inscrivez&nbsp;</span></strong><span>en tant
            que&nbsp;</span><strong><span>Client&nbsp;</span></strong><span>sur
            Studiomap.fr&nbsp;</span><strong><span>1.3.1.&nbsp;</span></strong><span>Nous collectons les Données
            personnelles suivantes :&nbsp;</span></p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                nom complet ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                adresse électronique et votre numéro de
                téléphone ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                lieu de travail.&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.3.2.&nbsp;</span></strong><span>Vos Données personnelles sont collectées pour le(s)
            finalité(s)
            suivante(s) :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                créer et maintenir Votre compte utilisateur sur
                Studiomap.fr ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                Vous permettre de commander des Services et des
                Livrables ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                traiter et exécuter votre commande de Services
                et de Livrables ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                gérer Vos commentaires et/ou retours concernant
                les Livrables.&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.3.3.&nbsp;</span></strong><span>Nous traitons ces Données selon les bases légales suivantes
            :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span
                style=" font-size:9pt;">L&rsquo;exécution du contrat conclu entre Vous et
                STUDIOMAP ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Notre intérêt
                légitime à améliorer notre site et
                nos services ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Notre intérêt
                légitime à surveiller notre site
                pour détecter toute fraude et/ou autre&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>utilisation illégale ou inadmissible.</span><br><span>Si Vous&nbsp;</span><strong><span>planifiez un
                Service&nbsp;</span></strong><span>via une page de renvoi associée à Studiomap.fr&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.4.1.&nbsp;</span></strong><span>Nous collectons les Données personnelles suivantes
            :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="circle">
        <li style="margin-top:14pt; margin-left:65pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">votre
                nom complet ;&nbsp;</span>
        </li>
        <li style="margin-left:65pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                adresse électronique et votre numéro de
                téléphone ;&nbsp;</span>
        </li>
        <li style="margin-left:65pt; margin-bottom:14pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                lieu de travail.&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:72pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.4.2.&nbsp;</span></strong><span>Vos Données personnelles sont collectées pour le(s)
            finalité(s)
            suivante(s) :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="square">
        <li style="margin-top:14pt; margin-left:99.58pt; padding-left:8.42pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour Vous
                contacter dans le cadre de la commande de services;&nbsp;</span>
        </li>
        <li style="margin-left:99.58pt; margin-bottom:14pt; padding-left:8.42pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour traiter et
                exécuter Votre commande de services
                et de Livrables.&nbsp;</span><strong><span style=" font-size:9pt;">1.4.3.&nbsp;</span></strong><span
                style=" font-size:9pt;">Nous traitons ces Données selon les bases légales
                suivantes :&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span
            style="font-family:ArialMT;">●&nbsp;</span><span>L&rsquo;exécution du
            contrat conclu entre Vous et STUDIOMAP. Si Vous&nbsp;</span><strong><span>postulez à un emploi à
                STUDIOMAP&nbsp;</span></strong><span>via Studiomap.fr&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.5.1.&nbsp;</span></strong><span>Nous collectons les Données personnelles suivantes
            :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                nom complet ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                localisation, votre disponibilité et votre
                expérience ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Tout
                profil que vous partagez sur les réseaux sociaux
                (par exemple LinkedIn, Github,&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>etc.).</span><br><strong><span>1.5.2.&nbsp;</span></strong><span>Vos Données personelles sont
            collectées pour le(s) finalité(s)
            suivante(s) :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                vous permettre d&rsquo;envoyer Votre candidature
                à STUDIOMAP ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour permettre à
                STUDIOMAP d&rsquo;évaluer Votre
                candidature et de Vous contacter au sujet de&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>l&rsquo;offre d&rsquo;emploi pour laquelle Vous avez manifesté de
            l&rsquo;intérêt.&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.4.&nbsp;</span></strong><span>Si Vous&nbsp;</span><strong><span>planifiez un
                Service&nbsp;</span></strong><span>via une page de renvoi associée à Studiomap.fr&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.4.1.&nbsp;</span></strong><span>Nous collectons les Données personnelles suivantes
            :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="circle">
        <li style="margin-top:14pt; margin-left:65pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                nom complet ;&nbsp;</span>
        </li>
        <li style="margin-left:65pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                adresse électronique et votre numéro de
                téléphone ;&nbsp;</span>
        </li>
        <li style="margin-left:65pt; margin-bottom:14pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                lieu de travail.&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:72pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.4.2.&nbsp;</span></strong><span>Vos Données personnelles sont collectées pour le(s)
            finalité(s)
            suivante(s) :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="square">
        <li style="margin-top:14pt; margin-left:99.58pt; padding-left:8.42pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour Vous
                contacter dans le cadre de la commande de
                Services ;&nbsp;</span>
        </li>
        <li style="margin-left:99.58pt; margin-bottom:14pt; padding-left:8.42pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour traiter ou
                exécuter Votre commande de services
                et de Livrables.&nbsp;</span><strong><span style=" font-size:9pt;">1.4.3.&nbsp;</span></strong><span
                style=" font-size:9pt;">Nous traitons ces Données selon les bases légales
                suivantes :&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; "><span
            style="font-family:ArialMT;">●&nbsp;</span><span>L&rsquo;exécution du
            contrat conclu entre Vous et Studiomap. Si Vous&nbsp;</span><strong><span>postulez à un emploi à
                Studiomap&nbsp;</span></strong><span>via Studiomap.fr&nbsp;</span></p>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <strong><span>1.5.1.&nbsp;</span></strong><span>Nous collectons les Données personnelles suivantes
            :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="circle">
        <li style="margin-top:14pt; margin-left:65pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                nom complet ;&nbsp;</span>
        </li>
        <li style="margin-left:65pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Votre
                localisation, votre disponibilité et votre
                expérience ;&nbsp;</span>
        </li>
        <li style="margin-left:65pt; margin-bottom:14pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Tout
                profil que vous partagez sur les réseaux sociaux
                (par exemple LinkedIn, Github,&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:72pt; margin-bottom:14pt; font-size:9pt; ">
        <span>etc.).</span><br><strong><span>1.5.2.&nbsp;</span></strong><span>Vos Données personnelles sont
            collectées pour le(s) finalité(s)
            suivante(s) :&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="circle">
        <li style="margin-top:14pt; margin-left:65pt; padding-left:7pt;  font-size:10pt; ">
            <strong><span style="font-family:Arial; font-size:9pt;">●&nbsp;</span></strong><strong><span
                    style="font-family:Arial; font-size:9pt;">&nbsp;</span></strong><span style=" font-size:9pt;">Pour
                vous permettre d&rsquo;envoyer Votre candidature
                à Studiomap ;&nbsp;</span>
        </li>
        <li style="margin-left:65pt; margin-bottom:14pt; padding-left:7pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">Pour permettre à
                Studiomap d&rsquo;évaluer Votre
                candidature et de Vous contacter au sujet de&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:72pt; margin-bottom:14pt; font-size:9pt; ">
        <span>l&rsquo;offre d’emploi pour laquelle Vous avez manifesté de l’intérêt. .&nbsp;</span>
    </p>

    <p style="margin-top:14pt; margin-bottom:14pt; "><span style="font-family:'Times New Roman';">&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><strong><span>2. COLLECTE
                DE DONNÉES OBLIGATOIRE OU
                FACULTATIVE&nbsp;</span></strong></p>

    <p style="margin-top:0pt; margin-bottom:0pt; "><span style="font-family:'Times New Roman';">&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>STUDIOMAP Vous informera lorsque Vous êtes
            tenu de fournir certaines informations, ou lorsque celles-ci sont facultatives. Par exemple, les
            photographes sont tenus de fournir leur nom afin de pouvoir être payés pour le(s) service(s) qu'ils
            effectuent. &nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Si les informations demandées sont
            obligatoires, les champs que Vous devez remplir seront marqués comme obligatoires. Pour les autres champs,
            c'est Vous qui décidez. .&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><strong><span>3.
                COOKIES&nbsp;</span></strong></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>STUDIOMAP utilise des
            cookies conformément à sa Politique en matière
            de cookies, qui se trouve&nbsp;</span><span
            style=" color:#0f54cc;">ici</span><span>.&nbsp;</span><strong><br><span>4.
                INFORMATION QUE NOUS PARTAGEONS&nbsp;</span></strong></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Nous ne divulguerons les
            Données personnelles uniquement dans les cas suivants
            :</span><br><span>A nos prestataires dans le cadre de leurs services en
            lien avec les finalités listées ci-dessus ;&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Si Vous acceptez de partager ces Données avec
            des tiers, tels que les réseaux sociaux par le biais de bouton de partage ; &nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Si Vous êtes un Client ou un Client final –
            aux Photographes pour leur permettre d’exécuter les Services ; &nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Si Vous êtes un Photographe – aux Clients et
            aux Clients finaux pour Vous permettre d’exécuter les Services ; </span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Si Vous êtes un ambassadeur – à d’autres
            ambassadeurs STUDIOMAP ; </span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Aux filiales ou
            entités faisant partie de groupe STUDIOMAP.&nbsp;</span>
    </p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Nous pouvons partager des informations
            agrégées, pseudonymisées ou anonymisées, lesquelles ne permettent pas de Vous identifier, dans le cadre
            d'analyses statistiques à des fins commerciales ; ce qui peut inclure le nombre de visiteurs de notre Site
            et le nombre de clics générés par e-mail.&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><strong><span>5. TRANSFERT
                DE DONNÉES HORS UNION EUROPÉENNE&nbsp;</span></strong>
    </p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Les données peuvent être transférées hors
            de l'Union européenne, soit vers des entités de notre groupe situées hors de l'Union européenne, soit
            vers l'un de nos prestataires. Nos prestataires sont situés dans l'Union européenne, aux États-Unis, en
            Inde, en Corée, en Australie et en Argentine. &nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Lorsque nous transférons des Données en
            dehors de l'Union européenne, nous prenons les mesures appropriées (telles que la signature de clauses
            contractuelles types de la Commission européenne), sauf en cas d'exemption légale. .&nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><strong><span>6.
                CONSERVATION DES DONNÉES&nbsp;</span></strong></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>La durée de
            conservation des Données dépend de :&nbsp;</span></p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">de la nature des
                Données ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">de la raison pour
                laquelle nous traitons les données
                (la finalité) ;&nbsp;</span>
        </li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">de toute
                directive ou recommandation des autorités
                compétentes en matière de protection des&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; ">
        <span>données ;&nbsp;</span>
    </p>
    <ul style="margin:0pt; padding-left:0pt;" type="disc">
        <li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:14pt; padding-left:8.4pt;  font-size:10pt; ">
            <span style="font-family:ArialMT; font-size:9pt;">●&nbsp;</span><span
                style="font-family:ArialMT; font-size:9pt;">&nbsp;</span><span style=" font-size:9pt;">de toute(s)
                obligation(s) légale(s)
                pertinente(s).&nbsp;</span>
        </li>
    </ul>
    <p style="margin-top:14pt; margin-left:36pt; margin-bottom:14pt; font-size:9pt; "><span>En règle générale, nous
            conservons les Données pendant le temps nécessaire et pour la finalité pour laquelle elles ont été
            collectées. Vos Données seront ensuite archivées pendant le délai de prescription légal applicable afin
            de permettre à STUDIOMAP de respecter ses obligations légales. &nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><strong><span>7. LES
                RÉSEAUX SOCIAUX ET VOTRE CONTENU&nbsp;</span></strong></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Notre Site mentionne parfois d'autres pages de
            STUDIOMAP ou d’autres comptes de STUDIOMAP sur les réseaux sociaux. Nous attirons votre attention sur le
            fait que le contenu présenté sur ces plateformes peut être vu par le public, et que STUDIOMAP ne peut
            être tenu responsable des actions entreprises par des tiers si vous publiez quelque chose (y compris tout
            ce qui contient des Données personnelles) sur l'un de nos comptes présents sur les réseaux sociaux.
            &nbsp;</span></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><strong><span>8. COMMENT
                EXERCER VOS DROITS SUR VOS DONNÉES
                PERSONNELLES&nbsp;</span></strong></p>
    <p style="margin-top:14pt; margin-bottom:14pt; font-size:9pt; "><span>Vous disposez, sur
            vos Données personnelles, des droits :&nbsp;</span>
    </p>
</div>