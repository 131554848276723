<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#shareButtons"
    id="openshareButtonsModal">
    Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="shareButtons" tabindex="-1" aria-labelledby="shareButtonsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content position-relative">
            <div class="position-absolute w-100 text-end" style="top: -28px;left: 0;">
                <button id="close3DSecureModal" type="button" class="text-white btn p-0" data-bs-dismiss="modal"
                    aria-label="Close"><i class="fas fa-times fs-4"></i></button>
            </div>
            <div class="modal-body py-5">
                <share-buttons [url]="url" [exclude]="['print','copy']">
                </share-buttons>
                  <div class="input-group">
                    <input type="text" class="form-control" [value]="url">
                    <!-- <div class="input-group-append">
                        <button title="copier" class="btn border-border rounded-0 rounded-end" type="button"><i
                                class="fa fa-link"></i>
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>