export const environment = {
  googleProvider:'632001398710-jfvr0n1hub89fqrkphkrmj778un9hilk.apps.googleusercontent.com',
  facebookProvider:'933150950936691',
  production: true,
  googleApi: 'AIzaSyC_BLmBbNDewh9xbYQFc4T5eVfNlsGTjP4',
  api: 'https://api.dev.studiomap.fr/',
  base_url_api: 'https://api.dev.studiomap.fr',
  defaultAvatar: "/assets/team/images.jpg",
  application:
  {
    name: 'studio-map',
    angular: 'Angular 11.2.4',
    bootstrap: 'Bootstrap 5.0.0',
    fontawesome: 'Font Awesome 5.15.2',
  },
  url: 'https://dev.studiomap.fr/',
  webflowURL: 'https://studioma.webflow.io',
  meta_image: '/assets/icons/logo-black-red.svg',
  config: {
    /* SELECT ONE OF THOSE CONFIGURATIONS */

    /* LOCAL JSON (NO CRUD) */
    api: false,
    url: './assets/params/json/crud/',

    /* LOCAL REST API CRUD  */
    /* api: true,
    url: 'http://localhost:5200/', */
  },
  studioPrestations: [
    { value: "ENREGISTREMENT", name: "Studio d'enregistrement " }
    , { value: "PHOTOGRAPHIE", name: "Studio photo & vidéo" }
    , { value: "REPETITION", name: "Studio de répétition" }
    , { value: "DANSE", name: "Studio de danse" }
  ],
  freelancePrestations: [
    { value: "PHOTOGRAPHE", name: "Photographe / Réalisateur" },
    { value: "GRAPHISTE", name: "Graphiste / Designer / Styliste" },
    { value: "BEATMAKER", name: "Beatmaker / Ingénieur son" },
    { value: "MODELE", name: 'Modèle / Figurant' }
  ],
  firebase: {
    apiKey: "AIzaSyC6DYWEy8Q7etKpkFSJ5d-ZXEBcr7Cvjd0",
    authDomain: "studiomap-aecb8.firebaseapp.com",
    databaseURL: "https://studiomap-aecb8-default-rtdb.firebaseio.com",
    projectId: "studiomap-aecb8",
    storageBucket: "studiomap-aecb8.appspot.com",
    messagingSenderId: "426020724932",
    appId: "1:426020724932:web:58b4921d46ef6272ad6991",
    measurementId: "G-QYB8T9KBQX"
  },
  shourtLinkApiKey:"AIzaSyC6DYWEy8Q7etKpkFSJ5d-ZXEBcr7Cvjd0",

  
};
